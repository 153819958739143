import { IoIosLogOut } from 'react-icons/io';
import Logo from '../../assets/logo.png';

import './index.scss';

export const TopBarComponent = (props: any) => {
  const { handleLogout } = props;

  var mfaUserInfo: any = localStorage.getItem('mfaUserInfo');
  if (mfaUserInfo) {
    mfaUserInfo = JSON.parse(mfaUserInfo);
  }

  return (
    <div id='TopBar'>
      <div id='TopBar__Left'>
        <img src={Logo} alt='logo' />
        <div className='LeftText'>OCR Platform</div>
      </div>
      <div id='TopBar__Right'>
        {mfaUserInfo ? (
          <div className='MFAInfo'>
            <div>{mfaUserInfo.fullName}</div>
            <div>{mfaUserInfo.businessUnitName}</div>
          </div>
        ) : (
          <div className='UserInfo'>{localStorage.getItem('username') || 'Username'}</div>
        )}
        <div className='ButtonContainer'>
          <IoIosLogOut style={{ fontSize: '25px' }} onClick={handleLogout} title='Đăng xuất' />
        </div>
      </div>
    </div>
  );
};
