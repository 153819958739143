import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationService } from "../../components";
import { APIServices } from "../../utils";
import actions from "./actions";
import reqActions from "../request/actions";
import authActions from "../auth/actions";

function* saga_LoadData(): Generator<any, void, any> {
  try {
    let pageNumber = yield select((state: any) => state.user.page_number);
    let pageSize = yield select((state: any) => state.user.page_size);
    let currentFilter = yield select((state: any) => state.user.current_filter);
    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.GetUser(
      pageNumber,
      pageSize,
      currentFilter
    );
    let response: any = _response;
    let { data } = response;
    const { account, paging } = data;
    if (data && data.account) {
      yield put(actions.action.loadDataSuccess(account, paging));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải danh sách User thất bại");
    }
  } catch (ex: any) {
    console.log("[User] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải danh sách User thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_LoadRoleData() {
  try {
    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.GetUserRole();
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(actions.action.loadRoleSuccess(data));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải danh sách User Role thất bại");
    }
  } catch (ex: any) {
    console.log("[User] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải danh sách User Role thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_saveData() {
  try {
    let _currentItem: Promise<any> = yield select(
      (state: any) => state.user.current_item
    );
    let currentItem = _currentItem;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.SaveUser(currentItem);
    let response: any = _response;
    let { data, message } = response;

    if (data) {
      yield put(reqActions.action.updateRequestState(1));
      yield saga_LoadData();
      NotificationService.success("Lưu User thành công");
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error(message ? message : "Lưu User thất bại");
    }
  } catch (ex: any) {
    console.log(
      "[User] saga_LoadData Error: ",
      ex && ex.data && ex.data.message
    );
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error(
      ex && ex.data && ex.data.message ? ex.data.message : "Lưu User thất bại"
    );

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_deleteData(action: any) {
  try {
    let { user_id } = action.payload;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.DeleteUser(user_id);
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(reqActions.action.updateRequestState(1));
      yield saga_LoadData();
      NotificationService.success("Xoá User thành công");
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Xoá User thất bại");
    }
  } catch (ex: any) {
    console.log("[User Group] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Xoá User thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_DATA, saga_LoadData);
  yield takeEvery(actions.types.LOAD_ROLE, saga_LoadRoleData);
  yield takeEvery(actions.types.SAVE_CURRENT_ITEM, saga_saveData);
  yield takeEvery(actions.types.DELETE_CURRENT_ITEM, saga_deleteData);
  yield takeEvery(actions.types.UPDATE_PAGE_NUMBER, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_SIZE, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_FILTER, saga_LoadData);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
