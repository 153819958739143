import { useState } from 'react';
import { Input } from 'antd';

export const PriorityInputComponent = (props: any) => {
  const { requestId, priority, onUpdate, status } = props;
  const [isInput, setIsInput] = useState(false);
  const [inputPriority, setInputPriority] = useState(priority);

  const handleClick = () => {
    setIsInput(true);
  };

  const onChangeInput = (e: any) => {
    setInputPriority(e.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onUpdate(requestId, inputPriority);
      setIsInput(false);
    }
  };

  console.log(inputPriority, status);

  return (
    <div className='PriorityCell'>
      {status === 'PROCESSING' ? (
        <div className='PriorityInp'>
          <Input type='number' disabled value={inputPriority} />
        </div>
      ) : isInput ? (
        <div className='PriorityInp' onKeyDown={handleKeyDown}>
          <Input
            type='number'
            disabled={status === 'PROCESSING'}
            value={inputPriority}
            onChange={onChangeInput}
          />
        </div>
      ) : (
        <div className='PriorityContainer' onClick={handleClick}>
          {priority}
        </div>
      )}
    </div>
  );
};
