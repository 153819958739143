// BANGKE
const GeneralInfo_BANG_KE_B1PLUSMap = new Map(
  Object.entries({
    title: "Tên tài liệu",
    buyer_tax_code: "Mã số thuế đơn vị mua",
  })
);

const LineInfo_BANG_KE_B1PLUSMap = new Map(
  Object.entries({
    seller_name: "Tên đơn vị thụ hưởng",
    seller_tax_code: "Mã số thuế đơn vị thụ hưởng",
    invoice_number: "Số hoá đơn",
    invoice_date: "Ngày hoá đơn",
    invoice_sign: "Ký hiệu hoá đơn",
    invoice_value: "Giá trị theo hoá đơn",
    disbursement_amount: "Số tiền giải ngân",
  })
);

const getMap = (type: any, data_field: any = "") => {
  // 1
  if (type === "BANGKE") {
    if (data_field === "general_info") return GeneralInfo_BANG_KE_B1PLUSMap;
    if (data_field === "line_info") return LineInfo_BANG_KE_B1PLUSMap;
  }
};

export const B1PLUSMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => B1PLUSItemMapping(document));
};

export const B1PLUSItemMapping = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.document_code,
    score: isNaN(document.document_code_score)
      ? document.document_code_score
      : document.document_code_score === -1
      ? 0
      : Math.min(
          Number.parseFloat(document.document_code_score) * 100,
          99
        ).toFixed(0),
  });

  if (document.ocr_data && typeof document.ocr_data === "object") {
    Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
      const ocr_data = document.ocr_data[`${data_field}`];
      const data_map = getMap(document.document_type, data_field);

      // OCR data type object
      if (
        ocr_data &&
        !Array.isArray(ocr_data) &&
        Object.keys(ocr_data).length > 0
      ) {
        if (ocr_data && Object.keys(ocr_data).length > 0 && data_map) {
          Array.from(data_map.keys()).forEach((item: any, index: any) => {
            let ocr_item = ocr_data[item];
            if (ocr_item && ocr_item.length > 0) {
              let field = Array.isArray(ocr_item) ? ocr_item[0] : ocr_item;

              ocrData.push({
                attribute: data_map.get(item),
                coords: field.coordinates,
                value: field.value,
                id: field.id,
                image_index: field.image_index,
                score: isNaN(field.score)
                  ? field.score
                  : field.score === -1
                  ? 0
                  : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(
                      0
                    ),
              });
            } else
              ocrData.push({
                attribute:
                  index === 0
                    ? data_map.get(item)
                    : `${data_map.get(item)} ${index + 1}`,
                coords: null,
                id: null,
                image_index: null,
                value: null,
                value_update: null,
                certain: null,
              });
          });
        }
      } else {
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            let custom_data_map = structuredClone(data_map);
            let isBold = false;
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              const ocr_item_key = Object.keys(ocr_item);
              if (ocr_item_key.length === 3) {
                const data_key = Array.from(custom_data_map.keys()).map(
                  (item: any) => {
                    return item;
                  }
                );
                data_key.forEach((item: any, _: any) => {
                  if (ocr_item_key.indexOf(item) === -1) {
                    custom_data_map.delete(item);
                  }
                });
                isBold = true;
              }

              Array.from(custom_data_map.keys()).forEach(
                (item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item])
                      ? ocr_item[item][0]
                      : ocr_item[item];

                    ocrData.push({
                      attribute:
                        index === 0
                          ? custom_data_map.get(item)
                          : `${custom_data_map.get(item)} ${index + 1}`,
                      coords: field.coordinates,
                      value: field.value,
                      id: field.id,
                      image_index: field.image_index,
                      is_bold: isBold,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(
                            Number.parseFloat(field.score) * 100,
                            99
                          ).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0
                          ? custom_data_map.get(item)
                          : `${custom_data_map.get(item)} ${index + 1}`,
                      coords: null,
                      id: null,
                      image_index: null,
                      is_bold: isBold,
                      value: null,
                      certain: null,
                    });
                }
              );
            }
          });
        }
      }
    });
  }

  return {
    type: document.document_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};

export const B1PLUSDetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => B1PLUSItemDetail(document));
};

export const B1PLUSItemDetail = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.doc_code,
    score: isNaN(document.doc_score)
      ? document.doc_score
      : document.doc_score === -1
      ? 0
      : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
  });

  if (document.ocr_data && typeof document.ocr_data === "object") {
    const ocr_update = document.ocr_update ? document.ocr_update : null;
    Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
      const ocr_data = document.ocr_data[`${data_field}`];
      const data_map = getMap(document.doc_type, data_field);

      // OCR data type object
      if (
        ocr_data &&
        !Array.isArray(ocr_data) &&
        Object.keys(ocr_data).length > 0
      ) {
        if (ocr_data && Object.keys(ocr_data).length > 0 && data_map) {
          Array.from(data_map.keys()).forEach((item: any, index: any) => {
            let ocr_item = ocr_data[item];
            if (ocr_item && ocr_item.length > 0) {
              let field = Array.isArray(ocr_item) ? ocr_item[0] : ocr_item;

              ocrData.push({
                attribute: data_map.get(item),
                coords: field.coordinates,
                value: field.value,
                value_update: ocr_update && ocr_update[field.id],
                id: field.id,
                image_index: field.image_index,
                score: isNaN(field.score)
                  ? field.score
                  : field.score === -1
                  ? 0
                  : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(
                      0
                    ),
              });
            } else
              ocrData.push({
                attribute:
                  index === 0
                    ? data_map.get(item)
                    : `${data_map.get(item)} ${index + 1}`,
                coords: null,
                id: null,
                image_index: null,
                value: null,
                value_update: null,
                certain: null,
              });
          });
        }
      } else {
        // OCR data type array
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            let custom_data_map = structuredClone(data_map);
            let isBold = false;
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              const ocr_item_key = Object.keys(ocr_item);
              if (ocr_item_key.length === 3) {
                const data_key = Array.from(custom_data_map.keys()).map(
                  (item: any) => {
                    return item;
                  }
                );
                data_key.forEach((item: any, _: any) => {
                  if (ocr_item_key.indexOf(item) === -1) {
                    custom_data_map.delete(item);
                  }
                });
                isBold = true;
              }

              Array.from(custom_data_map.keys()).forEach(
                (item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item])
                      ? ocr_item[item][0]
                      : ocr_item[item];

                    ocrData.push({
                      attribute:
                        index === 0
                          ? custom_data_map.get(item)
                          : `${custom_data_map.get(item)} ${index + 1}`,
                      coords: field.coordinates,
                      value: field.value,
                      value_update: ocr_update && ocr_update[field.id],
                      id: field.id,
                      image_index: field.image_index,
                      is_bold: isBold,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(
                            Number.parseFloat(field.score) * 100,
                            99
                          ).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0
                          ? custom_data_map.get(item)
                          : `${custom_data_map.get(item)} ${index + 1}`,
                      coords: null,
                      id: null,
                      image_index: null,
                      is_bold: isBold,
                      value: null,
                      value_update: null,
                      certain: null,
                    });
                }
              );
            }
          });
        }
      }
    });
  }

  return {
    type: document.doc_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};
