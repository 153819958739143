import { useState, useEffect } from 'react';
import { APIServices } from '../../../utils';
import { Spin } from 'antd';

export const FileComponent = (props: any) => {
  const [file, setFile] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const { size, path, clientID, requestID, handleClick, allowClick } = props;

  useEffect(() => {
    async function getFile() {
      let fileContent;
      try {
        setLoading(true);
        fileContent = await APIServices.Sign.GetFileContent(clientID, requestID, path, size);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return null;
      }

      //setFile(`data:image/png;base64,${fileContent.data.file_base64}`);
      setFile(URL.createObjectURL(fileContent));
    }

    //if (!fileIDs[0].includes('pdf'))
    getFile();
  }, [clientID, path, size, requestID]);

  return (
    <>
      {loading && <Spin spinning={loading}></Spin>}
      {!loading && (
        <img
          src={file}
          alt={`file`}
          id={`sign_${size}`}
          onClick={() => handleClick(file)}
          style={{ cursor: allowClick ? 'pointer' : 'default' }}
        />
      )}
    </>
  );
};
