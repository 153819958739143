import { Button, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";

interface FilterComponentProps {
  showSearchText?: boolean;
  showSearchType?: boolean;
  options: { label: string; value: number }[];
  onSearch?: (searchText: string, searchType: number) => void;
}

const ClientFilter: React.FC<FilterComponentProps> = ({
  onSearch,
  options,
  showSearchText = true,
  showSearchType = true,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState(1);
  const [form] = Form.useForm();

  const onReset = () => {
    setSearchText("");
    onSearch && onSearch("", 1);
  };

  return (
    <div className="FormFilter" style={{ margin: 0, marginTop: 20 }}>
      <Form layout="vertical" form={form}>
        <Row>
          <Form.Item>
            <Select
              disabled={!showSearchType}
              style={{ width: "200px" }}
              placeholder="Cột cần tìm kiếm"
              options={options}
              defaultValue={1}
              onChange={(e) => {
                setSearchType(e);
              }}
            />
          </Form.Item>

          {showSearchText && (
            <Form.Item>
              <Input
                style={{ minWidth: "250px" }}
                placeholder="Tìm kiếm"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </Form.Item>
          )}
          <Form.Item>
            <div className="ButtonContainer">
              <Button
                type="primary"
                className="SearchButton"
                onClick={() => {
                  onSearch && onSearch(searchText, searchType);
                }}
              >
                <span>Áp dụng </span>
              </Button>
              <Button type="default" className="ResetButton" onClick={onReset}>
                <IoIosCloseCircle /> Bỏ tuỳ chọn
              </Button>
            </div>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default ClientFilter;
