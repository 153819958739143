import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const dateFormat = "DD-MM";
const dateYearFormat = "DD-MM-YYYY";

export const RequestNumberChartComponent = (props: any) => {
  const { requestData, currentFilter } = props;

  var areaChartData: any[] = [];

  if (requestData && requestData.length > 0) {
    requestData.forEach((item: any) => {
      const { CreatedTime } = item;
      const time_value = moment(CreatedTime).format(dateFormat);
      const unix_value = moment(time_value, dateFormat).unix();

      areaChartData.push({
        name: time_value,
        unix_value,
        Total: item.Total,
      });
    });

    areaChartData.sort((a, b) => a.unix_value - b.unix_value);
  }

  if (!requestData || requestData.length === 0)
    return <div className="ReportBlock">Không có dữ liệu</div>;
  return (
    <div className="ReportBlock">
      <div className="BlockTitle">Số lượng yêu cầu OCR</div>
      <div className="BlockTime">
        {moment(currentFilter.from_time).format(dateYearFormat)} &#8226;{" "}
        {moment(currentFilter.to_time).format(dateYearFormat)}
      </div>
      <div className="BlockBody OCR_Status">
        <div className="ChartContainer">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={areaChartData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="Total"
                stroke="#7BC7FF"
                fill="#7BC7FF"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
