import { DATE_RANGE_FORMAT } from "../../common";
import actions from "./actions";
import moment from "moment";

const default_filter = {
  from_time: moment().format(DATE_RANGE_FORMAT),
  to_time: moment().format(DATE_RANGE_FORMAT),
  clients: [],
  doc_types: [],
};

const initState = {
  current_filter: default_filter,
  request_data: null,
  document_data: null,
  download_data: null,
};

const LogReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case actions.types.UPDATE_FILTER:
      return {
        ...state,
        ...{
          current_filter: action.payload.filter,
        },
      };

    case actions.types.LOAD_REQUEST_DATA:
      return {
        ...state,
      };
    case actions.types.LOAD_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        ...{
          request_data: action.payload.request_data,
        },
      };
    case actions.types.LOAD_DOCUMENT_DATA:
      return {
        ...state,
      };
    case actions.types.LOAD_DOCUMENT_DATA_SUCCESS:
      return {
        ...state,
        ...{
          document_data: action.payload.document_data,
        },
      };

    case actions.types.LOAD_EVALUATE_DATA:
      return {
        ...state,
      };
    case actions.types.LOAD_EVALUATE_DATA_SUCCESS:
      return {
        ...state,
        ...{
          evaluate_data: action.payload.evaluate_data,
        },
      };
    case actions.types.LOAD_DOC_TYPES:
      return {
        ...state,
      };

    case actions.types.LOAD_DOC_TYPES_SUCCESS:
      return {
        ...state,
        ...{
          doc_types: action.payload.doc_types,
        },
      };

    default:
      return state;
  }
};

export default LogReducer;
