import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { RiAddCircleLine, RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import ClientFilter from "../../components/clientFilter";
import { showConfirm } from "../../components/modal";
import { error } from "../../components/notification";
import { PaginationComponent } from "../../components/pagination";
import { useActions } from "../../redux";
import { FlowComponent } from "./component";

var role = localStorage.getItem("role");
const options = [
  { value: 1, label: "Client App" },
  { value: 2, label: "Client ID" },
  { value: 3, label: "Tên luồng nghiệp vụ" },
];

export const FlowPage = () => {
  const dispatch = useDispatch();
  const actions = useActions();
  const [itemShow, setItemShow] = useState<any>(null);
  const requestState = useSelector((state: any) => state.request.request_state);
  let data = useSelector((state: any) => state.flow.data);
  let totalRecord = useSelector((state: any) => state.flow.total_record);
  let pageNumber = useSelector((state: any) => state.flow.page_number);
  let pageSize = useSelector((state: any) => state.flow.page_size);
  let currentItem = useSelector((state: any) => state.flow.current_item);
  let clientAppData = useSelector((state: any) => state.client_app.data);
  let documentData = useSelector((state: any) => state.doc.config);
  let flowData = useSelector((state: any) => state.ocr.config);

  useEffect(() => {
    if (!flowData) dispatch(actions.FlowActions.loadData());
    if (!clientAppData) dispatch(actions.ClientAppActions.loadData(true));
    if (!documentData) dispatch(actions.DocActions.loadConfig(1000000));
    if (!flowData) dispatch(actions.OCRActions.loadConfig());
  }, [
    dispatch,
    actions.FlowActions,
    actions.ClientAppActions,
    actions.DocActions,
    actions.OCRActions,
    clientAppData,
    documentData,
    flowData,
  ]);

  const handleChangeFilter = (e: any) => {
    const search = e.target.value;
    dispatch(actions.FlowActions.updateFilter({ search }));
  };

  const handleChangePageNumber = (page_number: any) => {
    dispatch(actions.FlowActions.updatePageNumber(page_number));
  };

  const handleChangePageSize = (page_size: any) => {
    dispatch(actions.FlowActions.updatePageSize(page_size));
  };

  const handleCreate = () => {
    dispatch(
      actions.FlowActions.updateCurrentItem({
        id: "",
        client_id: "",
        doc_type: "",
        doc_type_priority: 0,
        document_priority_configs: [],
      })
    );
    setItemShow("");
  };

  const handleUpdate = (item: any) => {
    dispatch(
      actions.FlowActions.updateCurrentItem({
        ...item,
        id: item.id.toString(),
        client_id: item.client_id,
        doc_type: item.doc_type,
        doc_type_priority: item.doc_type_priority,
        document_priority_configs: item.document_priority_configs,
      })
    );
    setItemShow(item.id.toString());
  };

  const handleDelete = (id: any) => {
    showConfirm({
      title: "Chắc chắn xoá Document Workflow này?",
      content: "",
      onOk: () => {
        dispatch(actions.FlowActions.deleteCurrentItem(id));
      },
      onCancel: () => {},
    });
  };

  const handleChangeCurrentItem = (data: any) => {
    dispatch(actions.FlowActions.updateCurrentItem(data));
  };

  const handleCancel = () => {
    dispatch(actions.FlowActions.updateCurrentItem(null));
    setItemShow(null);
  };

  const handleSave = () => {
    const { client_id, doc_type, doc_type_priority } = currentItem;

    if(doc_type_priority < 0)
      return error("Mức độ ưu tiên phải lớn hơn hoặc bằng 0");
    if (
      client_id === "" ||
      doc_type === "" ||
      doc_type_priority === null ||
      doc_type_priority === ""
    )
      return error("Vui lòng nhập đầy đủ thông tin");
    else {
      dispatch(actions.FlowActions.saveCurrentItem());
      setItemShow(null);
    }
  };

  const onSearch = (searchText: string, searchType: number) => {
    dispatch(actions.FlowActions.updateFilter({ searchText, searchType }));
  };

  if (!role || role === "") role = localStorage.getItem("role");

  var clientAppList: any[] = [];
  if (clientAppData && clientAppData.length > 0)
    clientAppList = clientAppData.map((item: any, _: any) => {
      return {
        value: item.client_id,
        label: `${item.client_alias} - ID: ${item.client_id}`,
      };
    });

  var listFlow: any[] = [];
  if (flowData && typeof flowData === "object") {
    Object.keys(flowData).forEach((key: any, _: any) => {
      listFlow.push({
        label: flowData[key],
        value: flowData[key],
      });
    });
    listFlow.sort((a, b) => a.value.localeCompare(b.value));
  }

  var prioritySet = new Set();
  if (data && data.length > 0) {
    var currentValue = 0;
    if (currentItem && currentItem.id)
      currentValue = data.find(
        (e: any) => e.id === currentItem.id
      ).doc_type_priority;
    prioritySet = new Set(
      data.map((item: any) => {
        if (
          item.doc_type_priority === 0 ||
          item.doc_type_priority === currentValue
        )
          return null;
        return item.doc_type_priority;
      })
    );
  }

  return (
    <div className="AppPage">
      <div className="PageTitle">Document Workflow</div>
      <div className="head-section">
        <ClientFilter options={options} onSearch={onSearch} />

        <div
          className="CreateButtonContainer"
          style={{ justifyContent: "space-between", paddingLeft: "5px" }}
        >
          <Button type="primary" onClick={handleCreate}>
            <RiAddCircleLine /> Tạo luồng nghiệp vụ
          </Button>
        </div>
      </div>
      <Spin
        spinning={requestState === 0}
        size="large"
        style={{ position: "static" }}
      >
        <div className="TableContainer">
          <table className="table table-striped sticky-header">
            <thead>
              <tr>
                <th>Client App</th>
                <th>Client ID</th>
                <th>Tên luồng nghiệp vụ</th>
                <th>Mức độ ưu tiên</th>
                {/* <th>Chứng từ liên quan</th> */}
                {role === "ADMIN_OCR" && <th></th>}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item: any, index: any) => {
                  return (
                    <tr key={`row_${index}`}>
                      <td>{item.client_app}</td>
                      <td>{item.client_id}</td>
                      <td>{item.doc_type}</td>
                      <td>{item.doc_type_priority}</td>
                      {/* <td>
                        {item.document_priority_configs &&
                          item.document_priority_configs.length > 0 &&
                          item.document_priority_configs.map(
                            (doc_config: any, doc_config_idx: any) => {
                              return (
                                <div key={`doc_config_${doc_config_idx}`}>
                                  - {doc_config.document_name}
                                </div>
                              );
                            }
                          )}
                      </td> */}
                      <td>
                        <div className="ButtonContainer">
                          {item.status !== "DELETED" && (
                            <Button
                              type="default"
                              className="SearchButton"
                              onClick={() => handleUpdate(item)}
                            >
                              <RiEditLine />
                              Cập nhật
                            </Button>
                          )}
                          {role === "ADMIN_OCR" &&
                            item.status !== "DELETED" && (
                              <Button
                                type="primary"
                                className="ResetButton"
                                onClick={() => handleDelete(item.id)}
                                danger
                              >
                                <RiDeleteBin6Line /> Xoá
                              </Button>
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {(!data || data.length === 0) && (
                <tr>
                  <td colSpan={5}>Không có dữ liệu</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
          totalRecord={totalRecord}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onChangePageNumber={handleChangePageNumber}
          onChangePageSize={handleChangePageSize}
        />
      </Spin>
      <FlowComponent
        modalStatus={currentItem && itemShow === currentItem.id}
        currentItem={currentItem}
        clientAppList={clientAppList}
        documentList={documentData}
        flowList={listFlow}
        prioritySet={prioritySet}
        onCancel={handleCancel}
        onSave={handleSave}
        onChangeCurrentItem={handleChangeCurrentItem}
      />
    </div>
  );
};
