import { HiOutlineArrowsExpand } from 'react-icons/hi';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export const FileComponent = (props: any) => {
  const { file, expandFile, showExpand } = props;

  return (
    <>
      <div className='FileContainer'>
        <div className='ContentContainer'>
          {showExpand && (
            <div className='IconContainer' onClick={expandFile}>
              <HiOutlineArrowsExpand />
            </div>
          )}
          <Document file={file} className='ThumbnailPdf'>
            <Page pageNumber={1} />
          </Document>
        </div>
      </div>
    </>
  );
};
