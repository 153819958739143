import { Form, Input, Select } from 'antd';
import { TiDelete } from 'react-icons/ti';
import { ModalComponent } from '../../../components';
import './index.scss';

export const FlowComponent = (props: any) => {
  const [form] = Form.useForm();
  const {
    modalStatus,
    currentItem,
    clientAppList,
    documentList,
    flowList,
    onSave,
    onCancel,
    onChangeCurrentItem,
    prioritySet,
  } = props;
  if (currentItem) form.setFieldsValue(currentItem);

  const onChangeDocumentList = (selected: any) => {
    const selected_item = documentList.find((e: any) => e.id.toString() === selected);
    onChangeCurrentItem({
      ...currentItem,
      document_priority_configs: [
        ...currentItem.document_priority_configs,
        {
          id: selected_item.id,
          document_name: selected_item.description,
          priority: 0,
        },
      ],
    });
  };

  const onChangePriority = (id: any, input: any) => {
    const newValue = input.target.value;

    onChangeCurrentItem({
      ...currentItem,
      document_priority_configs: [
        ...currentItem.document_priority_configs.slice(0, id),
        {
          id: currentItem.document_priority_configs[id].id,
          document_name: currentItem.document_priority_configs[id].document_name,
          priority: newValue,
        },
        ...currentItem.document_priority_configs.slice(id + 1),
      ],
    });
  };

  const onDeleteDocument = (id: any) => {
    onChangeCurrentItem({
      ...currentItem,
      document_priority_configs: [
        ...currentItem.document_priority_configs.slice(0, id),
        ...currentItem.document_priority_configs.slice(id + 1),
      ],
    });
  };

  var filteredOptions: any[] = documentList;
  if (documentList && documentList.length > 0)
    filteredOptions = documentList.filter((o: any) => {
      return (
        !currentItem ||
        !currentItem.document_priority_configs ||
        !currentItem.document_priority_configs.find((e: any) => e.id === o.id)
      );
    });

  const _renderModal = () => {
    if (!currentItem) return;

    return (
      <div className='FlowModal'>
        <Form
          form={form}
          layout='vertical'
          initialValues={currentItem}
          onValuesChange={() => onChangeCurrentItem({ ...currentItem, ...form.getFieldsValue() })}
        >
          <div className='Container' style={{ display: 'none' }}>
            <div className='FormItem'>
              <Form.Item name='id' label='Client app ID'>
                <Input readOnly />
              </Form.Item>
            </div>
          </div>
          <div className='Container'>
            <div className='FormItem'>
              <Form.Item name='client_id' label='Client App'>
                <Select
                  style={{ width: '100%' }}
                  disabled={currentItem.id}
                  placeholder='Client app'
                  options={clientAppList}
                />
              </Form.Item>
            </div>
          </div>
          <div className='Container'>
            <div className='FormItem'>
              <Form.Item name='doc_type' label='Tên luồng nghiệp vụ'>
                <Select
                  style={{ width: '100%' }}
                  disabled={currentItem.id}
                  placeholder='Tên luồng'
                  options={flowList}
                />
              </Form.Item>
            </div>
          </div>
          <div className='Container'>
            <div className='FormItem'>
              <Form.Item name='doc_type_priority' label='Mức độ ưu tiên của luồng nghiệp vụ'>
                <Input type='number' placeholder='Mức độ ưu tiên' min={0} />
              </Form.Item>
            </div>
            {prioritySet && prioritySet.has(parseInt(form.getFieldValue('doc_type_priority'))) && (
              <div
                style={{
                  color: 'rgba(222, 54, 24, 1)',
                  fontWeight: '400',
                  fontSize: '12px',
                  marginBottom: '10px',
                }}
              >
                Mức độ ưu tiên đã tồn tại. Bạn có chắc muốn đặt mức độ ưu tiên này.
              </div>
            )}
          </div>
          {/* <div className='Container'>
            <div className='FormItem'>Chứng từ liên quan:</div>

            <div className='ModalSearch'>
              <div className='SearchLabel'>Tìm kiếm</div>
              <Select
                showSearch
                value={''}
                style={{ flexGrow: 1 }}
                onChange={onChangeDocumentList}
                placeholder='Please select'
                options={filteredOptions.map((item: any) => ({
                  value: item.id.toString(),
                  label: item.description,
                }))}
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
              />
            </div>

            <div className='ModalTable'>
              <div className='TableRow TableHeader'>
                <div className='FirstCol'>Tên chứng từ</div>
                <div className='SecondCol'>Mức độ ưu tiên</div>
              </div>
              {currentItem.document_priority_configs &&
                currentItem.document_priority_configs.length > 0 &&
                currentItem.document_priority_configs.map((item: any, id: any) => {
                  return (
                    <div key={`row_${id}`} className='TableRow'>
                      <div className='FirstCol'>{item.document_name}</div>
                      <div className='SecondCol'>
                        <div className='PriorityInp'>
                          <Input
                            type='number'
                            value={item.priority}
                            onChange={(input: any) => onChangePriority(id, input)}
                          />
                        </div>

                        <TiDelete onClick={() => onDeleteDocument(id)} />
                      </div>
                    </div>
                  );
                })}
              {(!currentItem.document_priority_configs ||
                currentItem.document_priority_configs.length === 0) && (
                <div className='TableEmpty'>Chưa có chứng từ</div>
              )}
            </div>
          </div> */}
        </Form>
      </div>
    );
  };

  return (
    <>
      <ModalComponent
        title={
          currentItem && currentItem.id
            ? 'Cập nhật luồng xử lý chứng từ'
            : 'Tạo luồng xử lý chứng từ'
        }
        isModalVisible={modalStatus}
        onOk={onSave}
        onCancel={onCancel}
        cancelText='Huỷ'
        okText='Lưu'
      >
        {_renderModal()}
      </ModalComponent>
    </>
  );
};
