import createApiServices from "../createApiServices";

const api = createApiServices();

const GetDashboard = () => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/daily-request/dashboard`,
    method: "GET",
  });
};

const GetData = (page_size: any, page_number: any, filter: any) => {
  const { status, sortByPriority, search, search_type } = filter;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/daily-request?limit=${page_size}&page=${page_number}&status=${status}&sortByPriority=${sortByPriority}&search=${search}&search_type=${search_type}`,
    method: "GET",
  });
};

const UpdatePriority = (data: any) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/daily-request/update`,
    method: "PUT",
    data,
  });
};

const GetFileContent = (
  client_id: any,
  request_id: any,
  path: any,
  size: any = "thumbnail"
) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/file/${request_id}?size=${size}`,
    method: "POST",
    data: { client_id, path },
    responseType: "blob",
  });
};

const RetryRequest = (request_id: any) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/daily-request/retry`,
    method: "POST",
    data: { request_id },
  });
};

export const Daily = {
  GetDashboard,
  GetData,
  UpdatePriority,
  GetFileContent,
  RetryRequest,
};
