import createApiServices from "../createApiServices";

const api = createApiServices();

const SaveFlow = (data: any) => {
  var documentConfigs: any[] = [];
  if (data && data.document_priority_configs)
    documentConfigs = data.document_priority_configs.map(
      (item: any, id: any) => {
        return {
          document_id: item.id,
          priority: parseInt(item.priority),
        };
      }
    );

  if (data && data.id)
    return api.makeAuthRequest({
      url: `/ocr-internal/v1/queue-priority/update`,
      method: "PUT",
      data: {
        id: data.id,
        doc_type_priority: parseInt(data.doc_type_priority),
      },
    });
  else
    return api.makeAuthRequest({
      url: `/ocr-internal/v1/queue-priority/create`,
      method: "POST",
      data: {
        queuePriority: {
          client_id: data.client_id,
          doc_type: data.doc_type,
          doc_type_priority: parseInt(data.doc_type_priority),
          document_priority_configs: documentConfigs,
        },
      },
    });
};

const GetFlow = (page_size: any, page_number: any, filter: any) => {
  const { searchText, searchType } = filter;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/queue-priority?limit=${page_size}&page=${page_number}&search_text=${searchText}&search_type=${searchType}`,
    method: "GET",
  });
};

const DeleteFlow = (id: any) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/queue-priority/${id}`,
    method: "DELETE",
  });
};

export const Flow = {
  SaveFlow,
  GetFlow,
  DeleteFlow,
};
