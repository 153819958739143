import { Form, Input, Select, Tooltip } from "antd";
import { ModalComponent } from "../../../components";
import { RiInformationLine } from "react-icons/ri";

export const ClientAppModalComponent = (props: any) => {
  const [form] = Form.useForm();
  const {
    modalStatus,
    currentItem,
    onSave,
    onCancel,
    onChangeCurrentItem,
    prioritySet,
  } = props;
  if (currentItem) form.setFieldsValue(currentItem);

  const _renderModal = () => {
    if (!currentItem) return;

    const authType = [
      { value: "API_KEY", label: "API_KEY" },
      { value: "BEARER_TOKEN", label: "BEARER_TOKEN" },
    ];

    const { TextArea } = Input;

    return (
      <div className="ClientAppModal">
        <Form
          form={form}
          layout="vertical"
          initialValues={currentItem}
          onValuesChange={() => onChangeCurrentItem(form.getFieldsValue())}
        >
          <div className="Container" style={{ display: "none" }}>
            <div className="FormItem">
              <Form.Item name="id" label="Client app ID">
                <Input readOnly />
              </Form.Item>
            </div>
          </div>
          <div className="Container">
            <div className="FormItem">
              <Form.Item name="name" label="Tên Client App">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="Container">
            <div className="FormItem">
              <Form.Item name="priority" label="Mức độ ưu tiên">
                <Input type="number" min={0} />
              </Form.Item>
            </div>
            {prioritySet &&
              prioritySet.has(parseInt(form.getFieldValue("priority"))) && (
                <div
                  style={{
                    color: "rgba(222, 54, 24, 1)",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Mức độ ưu tiên đã tồn tại. Bạn có chắc muốn đặt mức độ ưu tiên
                  này.
                </div>
              )}
          </div>
          <div className="Container">
            <div className="FormItem">
              <Form.Item name="webhook_url" label="Webhook URL">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="Container">
            <div className="FormItem">
              <Form.Item name="auth_type" label="Loại xác thực">
                <Select
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  options={authType}
                />
              </Form.Item>
            </div>
          </div>

          <div
            style={{
              display:
                form.getFieldValue("auth_type") === "BEARER_TOKEN"
                  ? "initial"
                  : "none",
            }}
          >
            <div className="Container">
              <div className="FormItem">
                <Form.Item name="auth_url" label="URL đăng nhập">
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="Container">
              <div className="FormItem">
                <Form.Item name="auth_resp_key" label="Response key đăng nhập">
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="Container">
              <div className="FormItem">
                <Form.Item name="auth_header" label="Header đăng nhập">
                  <TextArea rows={3} />
                </Form.Item>
              </div>
            </div>
            <div className="Container">
              <div className="FormItem">
                <Form.Item name="auth_body" label="Body đăng nhập">
                  <TextArea rows={3} />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* <div
            className='Container'
            style={{ display: form.getFieldValue('auth_type') === 'API_KEY' ? 'initial' : 'none' }}
          >
            <div className='FormItem'>
              <Form.Item name='auth_token' label='Token đăng nhập'>
                <Input />
              </Form.Item>
            </div>
          </div> */}
          <div className="Container">
            <div className="FormItem">
              <Form.Item
                name="bucket_name"
                label={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div>Client bucket</div>
                    <Tooltip
                      title="Tên bucket phải có định dạng chữ thường và chỉ có gạch
                        nối - ở giữa"
                    >
                      <RiInformationLine size={20} />
                    </Tooltip>
                  </div>
                }
              >
                <Input placeholder="bucket-name" />
              </Form.Item>
            </div>
          </div>
          <div className="Container">
            <div className="FormItem">
              <Form.Item name="webhook_header" label="Webhook Header">
                <TextArea rows={3} />
              </Form.Item>
            </div>
          </div>
          <div className="Container">
            <div className="FormItem">
              <Form.Item name="bucket_path" label={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div>Bucket Path</div>
                    <Tooltip
                      title="Bucket Path mà Webhook API sẽ sao chép OCR file sang cho client"
                    >
                      <RiInformationLine size={20} />
                    </Tooltip>
                  </div>
                }
              >
                <Input />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      <ModalComponent
        title={
          currentItem && currentItem.id
            ? "Cập nhật Client App"
            : "Tạo Client App"
        }
        isModalVisible={modalStatus}
        onOk={onSave}
        onCancel={onCancel}
        cancelText="Huỷ"
        okText="Lưu"
      >
        {_renderModal()}
      </ModalComponent>
    </>
  );
};
