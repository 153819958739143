import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationService } from "../../components";
import { APIServices } from "../../utils";
import actions from "./actions";
import reqActions from "../request/actions";
import authActions from "../auth/actions";

function* saga_LoadData(payloadAction?: any): Generator<any, void, any> {
  try {
    const isFilter = payloadAction?.type === actions.types.UPDATE_FILTER;
    let isMaxSize: any = yield select(
      (state: any) => state.client_app.isMaxSize
    );
    let _pageNumber: Promise<any> = yield select(
      (state: any) => state.client_app.page_number
    );
    let pageNumber = isFilter ? 1 : _pageNumber;
    let _pageSize = yield select((state: any) => state.client_app.page_size);
    let pageSize = isMaxSize ? 99999999 : _pageSize;
    let _currentFilter: Promise<any> = yield select(
      (state: any) => state.client_app.current_filter
    );
    let currentFilter = _currentFilter;
    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.GetClientApp(
      pageNumber,
      pageSize,
      currentFilter
    );
    let response: any = _response;
    let { data } = response;
    const { paging, clients } = data;
    if (data) {
      yield put(actions.action.loadDataSuccess(clients, paging));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải danh sách Client App thất bại");
    }
  } catch (ex: any) {
    console.log("[Client app] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải danh sách Client App thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_saveData(action: any) {
  try {
    let { type } = action.payload;
    let _currentItem: Promise<any> = yield select(
      (state: any) => state.client_app.current_item
    );
    let currentItem = _currentItem;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.SaveClientApp(
      currentItem
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(reqActions.action.updateRequestState(1));
      yield saga_LoadData();
      NotificationService.success(
        type === "create" ? "Tạo Client App thành công" : "Cập nhật thành công"
      );
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error(
        type === "create" ? "Tạo Client App thất bại" : "Cập nhật thất bại"
      );
    }
  } catch (ex: any) {
    console.log(
      "[Client app] saga_LoadData Error: ",
      ex && ex.data && ex.data.message
    );
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error(
      ex && ex.data && ex.data.message
        ? ex.data.message
        : "Lưu Client App thất bại"
    );

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_deleteData(action: any) {
  try {
    let { client_id } = action.payload;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.DeleteClientApp(
      client_id
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(reqActions.action.updateRequestState(1));
      yield saga_LoadData();
      NotificationService.success("Xoá Client App thành công");
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Xoá Client App thất bại");
    }
  } catch (ex: any) {
    console.log("[Client App] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Xoá Client App thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_DATA, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_NUMBER, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_SIZE, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_FILTER, saga_LoadData);
  yield takeEvery(actions.types.SAVE_CURRENT_ITEM, saga_saveData);
  yield takeEvery(actions.types.DELETE_CURRENT_ITEM, saga_deleteData);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
