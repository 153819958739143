import {
  CLMSItemDetail,
  CLMSItemMapping,
  CLMS_DOC_TYPE,
} from "./clms-flow-mapping";
import {
  GREEN_FLOW_DOC_TYPE,
  GreenItemDetail,
  GreenItemMapping,
} from "./green-flow-mapping";

export const LoanMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  const result = [];
  for (let i = 0; i < ocr.length; i++) {
    const doc_type = ocr[i].document_type;
    if (CLMS_DOC_TYPE.includes(doc_type)) {
      const processedOCR = CLMSItemMapping(ocr[i]);
      result.push(processedOCR);
    } else if (GREEN_FLOW_DOC_TYPE.includes(doc_type)) {
      const processedOCR = GreenItemMapping(ocr[i]);
      result.push(processedOCR);
    }
  }
  return result;
};

export const LoanDetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];

  const result = [];
  for (let i = 0; i < ocr.length; i++) {
    const doc_type = ocr[i].doc_type;
    if (CLMS_DOC_TYPE.includes(doc_type)) {
      const processedOCR = CLMSItemDetail(ocr[i]);
      result.push(processedOCR);
    } else if (GREEN_FLOW_DOC_TYPE.includes(doc_type)) {
      const processedOCR = GreenItemDetail(ocr[i]);
      result.push(processedOCR);
    }
  }
  return result;
};
