import { Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";

import { DATE_RANGE_FORMAT } from "../../common";
import ButtonCopy from "../../components/buttonCoppy";
import FilterComponent from "../../components/filterComponent";
import { PaginationComponent } from "../../components/pagination";
import {
  A1DetailMapping,
  A2DetailMapping,
  B1DetailMapping,
  B2DetailMapping,
  B4DetailMapping,
  CLMSDetailMapping,
  DNADetailMapping,
  GreenDetailMapping,
  NWFDetailMapping,
  LoanDetailMapping,
  A3A4DetailMapping,
  D1DetailMapping,
  B1PLUSDetailMapping,
} from "../../const";
import { useActions } from "../../redux";
import { renderDateTime } from "../../utils";
import { Canvas, FileComponent, SignComponent } from "./component";

const dateFormat = "DD-MM-YYYY - HH:mm:ss";
const options = [
  { label: "Request ID", value: 1 },
  { label: "Loại File", value: 2 },
  { label: "Định dạng File", value: 3 },
  { label: "Client App", value: 4 },
];

export const LogPage = () => {
  const [isExpand, setIsExpand] = useState<any>(null);
  const [documentExpand, setDocumentExpand] = useState<any>(null);
  const [documentPath, setDocumentPath] = useState<any>(null);
  const [showCoord, setShowCoord] = useState<any>(null);
  const [fileIDs, setFileIDs] = useState<any>([]);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);

  const dispatch = useDispatch();
  const actions = useActions();
  const requestState = useSelector((state: any) => state.request.request_state);
  let data = useSelector((state: any) => state.log.data);
  let totalRecord = useSelector((state: any) => state.log.total_record);
  let pageNumber = useSelector((state: any) => state.log.page_number);
  let pageSize = useSelector((state: any) => state.log.page_size);
  let currentRequest = useSelector((state: any) => state.log.current_request);
  let detailData = useSelector((state: any) => state.log.detail_data);
  let clientAppData = useSelector((state: any) => state.client_app.data);

  useEffect(() => {
    if (!clientAppData) dispatch(actions.ClientAppActions.loadData(true));
    if (!data && clientAppData && clientAppData)
      dispatch(actions.LogActions.loadData());

    if (currentRequest && currentRequest.request_id && !detailData) {
      dispatch(actions.LogActions.loadDetailData());
    }
  }, [
    actions.LogActions,
    actions.ClientAppActions,
    dispatch,
    currentRequest,
    data,
    clientAppData,
    detailData,
  ]);

  const handleChangePageNumber = (page_number: any) => {
    dispatch(actions.LogActions.updatePageNumber(page_number));
  };

  const handleChangePageSize = (page_size: any) => {
    dispatch(actions.LogActions.updatePageSize(page_size));
  };

  const handleBack = () => {
    handleChangeCurrentRequest("", "", "", "", "", "");
    dispatch(actions.LogActions.loadDetailDataSuccess(null));
    setIsExpand(null);
    setDocumentExpand(null);
    setShowCoord(null);
    setFileIDs([]);
    setDocumentPath(null);
  };

  const handleChangeCurrentRequest = (
    client_id: any,
    request_id: any,
    time: any,
    doc_code: any,
    status: any,
    page: any
  ) => {
    dispatch(
      actions.LogActions.updateCurrentRequest({
        client_id,
        request_id,
        time,
        doc_code,
        status,
        page,
      })
    );
  };

  const handleExpandFile = (
    documentID: any,
    documentPath: any,
    fileIDs: any
  ) => {
    setIsExpand(1);
    setDocumentExpand(documentID);
    setShowCoord(null);
    setFileIDs(fileIDs);
    setDocumentPath(documentPath);
  };

  if (detailData && !documentExpand) {
    handleExpandFile(
      detailData[0].document_id,
      detailData[0].file_path,
      detailData[0].file_id
    );
  }

  const getMapping = (ocr: any) => {
    const { doc_code } = currentRequest;
    if (doc_code === "QF") return A1DetailMapping(ocr);
    if (doc_code === "INVOICE") return B1DetailMapping(ocr);
    if (doc_code === "KHDN") return GreenDetailMapping(ocr);
    if (doc_code === "NWF") return NWFDetailMapping(ocr);
    if (doc_code === "A2") return A2DetailMapping(ocr);
    if (doc_code === "B2") return B2DetailMapping(ocr);
    if (doc_code === "B4") return B4DetailMapping(ocr);
    if (doc_code === "CLMS") return CLMSDetailMapping(ocr);
    if (doc_code === "DNA") return DNADetailMapping(ocr);
    if (doc_code === "LOANXPRESS") return LoanDetailMapping(ocr);
    if (doc_code === "A3A4") return A3A4DetailMapping(ocr);
    if (doc_code === "D1") return D1DetailMapping(ocr);
    if (doc_code === "B1PLUS" || doc_code === "BANG_KE")
      return B1PLUSDetailMapping(ocr);
    return null;
  };

  const onSearch = (
    fromTime: moment.Moment,
    toTime: moment.Moment,
    searchText: string,
    searchType: number
  ) => {
    dispatch(
      actions.LogActions.updateFilter({
        from_time: moment(fromTime).format(DATE_RANGE_FORMAT),
        to_time: moment(toTime).format(DATE_RANGE_FORMAT),
        search_text: searchText,
        search_type: searchType,
      })
    );
    // dispatch(actions.LogActions.loadData());
  };
  const renderStatus = (status: number) => {
    if (status === 7) return "Thành công";
    if (status === 0) return "Ghi nhận";
    if ([1, 3, 5, 6].includes(status)) return "Đang xử lý";
    if ([2, 4, 8].includes(status)) return "Thất bại";
    return "";
  };
  const callBackRender = (callBack: any) => {
    switch (callBack) {
      case "SUCCESS":
        return "Thành công";
      case "FAILED":
        return "Thất bại";
      default:
        return "Không Callback";
    }
  };

  return (
    <div className="AppPage">
      {currentRequest && currentRequest.request_id === "" && (
        <>
          <div className="PageTitle">OCR Logs</div>
          <FilterComponent onSearch={onSearch} options={options} />

          <Spin
            spinning={requestState === 0}
            size="large"
            style={{ position: "static" }}
          >
            <div className="TableContainer">
              <table className="table table-striped sticky-header">
                <thead>
                  <tr>
                    <th>Request ID</th>
                    <th>Client App</th>
                    <th>Loại File</th>
                    <th>Định dạng File</th>
                    <th>Tên chứng từ</th>
                    <th>Số trang</th>
                    <th>Số trường</th>
                    <th>Trạng thái OCR</th>
                    <th>Thời gian upload file</th>
                    <th>Thời gian split file thành công</th>
                    <th>Thời gian đẩy lên queue</th>
                    <th>Thời gian bắt đầu OCR</th>
                    <th>Thời gian bắt đầu mỗi chứng từ</th>
                    <th>Khoảng thời gian thực hiện (s)</th>
                    <th>Thời gian kết thúc mỗi chứng từ</th>
                    <th>Thời gian OCR xong</th>
                    <th>Thời gian Callback</th>
                    <th>Trạng thái Callback</th>
                    <th>Thời gian kết thúc</th>
                    <th>Tổng thời gian thực hiện</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((request: any, req_index: any) => {
                      const { documents } = request;

                      if (documents && documents.length > 0)
                        return documents.map((item: any, index: any) => {
                          return (
                            <tr key={`row_${index}`}>
                              {index === 0 && (
                                <td
                                  style={{ position: "relative" }}
                                  rowSpan={documents.length}
                                >
                                  <div>
                                    <div
                                      className="clickable-class"
                                      onClick={() =>
                                        handleChangeCurrentRequest(
                                          request.client_id,
                                          request.request_id,
                                          moment(item.updated_at).format(
                                            dateFormat
                                          ),
                                          request.doc_type,
                                          item.status,
                                          request.total_doc
                                        )
                                      }
                                    >
                                      {request.request_id}
                                    </div>
                                    <ButtonCopy value={request.request_id} />
                                  </div>
                                </td>
                              )}
                              {index === 0 && (
                                <td
                                  rowSpan={documents.length}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  {request.client_id}
                                </td>
                              )}
                              <td>{request.doc_type}</td>
                              <td>{request.file_type}</td>
                              <td>
                                {item.doc_type === "QF"
                                  ? item.doc_code
                                  : item.doc_type}
                              </td>
                              <td>{item.file_id.length}</td>
                              <td>{item.total_field}</td>
                              <td>{renderStatus(request.status)}</td>

                              {index === 0 && (
                                <>
                                  <td rowSpan={documents.length}>
                                    {renderDateTime(request?.created_at)}
                                  </td>
                                  {/* Thời gian upload file: */}
                                  <td rowSpan={documents.length}>
                                    {renderDateTime(request?.splited_at)}
                                  </td>
                                  {/* Thời gian split file thành công */}
                                  <td rowSpan={documents.length}>
                                    {renderDateTime(item?.created_at)}
                                  </td>
                                  {/* Thời gian đẩy lên queue */}
                                  <td rowSpan={documents.length}>
                                    {renderDateTime(request?.start_ocr_at)}
                                  </td>
                                </>
                              )}

                              <td>{renderDateTime(item?.start_ocr_at)}</td>
                              {/* Thời gian bắt đầu OCR */}
                              <td>
                                {Number(item?.time_used / 1000).toFixed(2)}
                              </td>
                              {/* Thời gian thực hiện OCR */}
                              <td>{renderDateTime(item?.finished_at)}</td>
                              {/* Thời gian kết thúc mỗi chứng từ */}

                              {index === 0 && (
                                <>
                                  <td rowSpan={documents.length}>
                                    {renderDateTime(request?.finished_ocr_at)}
                                  </td>
                                  {/* Thời gian OCR xong */}
                                  <td rowSpan={documents.length}>
                                    {renderDateTime(request?.callback_at)}
                                  </td>
                                  {/* Thời gian Callback */}
                                  <td rowSpan={documents.length}>
                                    {[0, 1, 3, 5, 6].includes(request?.status)
                                      ? ""
                                      : callBackRender(
                                          request?.callback_status
                                        )}
                                  </td>
                                  {/* Trạng thái Callback */}
                                  <td rowSpan={documents.length}>
                                    {renderDateTime(request?.finished_at)}
                                  </td>
                                  {/* Thời gian kết thúc */}

                                  <td rowSpan={documents.length}>
                                    {Number(request?.total_time_ocr).toFixed(
                                      2
                                    ) ?? 0}
                                  </td>
                                  {/* Tổng thời gian thực hiện finished_at -  created_at*/}
                                </>
                              )}
                            </tr>
                          );
                        });
                      else
                        return (
                          <tr key={`row_req_${req_index}`}>
                            <td>{request.request_id}</td>
                            <td colSpan={8}>Không có chứng từ</td>
                          </tr>
                        );
                    })}
                  {(!data || data.length === 0) && (
                    <tr>
                      <td colSpan={10}>Không có dữ liệu</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <PaginationComponent
              totalRecord={totalRecord}
              pageNumber={pageNumber}
              pageSize={pageSize}
              onChangePageNumber={handleChangePageNumber}
              onChangePageSize={handleChangePageSize}
            />
          </Spin>
        </>
      )}
      {currentRequest && currentRequest.request_id && (
        <div className="DetailContainer">
          <div className="PageTitle">
            <HiArrowLeft onClick={handleBack} />
            Request ID {currentRequest.request_id}
          </div>
          <Spin
            spinning={requestState === 0}
            size="large"
            style={{ position: "static" }}
          >
            <div className="InfoContainer">
              <div className="InfoCol">
                <div>
                  <span className="InfoLabel">Thời gian</span>
                  <span className="InfoValue">{currentRequest.time}</span>
                </div>
                <div>
                  <span className="InfoLabel">Client ID</span>
                  <span className="InfoValue">{currentRequest.client_id}</span>
                </div>
              </div>
              <div className="InfoCol">
                <div>
                  <span className="InfoLabel">Tên chứng từ</span>
                  <span className="InfoValue">{currentRequest.doc_code}</span>
                </div>
                <div>
                  <span className="InfoLabel">Số lượng BCT</span>
                  <span className="InfoValue">{currentRequest.page}</span>
                </div>
              </div>
              <div className="InfoCol">
                <div>
                  <span className="InfoLabel">Trạng thái OCR</span>
                  <span className="InfoValue">
                    {currentRequest.status < 0
                      ? "Thất bại"
                      : currentRequest.status === 0
                      ? "Đang thực hiện"
                      : "Thành công"}
                  </span>
                </div>
                <div></div>
              </div>
            </div>
            <div className="OCRContainer">
              <div className="Page__Left">
                <div className="ExpandContainer">
                  <div className="ExpandController">
                    <div className="ThumbnailFileContainer">
                      {detailData &&
                        detailData.map((document: any, id: any) => {
                          if (
                            document.file_path &&
                            document.file_id &&
                            document.file_id.length > 0
                          ) {
                            return (
                              <div
                                key={`doc_${id}`}
                                style={{
                                  opacity:
                                    document.document_id === documentExpand
                                      ? "1"
                                      : "0.3",
                                }}
                                className="ThumbnailFile"
                              >
                                <FileComponent
                                  size="thumb"
                                  path={
                                    document.file_path +
                                    "/" +
                                    document.file_id[0]
                                  }
                                  clientID={currentRequest.client_id}
                                  requestID={currentRequest.request_id}
                                  handleClick={() =>
                                    handleExpandFile(
                                      document.document_id,
                                      document.file_path,
                                      document.file_id
                                    )
                                  }
                                  allowClick={true}
                                />
                              </div>
                            );
                          } else return <></>;
                        })}
                    </div>
                    <div className="ControllerContainer">
                      <HiArrowLeft
                        onClick={() => {
                          if (isExpand > 1) setIsExpand(isExpand - 1);
                        }}
                      />

                      <span>
                        {isExpand}/{fileIDs.length}
                      </span>

                      <HiArrowRight
                        onClick={() => {
                          if (isExpand < fileIDs.length)
                            setIsExpand(isExpand + 1);
                        }}
                      />
                    </div>
                  </div>
                  {documentExpand && (
                    <div className="CanvasContainer">
                      <div className="PdfCanvas">
                        <FileComponent
                          size="original"
                          path={documentPath + "/" + fileIDs[isExpand - 1]}
                          clientID={currentRequest.client_id}
                          requestID={currentRequest.request_id}
                          allowClick={false}
                          loaded={(height: any, width: any) => {
                            setOriginalHeight(height);
                            setOriginalWidth(width);
                          }}
                        />

                        <Canvas
                          bb={showCoord}
                          originalWidth={originalWidth}
                          originalHeight={originalHeight}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="Page__Right">
                {getMapping(detailData) &&
                  getMapping(detailData).length > 0 &&
                  getMapping(detailData).map((doc: any, index: any) => {
                    if (documentExpand && doc.document_id !== documentExpand)
                      return <></>;
                    var table_data = doc.ocrData;
                    return (
                      <div key={`doc_${index}`}>
                        <div className="ResultTitleContainer">
                          <div className="ResultTitle">Kết quả trích xuất</div>
                        </div>

                        <table>
                          <thead>
                            <tr>
                              <th>Trường thông tin</th>
                              <th>Kết quả OCR</th>
                              <th>Cập nhật OCR</th>
                              <th>Độ tin cậy (%)</th>
                            </tr>
                          </thead>
                          <tbody className="ContractTable">
                            {table_data.map((item: any, id: any) => {
                              return (
                                <tr
                                  key={`row_${id}`}
                                  className={`${
                                    item.coords &&
                                    documentExpand &&
                                    item.image_index === isExpand - 1
                                      ? "RowClick"
                                      : ""
                                  } ${item?.is_bold ? "text-bold" : ""}`}
                                  onMouseOver={() => {
                                    if (item.image_index === isExpand - 1)
                                      setShowCoord(item.coords);
                                  }}
                                  onMouseOut={() => {
                                    setShowCoord(null);
                                  }}
                                >
                                  <td className="First">{item.attribute}</td>
                                  <td
                                    className={
                                      item.value ? "Middle" : "Middle Null"
                                    }
                                  >
                                    {item?.hasImage ? (
                                      <FileComponent
                                        size="original"
                                        path={documentPath + "/" + item.value}
                                        clientID={currentRequest.client_id}
                                        requestID={currentRequest.request_id}
                                        allowClick={false}
                                        loaded={(height: any, width: any) => {
                                          setOriginalHeight(height);
                                          setOriginalWidth(width);
                                        }}
                                      />
                                    ) : item.value ? (
                                      item.value
                                    ) : (
                                      "--"
                                    )}
                                  </td>
                                  <td
                                    className={
                                      item.value_update
                                        ? "Middle"
                                        : "Middle Null"
                                    }
                                  >
                                    {item.value_update
                                      ? item.value_update
                                      : "--"}
                                  </td>
                                  <td
                                    className="Last"
                                    style={{
                                      color:
                                        item.score && parseInt(item.score) < 50
                                          ? "red"
                                          : "initial",
                                    }}
                                  >
                                    {item.score ? item.score : "--%"}
                                  </td>
                                </tr>
                              );
                            })}
                            {doc.type === "QF" && (
                              <SignComponent
                                documentID={doc.document_id}
                                requestID={currentRequest.request_id}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Spin>
        </div>
      )}
    </div>
  );
};
