const prefix = 'auth/';

const types = {
  UPDATE_LOGIN_INFO: prefix + 'update_login_info',
  LOAD_ACCESS_TOKEN: prefix + 'load_access_token',
  LOAD_ACCESS_TOKEN_SUCCESS: prefix + 'load_access_token_sucess',

  UPDATE_OTP: prefix + 'update_otp',
  VERIFY_OTP: prefix + 'verify_otp',
  VERIFY_OTP_SUCCESS: prefix + 'verify_otp_sucess',

  LOG_OUT: prefix + 'log_out',

  SSO_LOGIN: prefix + 'sso_login',
  GET_INFO: prefix + 'get_info',
  LOAD_EXCHANGE_ACCESS_TOKEN: prefix + 'load_exchange_access_token',
  LOAD_EXCHANGE_ACCESS_TOKEN_SUCCESS: prefix + 'load_exchange_access_token_sucess',
};

const action = {
  updateLoginInfo: (loginInfo: any) => {
    return {
      type: types.UPDATE_LOGIN_INFO,
      payload: { loginInfo },
    };
  },
  loadAccessToken: () => {
    return {
      type: types.LOAD_ACCESS_TOKEN,
    };
  },
  loadAccessTokenSuccess: (token: any) => {
    return {
      type: types.LOAD_ACCESS_TOKEN_SUCCESS,
      payload: { token },
    };
  },

  updateOTP: (otp: any) => {
    return {
      type: types.UPDATE_OTP,
      payload: { otp },
    };
  },
  verifyOTP: () => {
    return {
      type: types.VERIFY_OTP,
    };
  },
  verifyOTPSuccess: (token: any) => {
    return {
      type: types.VERIFY_OTP_SUCCESS,
      payload: { token },
    };
  },

  logOut: () => {
    return {
      type: types.LOG_OUT,
    };
  },

  ssoLogin: (data: any) => {
    return {
      type: types.SSO_LOGIN,
      payload: { data },
    };
  },

  loadExchangeAccessToken: (data: any) => {
    return {
      type: types.LOAD_EXCHANGE_ACCESS_TOKEN,
      payload: { data },
    };
  },
  loadExchangeAccessTokenSuccess: (token: any, mfa_user_info: any) => {
    return {
      type: types.LOAD_EXCHANGE_ACCESS_TOKEN_SUCCESS,
      payload: { token, mfa_user_info },
    };
  },
};

const actions = {
  types,
  action,
};

export default actions;

export const AuthActions = action;
