import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { RiAddCircleLine, RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import ClientFilter from "../../components/clientFilter";
import { showConfirm } from "../../components/modal";
import { error } from "../../components/notification";
import { useActions } from "../../redux";
import { renderDateTime } from "../../utils";
import { UserGroupModalComponent, UserModalComponent } from "./component";
import { PaginationComponent } from "../../components";
var role = localStorage.getItem("role");
const options = [
  { label: "ID", value: 1 },
  { label: "Group User", value: 2 },
  { label: "Client App", value: 3 },
];

export const UserGroupPage = () => {
  const dispatch = useDispatch();
  const actions = useActions();
  const [itemShow, setItemShow] = useState<any>(null);
  const [groupID, setGroupID] = useState<any>(null);

  const requestState = useSelector((state: any) => state.request.request_state);
  let data = useSelector((state: any) => state.user_group.data);
  let currentItem = useSelector((state: any) => state.user_group.current_item);
  let clientAppData = useSelector((state: any) => state.client_app.data);

  let totalRecord = useSelector((state: any) => state.user_group.total_record);
  let pageNumber = useSelector((state: any) => state.user_group.page_number);
  let pageSize = useSelector((state: any) => state.user_group.page_size);

  useEffect(() => {
    dispatch(actions.UserGroupActions.loadData());
    dispatch(actions.ClientAppActions.loadData(true));
  }, [actions.UserGroupActions, actions.ClientAppActions, dispatch]);

  useEffect(() => {
    // This function will be called when the component is unmounted
    return () => {
      dispatch(actions.UserGroupActions.resetFilter());
    };
  }, [dispatch, actions.UserGroupActions]);

  const handleCreate = () => {
    dispatch(
      actions.UserGroupActions.updateCurrentItem({
        id: "",
        group_name: "",
      })
    );
    setItemShow("");
  };

  const handleUpdate = (item: any) => {
    dispatch(
      actions.UserGroupActions.updateCurrentItem({
        id: item.id.toString(),
        group_name: item.name,
        clients: item.clients.map((client: any, _: any) => {
          return {
            value: client.id.toString(),
            label: client.client_alias,
          };
        }),
      })
    );
    setItemShow(item.id.toString());
  };

  const handleDelete = (group_id: any) => {
    showConfirm({
      title: "Chắc chắn xoá User Group này?",
      content: "",
      onOk: () => {
        dispatch(actions.UserGroupActions.deleteCurrentItem(group_id));
      },
      onCancel: () => {},
    });
  };

  const handleChangeCurrentItem = (data: any) => {
    dispatch(
      actions.UserGroupActions.updateCurrentItem({
        ...data,
        id: currentItem.id,
      })
    );
  };

  const handleCancel = () => {
    dispatch(actions.UserGroupActions.updateCurrentItem(null));
    setItemShow(null);
  };

  const handleSave = () => {
    const { group_name, id, clients } = currentItem;
    if (group_name === "") return error("Vui lòng nhập tên group");
    if (id && (!clients || clients.length === 0))
      return error("Vui lòng chọn Client App");

    if (clients && clients.length > 0 && typeof clients[0] === "object") {
      dispatch(
        actions.UserGroupActions.updateCurrentItem({
          ...currentItem,
          clients: clients.map((client: any, _: any) => {
            return client.value.toString();
          }),
        })
      );
    }

    dispatch(actions.UserGroupActions.saveCurrentItem());
    setItemShow(null);
  };

  const handleChangePageNumber = (page_number: any) => {
    dispatch(actions.UserGroupActions.updatePageNumber(page_number));
  };

  const handleChangePageSize = (page_size: any) => {
    dispatch(actions.UserGroupActions.updatePageSize(page_size));
  };

  const onSearch = (searchText: string, searchType: number) => {
    let filter = {};

    switch (searchType) {
      case 1:
        filter = { id: searchText, name: "", clientApp: "" };
        break;
      case 2:
        filter = { name: searchText, clientApp: "", id: "" };
        break;
      case 3:
        filter = { clientApp: searchText, name: "", id: "" };
        break;
      default:
        filter = { name: searchText, clientApp: "", id: "" };
        break;
    }
    dispatch(actions.UserGroupActions.updateFilter({ ...filter }));
  };

  var clientAppList = [];
  if (clientAppData && clientAppData.length > 0)
    clientAppList = clientAppData.map((item: any, _: any) => {
      return {
        value: item.id.toString(),
        label: item.client_alias,
      };
    });

  if (!role || role === "") role = localStorage.getItem("role");

  return (
    <div className="AppPage">
      <div className="PageTitle">User Group Management</div>
      <div className="head-section">
        <ClientFilter onSearch={onSearch} options={options} />

        <div className="CreateButtonContainer">
          <Button type="primary" onClick={handleCreate}>
            <RiAddCircleLine /> Tạo User Group
          </Button>
        </div>
      </div>

      <Spin
        spinning={requestState === 0}
        size="large"
        style={{ position: "static" }}
      >
        <div className="TableContainer">
          <table className="table table-striped sticky-header">
            <thead>
              <tr>
                <th>Group ID</th>
                <th>Group User</th>
                <th>Client Apps</th>
                <th>Thời gian tạo</th>
                <th>Tạo bởi</th>
                <th>Thời gian cập nhật</th>
                <th>Cập nhật bởi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map((item: any, index: any) => {
                  return (
                    <tr key={`row_${index}`}>
                      <td style={{ width: "100px" }}>{item.id}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => setGroupID(item.id)}
                      >
                        {item.name}
                      </td>
                      <td>
                        {item.clients &&
                          item.clients.map((client: any, client_index: any) => {
                            return (
                              <div key={`label_${client_index}`}>
                                {client.client_alias}
                              </div>
                            );
                          })}
                      </td>
                      <td>{renderDateTime(item.createdAt)}</td>
                      <td>{item.createdUser}</td>
                      <td>{renderDateTime(item.updatedAt)}</td>
                      <td>{item.updatedUser}</td>
                      <td>
                        <div className="ButtonContainer">
                          <Button
                            type="default"
                            className="SearchButton"
                            onClick={() => handleUpdate(item)}
                          >
                            <RiEditLine />
                            Cập nhật
                          </Button>
                          {role === "ADMIN_OCR" && (
                            <Button
                              type="primary"
                              className="ResetButton"
                              onClick={() => handleDelete(item.id)}
                              danger
                            >
                              <RiDeleteBin6Line /> Xoá
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {(!data || data.length === 0) && (
                <tr>
                  <td colSpan={8}>Không có dữ liệu</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Spin>
      <UserGroupModalComponent
        modalStatus={currentItem && itemShow === currentItem.id}
        currentItem={currentItem}
        clientAppList={clientAppList}
        onCancel={handleCancel}
        onSave={handleSave}
        onChangeCurrentItem={handleChangeCurrentItem}
      />
      <UserModalComponent groupID={groupID} onCancel={() => setGroupID(null)} />
      <div className="client-pagination">
        {data && data.length > 0 && (
          <div className="QuantityContainer">
            Số lượng User groups: {totalRecord}
          </div>
        )}
        <PaginationComponent
          totalRecord={totalRecord}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onChangePageNumber={handleChangePageNumber}
          onChangePageSize={handleChangePageSize}
        />
      </div>
    </div>
  );
};
