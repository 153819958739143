const InvoiceMap = new Map(
  Object.entries({
    customer_name: 'Tên khách hàng',
    customer_tax: 'Mã số thuế khách hàng',
    doc_type: 'Loại giấy tờ',
    doi: 'Ngày cấp',
    invoice_number: 'Số hoá đơn',
    provider_add: 'Địa chỉ người bán',
    provider_bank: 'Ngân hàng người bán',
    provider_bank_acc: 'Số tài khoản người bán',
    provider_district: 'Địa chỉ người bán (Quận/Huyện)',
    provider_province: 'Địa chỉ người bán (Tỉnh/Thành phố)',
    provider_name: 'Tên người bán',
    provider_phone: 'Số điện thoại người bán',
    provider_web: 'Website người bán',
    provider_tax: 'Mã số thuế người bán',
    sign: 'Ký hiệu',
    sign_date: 'Ngày ký',
    sign_name: 'Người ký',
    total_amount: 'Tổng đơn giá',
    vat_amount: 'Thuế GTGT',
  })
);

export const B1Mapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.document_code,
      score: isNaN(document.document_code_score)
        ? document.document_code_score
        : document.document_code_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.document_code_score) * 100, 99).toFixed(0),
    });

    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        if (ocr_data && ocr_data.length > 0) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
              Array.from(InvoiceMap.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0 ? InvoiceMap.get(item) : `${InvoiceMap.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0 ? InvoiceMap.get(item) : `${InvoiceMap.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }

    return {
      type: document.doc_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};

export const B1DetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.doc_code,
      score: isNaN(document.doc_score)
        ? document.doc_score
        : document.doc_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
    });

    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        const ocr_update = document.ocr_update ? document.ocr_update : null;
        if (ocr_data && ocr_data.length > 0) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
              Array.from(InvoiceMap.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0 ? InvoiceMap.get(item) : `${InvoiceMap.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    value_update: ocr_update && ocr_update[field.id],
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0 ? InvoiceMap.get(item) : `${InvoiceMap.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    value_update: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }

    return {
      type: document.doc_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};
