// DINH_GIA
const GeneralInfo_DINH_GIA_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const AssetInfo_DINH_GIA_D1Map = new Map(
  Object.entries({
    asset_name: "Tên loại tài sản",
    asset_address: "Địa chỉ tài sản",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

// BIEN_NHAN
const GeneralInfo_BIEN_NHAN_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const AssetInfo_BIEN_NHAN_D1Map = new Map(
  Object.entries({
    asset_name: "Tên tài sản",
    asset_address: "Địa chỉ tài sản",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

// CK_TC_TS
const GeneralInfo_CK_TC_TS_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const AssetInfo_CK_TC_TS_D1Map = new Map(
  Object.entries({
    asset_name: "Tên loại tài sản",
    asset_address: "Địa chỉ tài sản",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

// TT_DK_TDC
const GeneralInfo_TT_DK_TDC_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    document_number: "Số",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_TT_DK_TDC_D1Map = new Map(
  Object.entries({
    brc_code: "Số đăng ký kinh doanh",
    id_number: "Số GTTT",
  })
);

// TT_CBL
const GeneralInfo_TT_CBL_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    document_number: "Số",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_TT_CBL_D1Map = new Map(
  Object.entries({
    brc_code: "Số đăng ký kinh doanh",
    id_number: "Số GTTT",
  })
);

// HDTD
const GeneralInfo_HDTD_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    document_number: "Số",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_HDTD_D1Map = new Map(
  Object.entries({
    brc_code: "Số đăng ký kinh doanh",
    id_number: "Số GTTT",
  })
);

// BB_HOP
const GeneralInfo_BB_HOP_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_BB_HOP_D1Map = new Map(
  Object.entries({
    customer_name: "Tên Công ty",
    brc_code: "Số đăng ký kinh doanh",
  })
);

// HDTC
const GeneralInfo_HDTC_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    document_number: "Số",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_HDTC_D1Map = new Map(
  Object.entries({
    brc_code: "Số đăng ký kinh doanh",
    id_number: "Số GTTT",
  })
);

const AssetInfo_HDTC_D1Map = new Map(
  Object.entries({
    asset_name: "Tên loại tài sản",
    land_number: "Số thửa",
    land_map_number: "Số tờ bản đồ",
    asset_address: "Địa chỉ tài sản",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

// THOA_THUAN_BH
const GeneralInfo_THOA_THUAN_BH_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
    contract_number: "Số HĐTC",
  })
);

const AssetInfo_THOA_THUAN_BH_D1Map = new Map(
  Object.entries({
    asset_name: "Tên loại tài sản",
    asset_address: "Địa chỉ tài sản",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

// XN_BAO_DAM
const GeneralInfo_XN_BAO_DAM_D1Map = new Map(
  Object.entries({
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
    contract_number: "Số HĐTC",
  })
);

const AssetInfo_XN_BAO_DAM_D1Map = new Map(
  Object.entries({
    asset_name: "Tên tài sản",
    asset_address: "Địa chỉ tài sản",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

// GCK_TRA_NO
const GeneralInfo_GCK_TRA_NO_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_GCK_TRA_NO_D1Map = new Map(
  Object.entries({
    customer_name: "Tên bên cam kết",
  })
);

// HD_BAO_LANH
const GeneralInfo_HD_BAO_LANH_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    document_number: "Số",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_HD_BAO_LANH_D1Map = new Map(
  Object.entries({
    customer_name: "Tên bên bảo lãnh",
  })
);

// GCK
const GeneralInfo_GCK_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_GCK_D1Map = new Map(
  Object.entries({
    customer_name: "Tên bên cam kết",
  })
);

// GCK_KHONG_HUY_NGANG
const GeneralInfo_GCK_KHONG_HUY_NGANG_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_GCK_KHONG_HUY_NGANG_D1Map = new Map(
  Object.entries({
    customer_name: "Tên bên cam kết",
  })
);

const AssetInfo_GCK_KHONG_HUY_NGANG_D1Map = new Map(
  Object.entries({
    asset_address: "Địa chỉ tài sản",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

// GCK_CSH
const GeneralInfo_GCK_CSH_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_GCK_CSH_D1Map = new Map(
  Object.entries({
    customer_name: "Tên bên cam kết",
  })
);

const AssetInfo_GCK_CSH_D1Map = new Map(
  Object.entries({
    asset_address: "Địa chỉ tài sản",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

// VB_CHAP_THUAN
const GeneralInfo_VB_CHAP_THUAN_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_VB_CHAP_THUAN_D1Map = new Map(
  Object.entries({
    customer_name: "Tên công ty",
    brc_code: "Số đăng ký kinh doanh",
  })
);

const GeneralInfo_VB_BAO_LANH_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_VB_BAO_LANH_D1Map = new Map(
  Object.entries({
    customer_name: "Tên bên bảo lãnh",
  })
);

const GeneralInfo_GIAY_UY_QUYEN_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_GIAY_UY_QUYEN_D1Map = new Map(
  Object.entries({
    brc_code: "Số DKKD của Bên ủy quyền",
    id_number: "Số GTTT của Bên ủy quyền",
  })
);

const GeneralInfo_PYC_DK_TC_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    contract_number: "Số HĐTC",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const AssetInfo_PYC_DK_TC_D1Map = new Map(
  Object.entries({
    asset_address: "Địa chỉ tài sản",
    land_number: "Số thửa",
    land_map_number: "Số tờ bản đồ",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

const GeneralInfo_GCN_D1Map = new Map(
  Object.entries({
    certificate_type: "Loại chứng từ",
    certificate_number: "Số cấp GCN",
    register_number: "Số vào sổ",
  })
);

const AssetInfo_GCN_D1Map = new Map(
  Object.entries({
    land_number: "Thửa đất",
    land_map_number: "Tờ bản đồ",
    asset_address: "Địa chỉ tài sản",
    asset_address_pre: "Địa chỉ tài sản - phần thông tin trước Xã/Phường",
    asset_address_ward: "Địa chỉ tài sản - xã/phường",
    asset_address_ward_code: "Địa chỉ tài sản - mã xã/phường",
    asset_address_district: "Địa chỉ tài sản - quận/huyện/tp",
    asset_address_district_code: "Địa chỉ tài sản - mã quận/huyện/tp",
    asset_address_province: "Địa chỉ tài sản - tỉnh/tp",
    asset_address_province_code: "Địa chỉ tài sản - mã tỉnh/tp",
  })
);

const AdditionalPageInfo_GCN_D1Map = new Map(
  Object.entries({
    land_number: "Trang bổ sung GCN - Số Thửa",
    land_map_number: "Trang bổ sung GCN - Số Tờ bản đồ",
    certificate_number: "Trang bổ sung GCN - Số phát hành GCN",
    register_number: "Trang bổ sung GCN - Số vào sổ cấp GCN",
  })
);

const GeneralInfo_HDTD_SDBS_D1Map = new Map(
  Object.entries({
    title: "Tên mẫu biểu",
    qr_code: "QR code",
    document_number: "Số",
    qf_code: "Ký hiệu mẫu biểu",
  })
);

const CustomerInfo_HDTD_SDBS_D1Map = new Map(
  Object.entries({
    brc_code: "Số đăng ký kinh doanh",
    id_number: "Số GTTT",
  })
);

const getMap = (type: any, data_field: any = "") => {
  // 1
  if (type === "DINH_GIA") {
    if (data_field === "general_info") return GeneralInfo_DINH_GIA_D1Map;
    if (data_field === "asset_info") return AssetInfo_DINH_GIA_D1Map;
  }
  // 2
  if (type === "BIEN_NHAN") {
    if (data_field === "general_info") return GeneralInfo_BIEN_NHAN_D1Map;
    if (data_field === "asset_info") return AssetInfo_BIEN_NHAN_D1Map;
  }
  // 3
  if (type === "CK_TC_TS") {
    if (data_field === "general_info") return GeneralInfo_CK_TC_TS_D1Map;
    if (data_field === "asset_info") return AssetInfo_CK_TC_TS_D1Map;
  }
  // 4
  if (type === "TT_DK_TDC") {
    if (data_field === "general_info") return GeneralInfo_TT_DK_TDC_D1Map;
    if (data_field === "customer_info") return CustomerInfo_TT_DK_TDC_D1Map;
  }
  // 5
  if (type === "TT_CBL") {
    if (data_field === "general_info") return GeneralInfo_TT_CBL_D1Map;
    if (data_field === "customer_info") return CustomerInfo_TT_CBL_D1Map;
  }
  // 6
  if (type === "HDTD") {
    if (data_field === "general_info") return GeneralInfo_HDTD_D1Map;
    if (data_field === "customer_info") return CustomerInfo_HDTD_D1Map;
  }
  // 7
  if (type === "BB_HOP") {
    if (data_field === "general_info") return GeneralInfo_BB_HOP_D1Map;
    if (data_field === "customer_info") return CustomerInfo_BB_HOP_D1Map;
  }
  // 8
  if (type === "HDTC") {
    if (data_field === "general_info") return GeneralInfo_HDTC_D1Map;
    if (data_field === "customer_info") return CustomerInfo_HDTC_D1Map;
    if (data_field === "asset_info") return AssetInfo_HDTC_D1Map;
  }
  // 9
  if (type === "THOA_THUAN_BH") {
    if (data_field === "general_info") return GeneralInfo_THOA_THUAN_BH_D1Map;
    if (data_field === "asset_info") return AssetInfo_THOA_THUAN_BH_D1Map;
  }
  // 10
  if (type === "XN_BAO_DAM") {
    if (data_field === "general_info") return GeneralInfo_XN_BAO_DAM_D1Map;
    if (data_field === "asset_info") return AssetInfo_XN_BAO_DAM_D1Map;
  }
  // 11
  if (type === "GCK_TRA_NO") {
    if (data_field === "general_info") return GeneralInfo_GCK_TRA_NO_D1Map;
    if (data_field === "customer_info") return CustomerInfo_GCK_TRA_NO_D1Map;
  }
  // 12
  if (type === "HD_BAO_LANH") {
    if (data_field === "general_info") return GeneralInfo_HD_BAO_LANH_D1Map;
    if (data_field === "customer_info") return CustomerInfo_HD_BAO_LANH_D1Map;
  }
  // 13
  if (type === "GCK") {
    if (data_field === "general_info") return GeneralInfo_GCK_D1Map;
    if (data_field === "customer_info") return CustomerInfo_GCK_D1Map;
  }
  // 14
  if (type === "GCK_KHONG_HUY_NGANG") {
    if (data_field === "general_info")
      return GeneralInfo_GCK_KHONG_HUY_NGANG_D1Map;
    if (data_field === "customer_info")
      return CustomerInfo_GCK_KHONG_HUY_NGANG_D1Map;
    if (data_field === "asset_info") return AssetInfo_GCK_KHONG_HUY_NGANG_D1Map;
  }
  // 15
  if (type === "GCK_CSH") {
    if (data_field === "general_info") return GeneralInfo_GCK_CSH_D1Map;
    if (data_field === "customer_info") return CustomerInfo_GCK_CSH_D1Map;
    if (data_field === "asset_info") return AssetInfo_GCK_CSH_D1Map;
  }
  // 16
  if (type === "VB_CHAP_THUAN") {
    if (data_field === "general_info") return GeneralInfo_VB_CHAP_THUAN_D1Map;
    if (data_field === "customer_info") return CustomerInfo_VB_CHAP_THUAN_D1Map;
  }
  // 17
  if (type === "VB_BAO_LANH") {
    if (data_field === "general_info") return GeneralInfo_VB_BAO_LANH_D1Map;
    if (data_field === "customer_info") return CustomerInfo_VB_BAO_LANH_D1Map;
  }
  // 18
  if (type === "GIAY_UY_QUYEN") {
    if (data_field === "general_info") return GeneralInfo_GIAY_UY_QUYEN_D1Map;
    if (data_field === "customer_info") return CustomerInfo_GIAY_UY_QUYEN_D1Map;
  }
  // 19
  if (type === "PYC_DK_TC") {
    if (data_field === "general_info") return GeneralInfo_PYC_DK_TC_D1Map;
    if (data_field === "asset_info") return AssetInfo_PYC_DK_TC_D1Map;
  }
  // 20
  if (type === "GCN") {
    if (data_field === "general_info") return GeneralInfo_GCN_D1Map;
    if (data_field === "asset_info") return AssetInfo_GCN_D1Map;
    if (data_field === "additional_page_info")
      return AdditionalPageInfo_GCN_D1Map;
  }
  // 21
  if (type === "HDTD_SDBS") {
    if (data_field === "general_info") return GeneralInfo_HDTD_SDBS_D1Map;
    if (data_field === "customer_info") return CustomerInfo_HDTD_SDBS_D1Map;
  }
};

const D1_CAPEX_SPECIAL_KEY_HAS_IMAGES = ["qr_code"];

export const D1Mapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => D1ItemMapping(document));
};

export const D1ItemMapping = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.document_code,
    score: isNaN(document.document_code_score)
      ? document.document_code_score
      : document.document_code_score === -1
      ? 0
      : Math.min(
          Number.parseFloat(document.document_code_score) * 100,
          99
        ).toFixed(0),
  });

  if (document.ocr_data && typeof document.ocr_data === "object") {
    Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
      const ocr_data = document.ocr_data[`${data_field}`];
      const data_map = getMap(document.document_type, data_field);
      if (ocr_data && ocr_data.length > 0 && data_map) {
        ocr_data.forEach((ocr_item: any, index: any) => {
          if (
            ocr_item &&
            ocr_item instanceof Object &&
            Object.keys(ocr_item).length > 0
          ) {
            Array.from(data_map.keys()).forEach((item: any, _: any) => {
              if (ocr_item[item]) {
                let field = Array.isArray(ocr_item[item])
                  ? ocr_item[item][0]
                  : ocr_item[item];

                const hasImage = D1_CAPEX_SPECIAL_KEY_HAS_IMAGES.includes(item)
                  ? true
                  : false;
                ocrData.push({
                  attribute:
                    index === 0
                      ? data_map.get(item)
                      : `${data_map.get(item)} ${index + 1}`,
                  coords: field.coordinates,
                  value: field.value,
                  id: field.id,
                  image_index: field.image_index,
                  hasImage: hasImage,
                  score: isNaN(field.score)
                    ? field.score
                    : field.score === -1
                    ? 0
                    : Math.min(
                        Number.parseFloat(field.score) * 100,
                        99
                      ).toFixed(0),
                });
              } else
                ocrData.push({
                  attribute:
                    index === 0
                      ? data_map.get(item)
                      : `${data_map.get(item)} ${index + 1}`,
                  coords: null,
                  id: null,
                  image_index: null,
                  value: null,
                  certain: null,
                });
            });
          }
        });
      }
    });
  }

  return {
    type: document.document_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};

export const D1DetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => D1ItemDetail(document));
};

export const D1ItemDetail = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.doc_code,
    score: isNaN(document.doc_score)
      ? document.doc_score
      : document.doc_score === -1
      ? 0
      : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
  });

  if (document.ocr_data && typeof document.ocr_data === "object") {
    const ocr_update = document.ocr_update ? document.ocr_update : null;
    Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
      const ocr_data = document.ocr_data[`${data_field}`];
      const data_map = getMap(document.doc_type, data_field);

      if (ocr_data && ocr_data.length > 0 && data_map) {
        ocr_data.forEach((ocr_item: any, index: any) => {
          if (
            ocr_item &&
            ocr_item instanceof Object &&
            Object.keys(ocr_item).length > 0
          ) {
            Array.from(data_map.keys()).forEach((item: any, _: any) => {
              if (ocr_item[item]) {
                let field = Array.isArray(ocr_item[item])
                  ? ocr_item[item][0]
                  : ocr_item[item];

                const hasImage = D1_CAPEX_SPECIAL_KEY_HAS_IMAGES.includes(item)
                  ? true
                  : false;
                ocrData.push({
                  attribute:
                    index === 0
                      ? data_map.get(item)
                      : `${data_map.get(item)} ${index + 1}`,
                  coords: field.coordinates,
                  value: field.value,
                  value_update: ocr_update && ocr_update[field.id],
                  id: field.id,
                  image_index: field.image_index,
                  hasImage: hasImage,
                  score: isNaN(field.score)
                    ? field.score
                    : field.score === -1
                    ? 0
                    : Math.min(
                        Number.parseFloat(field.score) * 100,
                        99
                      ).toFixed(0),
                });
              } else
                ocrData.push({
                  attribute:
                    index === 0
                      ? data_map.get(item)
                      : `${data_map.get(item)} ${index + 1}`,
                  coords: null,
                  id: null,
                  image_index: null,
                  value: null,
                  value_update: null,
                  certain: null,
                });
            });
          }
        });
      }
    });
  }

  return {
    type: document.doc_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};
