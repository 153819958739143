const prefix = 'request/';

const types = {
  SET_INIT_STATE: prefix + 'set_init_state',
  LOAD_REQUEST_ID_SUCCESS: prefix + 'load_request_id_success',
  UPDATE_REQUEST_STATE: prefix + 'update_request_state',
};

const action = {
  setInitState: () => {
    return {
      type: types.SET_INIT_STATE,
    };
  },

  loadRequestIdSuccess: (data = {}) => {
    return {
      type: types.LOAD_REQUEST_ID_SUCCESS,
      payload: data,
    };
  },
  updateRequestState: (requestState: any) => {
    return {
      type: types.UPDATE_REQUEST_STATE,
      payload: { requestState },
    };
  },
};

const actions = {
  types,
  action,
};

export default actions;

export const RequestActions = action;
