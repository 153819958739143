import { Redirect, useLocation, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RouterLinks } from '../../const/router-link';

export const AuthorizationComponent = (props: any) => {
  const token = useSelector((state: any) => state.auth.token) || localStorage.getItem('token');
  const location = useLocation();

  if (!token || token === '') {
    return (
      <Redirect
        to={{
          pathname: RouterLinks.LOGIN_PAGE,
          state: {
            from: location.pathname,
          },
        }}
      />
    );
  }

  return (
    <Switch>
      <Route {...props} />
    </Switch>
  );
};
