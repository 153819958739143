import { Row, Form, DatePicker, Button, Select } from "antd";
import { IoIosCloseCircle, IoMdArrowRoundForward } from "react-icons/io";
import { RiDownloadCloud2Line } from "react-icons/ri";
import moment from "moment";
import { DATE_RANGE_FORMAT } from "../../../common";
import { useState } from "react";
import { NotificationService } from "../../../components";

//var role = localStorage.getItem('role');

export const ReportFilterComponent = (props: any) => {
  const [form] = Form.useForm();
  const [dates, setDates] = useState([]);
  const {
    currentFilter,
    onFilterChange,
    clientAppList,
    onSearch,
    onReset,
    onDownload,
    docTypes,
  } = props;

  const handleDateChange = (dates: any) => {
    onFilterChange({
      ...currentFilter,
      from_time:
        dates && dates[0] ? moment(dates[0]).format(DATE_RANGE_FORMAT) : "",
      to_time:
        dates && dates[1] ? moment(dates[1]).format(DATE_RANGE_FORMAT) : "",
      clients: currentFilter.clients,
    });
  };

  const handleClientChange = (clients: any) => {
    onFilterChange({
      ...currentFilter,
      from_time: currentFilter.from_time
        ? moment(currentFilter.from_time).format(DATE_RANGE_FORMAT)
        : "",
      to_time: currentFilter.to_time
        ? moment(currentFilter.to_time).format(DATE_RANGE_FORMAT)
        : "",
      clients,
    });
  };

  const onSelectionChange = (value: string[]) => {
    if (value.length > 7) {
      //show error message
      NotificationService.error("Chỉ chọn tối đa 7 loại tài liệu");
      return;
    }
    const selected = value.map((v) => v);
    onFilterChange({
      ...currentFilter,
      from_time: currentFilter.from_time
        ? moment(currentFilter.from_time).format(DATE_RANGE_FORMAT)
        : "",
      to_time: currentFilter.to_time
        ? moment(currentFilter.to_time).format(DATE_RANGE_FORMAT)
        : "",
      clients: currentFilter.clients,
      doc_types: selected,
    });
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleCalendarChange = (dates: any) => {
    if (dates && dates[0] && dates[1] && dates[1].diff(dates[0], "days") > 30) {
      dates[1] = moment(dates[0]).add(30, "days");
    }
    setDates(dates);
  };
  return (
    <div className="FormFilter">
      <Form layout="vertical" form={form}>
        <Row>
          <Form.Item>
            <DatePicker.RangePicker
              value={[currentFilter.from_time, currentFilter.to_time]}
              onChange={handleDateChange}
              format={DATE_RANGE_FORMAT}
              onCalendarChange={handleCalendarChange}
              separator={
                <div>
                  <IoMdArrowRoundForward />
                </div>
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item style={{ width: "200px" }}>
            <Select
              mode="multiple"
              value={currentFilter.clients}
              style={{ width: "100%" }}
              placeholder="ClientID"
              options={clientAppList}
              maxTagCount={2}
              maxTagTextLength={5}
              onChange={handleClientChange}
            />
          </Form.Item>

          <Form.Item style={{ minWidth: 250 }}>
            <Select
              showSearch
              mode="multiple"
              placeholder="Loại tài liệu"
              optionFilterProp="children"
              onChange={onSelectionChange}
              filterOption={filterOption}
              value={currentFilter.doc_types}
              options={docTypes}
              maxTagCount={2}
            />
          </Form.Item>

          <Form.Item>
            <div className="ButtonContainer">
              <Button
                type="primary"
                className="SearchButton"
                onClick={onSearch}
              >
                <span>Áp dụng </span>
              </Button>
              <Button type="default" className="ResetButton" onClick={onReset}>
                <IoIosCloseCircle /> Bỏ tuỳ chọn
              </Button>
              <Button
                type="primary"
                className="DownloadButton"
                onClick={onDownload}
              >
                <RiDownloadCloud2Line /> Tải báo cáo
              </Button>
            </div>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
