import React from 'react';

const useCanvas = (callback: any) => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext('2d');
    callback([canvas, ctx]);
  }, [callback]);

  return canvasRef;
};

export function Canvas(props: any) {
  const { bb, originalWidth, originalHeight } = props;
  const canvasRef = useCanvas(([canvas, ctx]: [any, any]) => {
    const imgEl = document.getElementById('document_image');
    const elWidth = imgEl?.clientWidth || 0;
    const elHeight = imgEl?.clientHeight || 0;

    const dpr = window.devicePixelRatio || 1;
    const bsr =
      ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio ||
      1;
    const ratio = dpr / bsr;

    canvas.width = elWidth * ratio;
    canvas.height = elHeight * ratio;
    canvas.style.width = elWidth + 'px';
    canvas.style.height = elHeight + 'px';
    ctx.setTransform(ratio, 0, 0, ratio, 0, 0);

    const viewWidth = elWidth;
    const viewHeight = elHeight;
    ctx.clearRect(0, 0, viewWidth, viewHeight);
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 1;

    if (bb) {
      const x = bb[0];
      const y = bb[1];
      const width = bb[2] - bb[0];
      const height = bb[3] - bb[1];
      const actualX = (x / originalWidth) * viewWidth;
      const actualY = (y / originalHeight) * viewHeight;
      const viewWidthBB = (width / originalWidth) * viewWidth;
      const viewHeightBB = (height / originalHeight) * viewHeight;
      ctx.strokeRect(actualX, actualY, viewWidthBB, viewHeightBB);
    }
  });

  return <canvas id='DrawCanvas' ref={canvasRef} />;
}
