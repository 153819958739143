import { useState, useRef } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Button } from 'antd';
import { NotificationService } from '..';
import { FileComponent } from './file-load';
import './index.scss';
import ImageIcon from '../../assets/img-ico.png';
import { EnvConfigs } from '../../const';

const acceptType = 'image/png,image/jpeg,image/jpg';

export function OnlyImageUpload(props: any) {
  const { file, onChangeFile, onExpandFile, showExpand } = props;
  const [dragActive, setDragActive] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const validateFile = (file: any) => {
    if (!acceptType.includes(file.type)) {
      NotificationService.error('Định dạng file đầu vào không hợp lệ');
      return false;
    }
    if (
      file.size < EnvConfigs.img_size_lower_bound ||
      file.size > EnvConfigs.img_size_upper_bound
    ) {
      NotificationService.error('Kích thước file đầu vào không hợp lệ');
      return false;
    }
    return true;
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && validateFile(e.dataTransfer.files[0]))
      onChangeFile(e.dataTransfer.files[0]);
    if (inputRef.current) inputRef.current.value = '';
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && validateFile(e.target.files[0])) onChangeFile(e.target.files[0]);

    if (inputRef.current) inputRef.current.value = '';
  };

  const onButtonClick = () => {
    if (inputRef && inputRef.current) inputRef.current.click();
  };

  return (
    <>
      <form id='FormOnlyImageUpload' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input
          ref={inputRef}
          type='file'
          accept={acceptType}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        <div
          id='LabelFileUpload'
          className={dragActive ? 'drag-active' : ''}
          style={{
            border: !file ? '2px dashed #69c0ff' : 'none',
            background: !file ? '#f9fafb' : '#ffffff',
          }}
        >
          {file ? (
            <>
              <FileComponent file={file} expandFile={onExpandFile} showExpand={showExpand} />
              <div className='UploadText'>JPG, JPEG, PNG</div>
              {!showExpand && (
                <div className='ButtonContainer'>
                  <Button className='Button Secondary' onClick={onButtonClick}>
                    Chọn file
                    <AiOutlinePlus />
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div>
              <div className='UploadIcon'>
                <img src={ImageIcon} alt='ico' />
              </div>
              <div className='UploadText'>JPG, JPEG, PNG</div>
              <div className='ButtonContainer'>
                <Button className='Button Secondary' onClick={onButtonClick}>
                  Chọn file
                  <AiOutlinePlus />
                </Button>
              </div>
            </div>
          )}
        </div>
        {dragActive && (
          <div
            id='DragFileElement'
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
    </>
  );
}
