import { Button, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import { useState } from "react";
import { IoIosCloseCircle, IoMdArrowRoundForward } from "react-icons/io";
import { DATE_RANGE_FORMAT } from "../../common";

interface FilterComponentProps {
  showSearchText?: boolean;
  showSearchType?: boolean;
  options?: any;
  onSearch?: (
    fromTime: moment.Moment,
    toTime: moment.Moment,
    searchText: string,
    searchType: number
  ) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  onSearch,
  options,
  showSearchText = true,
  showSearchType = true,
}) => {
  const [fromTime, setFromTime] = useState(moment().subtract(7, "days"));
  const [toTime, setToTime] = useState(moment());
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState(1); // 1: Request ID, 2: Loại File, 3: Định dạng File, 4: Client ID
  const [form] = Form.useForm();

  const onReset = () => {
    setFromTime(moment().subtract(7, "days"));
    setToTime(moment());
    setSearchText("");
    onSearch && onSearch(moment().subtract(7, "days"), moment(), "", 1);
  };

  return (
    <div className="FormFilter">
      <Form layout="vertical" form={form}>
        <Row>
          <Form.Item>
            <DatePicker.RangePicker
              value={[fromTime, toTime]}
              format={DATE_RANGE_FORMAT}
              onChange={(dates) => {
                if (dates && dates[0] && dates[1]) {
                  setFromTime(dates[0]);
                  setToTime(dates[1]);
                }
              }}
              separator={
                <div>
                  <IoMdArrowRoundForward />
                </div>
              }
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item>
            <Select
              disabled={!showSearchType}
              style={{ width: "200px" }}
              placeholder="Cột cần tìm kiếm"
              options={options}
              defaultValue={1}
              onChange={(e) => {
                setSearchType(e);
              }}
            />
          </Form.Item>

          {showSearchText && (
            <Form.Item>
              <Input
                style={{ minWidth: "250px" }}
                placeholder="Tìm kiếm"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </Form.Item>
          )}
          <Form.Item>
            <div className="ButtonContainer">
              <Button
                type="primary"
                className="SearchButton"
                onClick={() => {
                  onSearch &&
                    onSearch(fromTime, toTime, searchText, searchType);
                }}
              >
                <span>Áp dụng </span>
              </Button>
              <Button type="default" className="ResetButton" onClick={onReset}>
                <IoIosCloseCircle /> Bỏ tuỳ chọn
              </Button>
            </div>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default FilterComponent;
