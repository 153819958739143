const prefix = "report/";

const types = {
  UPDATE_FILTER: prefix + "update_filter",

  LOAD_REQUEST_DATA: prefix + "load_request_data",
  LOAD_REQUEST_DATA_SUCCESS: prefix + "load_request_data_success",
  LOAD_DOCUMENT_DATA: prefix + "load_document_data",
  LOAD_DOCUMENT_DATA_SUCCESS: prefix + "load_document_data_success",

  LOAD_EVALUATE_DATA: prefix + "load_evaluate_data",
  LOAD_EVALUATE_DATA_SUCCESS: prefix + "load_evaluate_data_success",

  LOAD_DOC_TYPES: prefix + "load_doc_types",
  LOAD_DOC_TYPES_SUCCESS: prefix + "load_doc_types_success",
};

const action = {
  updateFilter: (filter: any) => {
    return {
      type: types.UPDATE_FILTER,
      payload: { filter },
    };
  },

  loadRequestData: () => {
    return {
      type: types.LOAD_REQUEST_DATA,
    };
  },
  loadRequestDataSuccess: (request_data: any) => {
    return {
      type: types.LOAD_REQUEST_DATA_SUCCESS,
      payload: { request_data },
    };
  },
  loadDocumentData: () => {
    return {
      type: types.LOAD_DOCUMENT_DATA,
    };
  },
  loadDocumentDataSuccess: (document_data: any) => {
    return {
      type: types.LOAD_DOCUMENT_DATA_SUCCESS,
      payload: { document_data },
    };
  },
  loadEvaluateData: () => {
    return {
      type: types.LOAD_EVALUATE_DATA,
    };
  },
  loadEvaluateDataSuccess: (evaluate_data: any) => {
    return {
      type: types.LOAD_EVALUATE_DATA_SUCCESS,
      payload: { evaluate_data },
    };
  },
  loadDocTypes: () => {
    return {
      type: types.LOAD_DOC_TYPES,
    };
  },
  loadDocTypesSuccess: (doc_types: any) => {
    return {
      type: types.LOAD_DOC_TYPES_SUCCESS,
      payload: { doc_types },
    };
  },
};

const actions = {
  types,
  action,
};

export default actions;

export const ReportActions = action;
