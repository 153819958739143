import { Form, Input, Select } from 'antd';

import { ModalComponent } from '../../../components';

export const UserGroupModalComponent = (props: any) => {
  const [form] = Form.useForm();
  const { modalStatus, currentItem, clientAppList, onSave, onCancel, onChangeCurrentItem } = props;

  if (currentItem) form.setFieldsValue(currentItem);

  const _renderModal = () => {
    if (!currentItem) return;

    return (
      <div className='ClientAppModal'>
        <Form
          form={form}
          layout='vertical'
          initialValues={currentItem}
          onValuesChange={() => onChangeCurrentItem(form.getFieldsValue())}
        >
          <div className='Container' style={{ display: 'none' }}>
            <div className='FormItem'>
              <Form.Item name='id' label='Group ID'>
                <Input readOnly />
              </Form.Item>
            </div>
          </div>
          <div className='Container'>
            <div className='FormItem'>
              <Form.Item name='group_name' label='Tên Group'>
                <Input />
              </Form.Item>
            </div>
          </div>
          {currentItem.id && (
            <div className='Container'>
              <div className='FormItem'>
                <Form.Item name='clients' label='Client Apps'>
                  <Select
                    mode='multiple'
                    allowClear
                    style={{ width: '100%' }}
                    placeholder='Please select'
                    options={clientAppList}
                  />
                </Form.Item>
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  };

  return (
    <>
      <ModalComponent
        title={currentItem && currentItem.id ? 'Cập nhật User Group' : 'Tạo User Group'}
        isModalVisible={modalStatus}
        onOk={onSave}
        onCancel={onCancel}
        cancelText='Huỷ'
        okText='Lưu'
      >
        {_renderModal()}
      </ModalComponent>
    </>
  );
};
