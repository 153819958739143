// 👇️ import the pdf document
import template from '../../../assets/template.xlsx';
import { RiDownloadCloud2Line } from 'react-icons/ri';

function LoadFile() {
  return (
    <div>
      <a
        href={template}
        download='template_users.xlsx'
        target='_blank'
        rel='noreferrer'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <RiDownloadCloud2Line /> Tải template
      </a>
    </div>
  );
}

export default LoadFile;
