const prefix = 'sign/';

const types = {
  RESET: prefix + 'reset',

  UPDATE_FILTER: prefix + 'update_filter',
  UPDATE_PAGE_NUMBER: prefix + 'update_page_number',
  UPDATE_PAGE_SIZE: prefix + 'update_page_size',

  LOAD_DATA: prefix + 'load_data',
  LOAD_DATA_SUCCESS: prefix + 'load_data_success',
};

const action = {
  reset: () => {
    return {
      type: types.RESET,
    };
  },
  updateFilter: (filter: any) => {
    return {
      type: types.UPDATE_FILTER,
      payload: { filter },
    };
  },
  updatePageNumber: (page_number: any) => {
    return {
      type: types.UPDATE_PAGE_NUMBER,
      payload: { page_number },
    };
  },
  updatePageSize: (page_size: any) => {
    return {
      type: types.UPDATE_PAGE_SIZE,
      payload: { page_size },
    };
  },

  loadData: () => {
    return {
      type: types.LOAD_DATA,
    };
  },
  loadDataSuccess: (paging: any, data: any) => {
    return {
      type: types.LOAD_DATA_SUCCESS,
      payload: { paging, data },
    };
  },
};

const actions = {
  types,
  action,
};

export default actions;

export const SignActions = action;
