import './index.scss';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { BsThreeDots } from 'react-icons/bs';
import { Select } from 'antd';

export const PaginationComponent = (props: any) => {
  var { totalRecord, pageNumber, pageSize, onChangePageNumber, onChangePageSize } = props;

  var totalPage: any = 0;
  pageNumber = parseInt(pageNumber);
  if (totalRecord && pageSize) {
    totalPage = Math.ceil(totalRecord / pageSize);
  }

  var listPage = [];
  if (totalPage > 0) {
    for (var i = 1; i <= totalPage; i++) listPage.push(i);
  }

  const _renderPagingItem = () => {
    if (totalPage === 0) return null;

    let listItem = [];
    if (totalPage <= 7)
      for (let i = 1; i <= totalPage; i++) {
        listItem.push({ text: i.toString(), isActive: i === pageNumber, isMore: false });
      }
    else {
      if (pageNumber <= 4) {
        for (let i = 1; i <= 4; i++) {
          listItem.push({ text: i.toString(), isActive: i === pageNumber });
        }
        listItem.push({ text: '...', isActive: false });
        listItem.push({ text: totalPage.toString(), isActive: false });
      } else {
        if (pageNumber > totalPage - 4) {
          listItem.push({ text: '1', isActive: false });
          listItem.push({ text: '...', isActive: false });
          for (let i = 2; i <= 6; i++) {
            listItem.push({
              text: (totalPage + i - 6).toString(),
              isActive: totalPage + i - 6 === pageNumber,
            });
          }
        } else {
          listItem.push({ text: '...', isActive: false });
          for (let i = 2; i <= 6; i++) {
            listItem.push({
              text: (pageNumber + i - 4).toString(),
              isActive: pageNumber + i - 4 === pageNumber,
            });
          }
          listItem.push({ text: '...', isActive: false });
        }
      }
    }

    return (
      <div className='Paging'>
        <button
          className={pageNumber === 1 ? 'PagingItem Disabled' : 'PagingItem'}
          disabled={pageNumber === 1}
          onClick={() => handleChangePageNumber(pageNumber - 1)}
        >
          <HiOutlineChevronLeft />
        </button>
        {listItem.map((item: any, id: any) => {
          if (item.text === '...')
            return (
              <div key={`paging_${id}`} className='More'>
                <BsThreeDots />
              </div>
            );
          else
            return (
              <button
                key={`paging_${id}`}
                className={item.isActive ? 'PagingItem Active' : 'PagingItem'}
                onClick={() => handleChangePageNumber(item.text)}
              >
                {item.text}
              </button>
            );
        })}
        <button
          className={
            pageNumber === totalPage || totalPage === 0 ? 'PagingItem Disabled' : 'PagingItem'
          }
          disabled={pageNumber === totalPage || totalPage === 0}
          onClick={() => handleChangePageNumber(pageNumber + 1)}
        >
          <HiOutlineChevronRight />
        </button>
      </div>
    );
  };

  const handleChangePageSize = (pageSize: any) => {
    onChangePageSize(pageSize);
  };

  const handleChangePageNumber = (pageNumber: any) => {
    onChangePageNumber(pageNumber);
  };

  const handleSelectPageNumber = (pageNumber: any) => {
    onChangePageNumber(pageNumber);
  };

  return (
    <div id='PaginationContainer'>
      <div className='TextPaging'>Hiển thị</div>

      <Select
        allowClear
        showArrow
        value={pageSize}
        placeholder='Số dòng/trang'
        onChange={handleChangePageSize}
        style={{ width: '150px', marginRight: '5px' }}
      >
        <Select.Option value={10}>10 dòng/trang</Select.Option>
        <Select.Option value={20}>20 dòng/trang</Select.Option>
        <Select.Option value={50}>50 dòng/trang</Select.Option>
      </Select>

      {_renderPagingItem()}

      <div className='TextPaging'>Đến trang</div>
      <Select
        showArrow
        value={pageNumber}
        placeholder='Số trang'
        onChange={handleSelectPageNumber}
        style={{ width: '100px' }}
      >
        {listPage.map((page: any, index: any) => {
          return (
            <Select.Option key={`page_${index}`} value={page}>
              {page}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
