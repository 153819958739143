import { Button, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { HiArrowLeft, HiArrowRight, HiX } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";

import {
  NotificationService,
  OnlyImageUpload,
  OnlyPdfUpload,
} from "../../components";
import {
  A1Mapping,
  A2Mapping,
  B1Mapping,
  B2Mapping,
  B4Mapping,
  CLMSMapping,
  DNAMapping,
  EnvConfigs,
  GreenMapping,
  LoanMapping,
  NWFMapping,
  A3A4Mapping,
  B1PLUSMapping,
} from "../../const";
import { useActions } from "../../redux";
import {
  Canvas,
  FileComponent,
  SignComponent,
  TableComponent,
} from "./component";
import {
  DOC_TYPE_ENUM,
  MAP_SINGLE_DOC_TYPE,
  NUMBER_OF_PAGE,
} from "../../common";
import { D1Mapping } from "../../const/d1-flow-mapping";

export const OCRPage = () => {
  const [fileType, setFileType] = useState("image");
  const [isExpand, setIsExpand] = useState<any>(null);
  const [documentExpand, setDocumentExpand] = useState<any>(null);
  const [showCoord, setShowCoord] = useState<any>(null);
  const [fileIDs, setFileIDs] = useState<any>([]);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);
  const [indexInput, setIndexInput] = useState(null);
  const [imgIndex, setImgIndex] = useState(1);

  const dispatch = useDispatch();
  const actions = useActions();
  const requestState = useSelector((state: any) => state.request.request_state);
  const client_id = useSelector((state: any) => state.ocr.client_id);
  const doc_type = useSelector((state: any) => state.ocr.doc_type);
  const files = useSelector((state: any) => state.ocr.files);
  const ocr = useSelector((state: any) => state.ocr.ocr);
  const documents = useSelector((state: any) => state.ocr.documents);
  const request_id = useSelector((state: any) => state.ocr.request_id);
  const ocr_done = useSelector((state: any) => state.ocr.ocr_done);
  const update_result = useSelector((state: any) => state.ocr.update_result);
  let clientAppData = useSelector((state: any) => state.client_app.data);
  let config = useSelector((state: any) => state.ocr.config);
  let single_docs = useSelector((state: any) => state.ocr.single_docs);
  const ocr_type = useSelector((state: any) => state.ocr.ocr_type);

  useEffect(() => {
    if (!clientAppData) dispatch(actions.ClientAppActions.loadData(true));
    if (!config) dispatch(actions.OCRActions.loadConfig());
  }, [
    actions.OCRActions,
    actions.ClientAppActions,
    dispatch,
    clientAppData,
    config,
  ]);

  const handleChangeClientID = (client_id: any) => {
    dispatch(actions.OCRActions.updateClientID(client_id));
  };

  const handleChangeDocType = (doc_type: any) => {
    dispatch(actions.OCRActions.updateDocType(doc_type));
  };

  const handleChangeFileType = (file_type: any) => {
    setFileType(file_type);
    dispatch(actions.OCRActions.updateFile([]));
  };

  const handleChangeFile = (file: any, index: any) => {
    dispatch(
      actions.OCRActions.updateFile([
        ...files.slice(0, index),
        file,
        ...files.slice(index + 1),
      ])
    );
  };

  const handleReset = () => {
    dispatch(actions.OCRActions.setInitState());
    dispatch(actions.OCRActions.loadConfig());
  };

  const handleOCR = () => {
    if (!client_id) return NotificationService.error("Vui lòng chọn Client ID");
    if (!doc_type)
      return NotificationService.error("Vui lòng chọn loại tài liệu");
    dispatch(actions.OCRActions.pushFile());
  };

  const handleExpandFile = (documentID: any, fileIDs: any, file: any) => {
    if (!ocr_done) return;

    var img = document.createElement("img");
    img.setAttribute("src", file);
    setTimeout(function () {
      setOriginalHeight(img.height);
      setOriginalWidth(img.width);
    }, 0);

    setIsExpand(1);
    setDocumentExpand(documentID);
    setShowCoord(null);
    setFileIDs(fileIDs);
  };

  const handleInputUpdate = (document_id: any) => {
    if (indexInput) return;
    setIndexInput(document_id);
  };

  const handleChangeInput = (e: any, item_id: any) => {
    var newUpdateInput = update_result.map((item: any, _: any) => {
      if (item.document_id !== indexInput) return item;

      var newUpdate = {
        document_id: item.document_id,
        ocr_update: !item.ocr_update
          ? {
              [item_id]: e.target.value,
            }
          : {
              ...item.ocr_update,
              [item_id]: e.target.value,
            },
      };

      //if (e.target.value === '') delete newUpdate.ocr_update[item_id];
      return newUpdate;
    });
    dispatch(actions.OCRActions.updateResult(newUpdateInput));
  };

  const handleSaveUpdate = () => {
    var data = {
      document_id: indexInput,
      fields: update_result.find((e: any) => e.document_id === indexInput)
        .ocr_update,
    };
    dispatch(actions.OCRActions.pushResult(data));
    setIndexInput(null);
  };

  const getMapping = (ocr: any) => {
    if (ocr_type === "FLOW") {
      if (doc_type === "QF") return A1Mapping(ocr);
      if (doc_type === "INVOICE") return B1Mapping(ocr);
      if (doc_type === "KHDN") return GreenMapping(ocr);
      if (doc_type === "NWF") return NWFMapping(ocr);
      if (doc_type === "A2") return A2Mapping(ocr);
      if (doc_type === "B2") return B2Mapping(ocr);
      if (doc_type === "B4") return B4Mapping(ocr);
      if (doc_type === "CLMS") return CLMSMapping(ocr);
      if (doc_type === "DNA") return DNAMapping(ocr);
      if (doc_type === "LOANXPRESS") return LoanMapping(ocr);
      if (doc_type === "A3A4") return A3A4Mapping(ocr);
      if (doc_type === "D1") return D1Mapping(ocr);
      if (doc_type === "B1PLUS" || doc_type === "BANG_KE")
        return B1PLUSMapping(ocr);
    } else {
      if (
        [
          DOC_TYPE_ENUM.CMND,
          DOC_TYPE_ENUM.CCCD,
          DOC_TYPE_ENUM.EID,
          DOC_TYPE_ENUM.PP,
          DOC_TYPE_ENUM.BRC_DN,
          DOC_TYPE_ENUM.BRC_CN,
          DOC_TYPE_ENUM.BRC_HKD,
          DOC_TYPE_ENUM.TAX,
        ].includes(doc_type)
      ) {
        return GreenMapping(ocr);
      } else if (
        [
          DOC_TYPE_ENUM.VR,
          DOC_TYPE_ENUM.VPIC,
          DOC_TYPE_ENUM.LC,
          DOC_TYPE_ENUM.HOC,
          DOC_TYPE_ENUM.LAAC,
        ].includes(doc_type)
      ) {
        return CLMSMapping(ocr);
      } else if (
        [
          DOC_TYPE_ENUM.SC,
          DOC_TYPE_ENUM.FOREIGN_INVOICE,
          DOC_TYPE_ENUM.TTA,
        ].includes(doc_type)
      ) {
        return NWFMapping(ocr);
      } else if (
        [DOC_TYPE_ENUM.PAYMENT_ORDER, DOC_TYPE_ENUM.DEPOSIT_SLIP].includes(
          doc_type
        )
      ) {
        return DNAMapping(ocr);
      }
    }
    return [];
  };

  const myFixed = (x: any, d: any) => {
    if (!d) return x.toFixed(d); // don't go wrong if no decimal
    return x.toFixed(d).replace(/\.?0+$/, "");
  };

  if (request_id && documents && !ocr_done) {
    setTimeout(
      () => dispatch(actions.OCRActions.ocrFile()),
      EnvConfigs.ocr_result_interval_time
    );
  }

  if (!update_result && ocr_done) {
    var initUpdateResult = getMapping(ocr)?.map((item: any, _: any) => {
      let document_id = item.document_id;
      let ocr_update = null;

      return {
        document_id,
        ocr_update,
      };
    });
    dispatch(actions.OCRActions.updateResult(initUpdateResult));
  }

  //clientAppData = clientAppData && [clientAppData[0]];
  if (clientAppData && clientAppData.length === 1 && !client_id)
    handleChangeClientID(clientAppData[0].client_id);

  const listFlow: any[] = [];
  if (config && typeof config === "object") {
    Object.keys(config).forEach((key: any, _: any) => {
      listFlow.push({
        label: config[key] ?? key,
        value: key,
      });
    });
    listFlow.sort((a, b) => a.value.localeCompare(b.value));
  }
  const listSingle: any[] = [];
  if (single_docs && typeof single_docs === "object") {
    Object.keys(single_docs).forEach((key: any, _: any) => {
      listSingle.push({
        label: MAP_SINGLE_DOC_TYPE[key] ?? key,
        value: key,
      });
    });
    listSingle.sort((a, b) => a.value.localeCompare(b.value));
  }

  return (
    <Spin
      spinning={requestState === 0}
      size="large"
      style={{ position: "static" }}
    >
      <div className="AppPage">
        {(!documents || documents.length === 0) && (
          <>
            <div className="PageTitle">Mẫu {doc_type}</div>
            <div className="PageContainer">
              <div className="Page__Left" style={{ width: "80%" }}>
                <div className="MainLeft">
                  <div className="RequirementContainer">
                    <div className="RequirementItem">
                      <div className="Bullet"></div>
                      <div className="Text">
                        Kích thước file dưới{" "}
                        {fileType === "image"
                          ? myFixed(
                              EnvConfigs.img_size_upper_bound / 1000000,
                              1
                            )
                          : myFixed(
                              EnvConfigs.pdf_size_upper_bound / 1000000,
                              1
                            )}{" "}
                        MB
                      </div>
                    </div>
                  </div>

                  <div
                    className="FilterContainer"
                    style={{ display: "flex", gap: 5 }}
                  >
                    {clientAppData && clientAppData.length > 0 && (
                      <Select
                        showArrow
                        value={client_id}
                        placeholder="ClientID"
                        onChange={handleChangeClientID}
                      >
                        {clientAppData &&
                          clientAppData.map((item: any, index: any) => {
                            if (item.status !== "DELETED")
                              return (
                                <Select.Option
                                  key={`client_id_${index}`}
                                  value={item.client_id}
                                >
                                  {item.client_alias}
                                </Select.Option>
                              );
                            else return <></>;
                          })}
                      </Select>
                    )}
                    <Select
                      showArrow
                      placeholder="Loại xử lý"
                      value={ocr_type}
                      options={[
                        { label: "Luồng", value: "FLOW" },
                        { label: "Tài liệu", value: "SINGLE" },
                      ]}
                      onChange={(value: "FLOW" | "SINGLE") => {
                        dispatch(actions.OCRActions.updateOcrType(value));
                        handleChangeDocType(null);
                      }}
                    />
                    {ocr_type === "FLOW" ? (
                      <Select
                        showArrow
                        value={doc_type}
                        placeholder={
                          ocr_type === "FLOW"
                            ? "Luồng nghiệp vụ"
                            : "Loại tài liệu"
                        }
                        options={listFlow}
                        onChange={handleChangeDocType}
                      />
                    ) : (
                      <Select
                        style={{ minWidth: 200 }}
                        showArrow
                        value={doc_type}
                        placeholder={
                          ocr_type === "FLOW"
                            ? "Luồng nghiệp vụ"
                            : "Loại tài liệu"
                        }
                        options={listSingle}
                        onChange={handleChangeDocType}
                      />
                    )}

                    <Select
                      showArrow
                      placeholder="Định dạng file"
                      value={fileType}
                      onChange={handleChangeFileType}
                    >
                      <Select.Option value="image">Ảnh</Select.Option>
                      <Select.Option value="pdf">PDF</Select.Option>
                    </Select>
                  </div>
                  <div className="UploadText">Chọn Mẫu {doc_type}</div>
                  {fileType === "image" ? (
                    <div className="UploadContainer">
                      {[...Array(EnvConfigs.image_file_number)].map((_, i) => (
                        <div key={`img_${i}`} className="UploadItem">
                          <OnlyImageUpload
                            file={files && files[i] ? files[i] : null}
                            onChangeFile={(file: any) =>
                              handleChangeFile(file, i)
                            }
                            showExpand={false}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="UploadContainer">
                      <div className="UploadItem Full">
                        <OnlyPdfUpload
                          file={files && files[0] ? files[0] : null}
                          onChangeFile={(file: any) =>
                            handleChangeFile(file, 0)
                          }
                          showExpand={false}
                        />
                      </div>
                    </div>
                  )}

                  <div className="ButtonContainer">
                    {files &&
                      files.length > 0 &&
                      (!documents || documents.length === 0) && (
                        <Button className="ButtonApp" onClick={handleOCR}>
                          Trích xuất thông tin
                        </Button>
                      )}
                    {(!files || files.length === 0) && (
                      <Button className="ButtonApp Disabled" disabled>
                        Trích xuất thông tin
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </>
        )}

        {documents && documents.length > 0 && (
          <div className="PageContainer">
            {!isExpand ? (
              <div className="Page__Left">
                <div className="PageTitle">Mẫu {doc_type}</div>
                <div className="MainLeft">
                  <div className="TopFileContainer">
                    <FileComponent
                      type={2}
                      documentID={documents[0].document_id}
                      fileIDs={documents[0].file_id && documents[0].file_id}
                      clientID={client_id}
                      fileIndex={0}
                      requestID={request_id}
                      handleClick={handleExpandFile}
                      allowClick={ocr_done}
                    />
                  </div>

                  <div className="ButtonContainer">
                    {files &&
                      files.length > 0 &&
                      documents &&
                      documents.length > 0 && (
                        <Button className="ButtonApp" onClick={handleReset}>
                          Tải tài liệu khác
                        </Button>
                      )}
                  </div>
                  <div className="BottomFileContainer">
                    <div className="BottomFileContainer">
                      {documents.map((document: any, id: any) => {
                        if (id > 0) {
                          if (
                            imgIndex == 1 &&
                            id >= NUMBER_OF_PAGE * imgIndex + 1
                          )
                            return <></>;

                          if (
                            imgIndex ==
                              Math.floor(documents.length / NUMBER_OF_PAGE) &&
                            id <= NUMBER_OF_PAGE * (imgIndex - 1)
                          ) {
                            return <></>;
                          }

                          if (
                            imgIndex > 1 &&
                            imgIndex <
                              Math.floor(documents.length / NUMBER_OF_PAGE) &&
                            (id <= NUMBER_OF_PAGE * (imgIndex - 1) ||
                              id > NUMBER_OF_PAGE * imgIndex)
                          ) {
                            return <></>;
                          }

                          return (
                            <div key={`doc_${id}`} className="OCRListPage">
                              <FileComponent
                                type={2}
                                documentID={document.document_id}
                                fileIDs={document.file_id && document.file_id}
                                clientID={client_id}
                                fileIndex={0}
                                requestID={request_id}
                                handleClick={handleExpandFile}
                                allowClick={ocr_done}
                              />
                            </div>
                          );
                        }
                        return <></>;
                      })}
                    </div>
                    {documents.length > NUMBER_OF_PAGE - 1 && (
                      <div className="ControllerContainer OCRPaging">
                        <HiArrowLeft
                          onClick={() => {
                            if (imgIndex > 1) setImgIndex(imgIndex - 1);
                          }}
                        />

                        <span>
                          {imgIndex}/
                          {Math.floor(documents.length / NUMBER_OF_PAGE)}
                        </span>

                        <HiArrowRight
                          onClick={() => {
                            if (
                              imgIndex <
                              Math.floor(documents.length / NUMBER_OF_PAGE)
                            )
                              setImgIndex(imgIndex + 1);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="Page__Left">
                <div className="ExpandContainer">
                  <div className="ExpandController">
                    <div className="ControllerContainer">
                      <HiArrowLeft
                        onClick={() => {
                          if (isExpand > 1) setIsExpand(isExpand - 1);
                        }}
                      />

                      <span>
                        {isExpand}/{fileIDs.length}
                      </span>

                      <HiArrowRight
                        onClick={() => {
                          if (isExpand < fileIDs.length)
                            setIsExpand(isExpand + 1);
                        }}
                      />
                    </div>
                    <div
                      className="ControllerContainer"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsExpand(null);
                        setDocumentExpand(null);
                        setFileIDs([]);
                        setShowCoord(null);
                      }}
                    >
                      <span>Đóng</span> <HiX />
                    </div>
                  </div>
                  <div className="CanvasContainer">
                    <div className="PdfCanvas">
                      <FileComponent
                        type={2}
                        documentID={documentExpand}
                        fileIDs={fileIDs}
                        clientID={client_id}
                        fileIndex={isExpand - 1}
                        requestID={request_id}
                        handleClick={handleExpandFile}
                        allowClick={false}
                      />

                      <Canvas
                        bb={showCoord}
                        originalWidth={originalWidth}
                        originalHeight={originalHeight}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!ocr_done && (
              <div className="Page__Right">
                <Spin spinning={true} size="large"></Spin>
              </div>
            )}
            {ocr_done && getMapping(ocr) && getMapping(ocr).length > 0 && (
              <div className="Page__Right">
                {getMapping(ocr)?.map((doc: any, index: any) => {
                  if (documentExpand && doc.document_id !== documentExpand)
                    return <></>;
                  var table_data = doc.ocrData;
                  var updateOcr =
                    update_result &&
                    update_result.find(
                      (e: any) => e.document_id === doc.document_id
                    ) &&
                    update_result.find(
                      (e: any) => e.document_id === doc.document_id
                    ).ocr_update;

                  return (
                    <>
                      <div className="ResultTitleContainer">
                        <div className="ResultTitle">Kết quả trích xuất</div>
                        {doc.document_id === indexInput && (
                          <Button
                            className="ButtonApp"
                            onClick={handleSaveUpdate}
                          >
                            Lưu
                          </Button>
                        )}
                      </div>

                      <table>
                        <thead>
                          <tr>
                            <th>Trường thông tin</th>
                            <th>Kết quả OCR</th>
                            <th>Cập nhật OCR</th>
                            <th>Độ tin cậy (%)</th>
                          </tr>
                        </thead>
                        <tbody className="ContractTable">
                          {table_data.map((item: any, id: any) => {
                            return (
                              <tr
                                key={`row_${id}`}
                                className={`${
                                  item.coords &&
                                  documentExpand &&
                                  item.image_index === isExpand - 1
                                    ? "RowClick"
                                    : ""
                                } ${item?.is_bold ? "text-bold" : ""}`}
                                onMouseOver={() => {
                                  if (item.image_index === isExpand - 1)
                                    setShowCoord(item.coords);
                                }}
                                onMouseOut={() => {
                                  setShowCoord(null);
                                }}
                              >
                                <td className="First">{item.attribute}</td>
                                <td
                                  className={
                                    item.value ? "Middle" : "Middle Null"
                                  }
                                >
                                  {item?.hasImage ? (
                                    <img
                                      src={`data:image/jpeg;base64,${item.value}`}
                                      alt={`file`}
                                      id={`document_image_${item.image_index}`}
                                      style={{ cursor: "default" }}
                                    />
                                  ) : item.value ? (
                                    item.value
                                  ) : (
                                    "--"
                                  )}
                                </td>
                                {item.attribute === "Mã hồ sơ" || !item.id ? (
                                  <td
                                    className={
                                      item.value ? "Middle" : "Middle Null"
                                    }
                                  ></td>
                                ) : (
                                  <td
                                    className={
                                      item.value ? "Middle" : "Middle Null"
                                    }
                                    style={{
                                      cursor: !indexInput
                                        ? "pointer"
                                        : "default",
                                    }}
                                    onClick={() =>
                                      handleInputUpdate(doc.document_id)
                                    }
                                  >
                                    {doc.document_id === indexInput && (
                                      <input
                                        onChange={(e) =>
                                          handleChangeInput(e, item.id)
                                        }
                                        value={updateOcr && updateOcr[item.id]}
                                      />
                                    )}
                                    {doc.document_id !== indexInput && (
                                      <div className="Editable">
                                        {updateOcr && updateOcr[item.id]}
                                        <FaRegEdit />
                                      </div>
                                    )}
                                  </td>
                                )}
                                <td
                                  className="Last"
                                  style={{
                                    color:
                                      item.score && parseInt(item.score) < 50
                                        ? "red"
                                        : "initial",
                                  }}
                                >
                                  {item.score ? item.score : "--%"}
                                </td>
                              </tr>
                            );
                          })}
                          {(doc.type === "QF" ||
                            doc.type === "FOREIGN_INVOICE" ||
                            doc.type === "SC") && (
                            <SignComponent
                              documentID={doc.document_id}
                              requestID={request_id}
                              clientID={client_id}
                            />
                          )}
                          {(doc.type === "FOREIGN_INVOICE" ||
                            doc.type === "SC") && (
                            <TableComponent
                              documentID={doc.document_id}
                              requestID={request_id}
                              clientID={client_id}
                            />
                          )}
                        </tbody>
                      </table>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </Spin>
  );
};
