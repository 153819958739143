const IDMap = new Map(
  Object.entries({
    id_number: 'Số giấy tờ tuỳ thân',
    name: 'Họ và tên',
    doi: 'Ngày cấp',
  })
);

const QFPrimaryMap = new Map(
  Object.entries({
    customer_name: 'Tên khách hàng',
    person_code: 'Mã số giấy tờ tuỳ thân',
    company_name: 'Tên công ty',
    brc_code: 'Mã công ty',
    account_number: 'Tài khoản ngân hàng',
    tax_code: 'Mã số thuế',
  })
);

const QFSecondaryMap = new Map(
  Object.entries({
    customer_name: 'Tên khách hàng phụ',
    person_code: 'Mã số giấy tờ tuỳ thân khách hàng phụ',
    company_name: 'Tên công ty khách hàng phụ',
    brc_code: 'Mã công ty khách hàng phụ',
    account_number: 'Tài khoản ngân hàng khách hàng phụ',
    tax_code: 'Mã số thuế khách hàng phụ',
  })
);

const QFDocMap = new Map(
  Object.entries({
    doi: 'Ngày tài liệu',
    phone: 'Số điện thoại',
    qf_date: 'Ngày QF',
    account_number: 'Số tài khoản',
  })
);

const getMap = (type: any) => {
  if (type === 'CMND' || type === 'CCCD' || type === 'EID' || type === 'PP') return IDMap;
  if (type === 'primary_customer_info') return QFPrimaryMap;
  if (type === 'secondary_customer_info') return QFSecondaryMap;
  if (type === 'qf_info') return QFDocMap;
};

export const A1ver2Mapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.document_code,
      score: isNaN(document.document_code_score)
        ? document.document_code_score
        : document.document_code_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.document_code_score) * 100, 99).toFixed(0),
    });

    if (
      document.document_type === 'CMND' ||
      document.document_type === 'CCCD' ||
      document.document_type === 'EID' ||
      document.document_type === 'PP'
    ) {
      if (document.ocr_data) {
        const doc_type =
          document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
            ? Object.keys(document.ocr_data)[0]
            : null;
        if (doc_type) {
          const ocr_data = document.ocr_data[`${doc_type}`];

          if (ocr_data && ocr_data instanceof Object && Object.keys(ocr_data).length > 0) {
            Array.from(IDMap.keys()).forEach((item: any, _: any) => {
              if (ocr_data[item]) {
                let field = Array.isArray(ocr_data[item]) ? ocr_data[item][0] : ocr_data[item];

                ocrData.push({
                  attribute: IDMap.get(item),
                  id: field.id,
                  coords: field.coordinates,
                  value: field.value,
                  image_index: field.image_index,
                  score: isNaN(field.score)
                    ? field.score
                    : field.score === -1
                    ? 0
                    : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                });
              } else
                ocrData.push({
                  attribute: IDMap.get(item),
                  id: null,
                  coords: null,
                  image_index: null,
                  value: null,
                  certain: null,
                });
            });
          }
        }
      }
    }

    if (document.document_type === 'QF') {
      if (document.ocr_data) {
        if (document.ocr_data.primary_customer_info) {
          const ocr_data = document.ocr_data.primary_customer_info;
          if (ocr_data && ocr_data instanceof Object && Object.keys(ocr_data).length > 0) {
            Array.from(QFPrimaryMap.keys()).forEach((item: any, _: any) => {
              if (ocr_data[item]) {
                let field = Array.isArray(ocr_data[item]) ? ocr_data[item][0] : ocr_data[item];
                ocrData.push({
                  attribute: QFPrimaryMap.get(item),
                  coords: field.coordinates,
                  value: field.value,
                  id: field.id,
                  image_index: field.image_index,
                  score: isNaN(field.score)
                    ? field.score
                    : field.score === -1
                    ? 0
                    : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                });
              } else
                ocrData.push({
                  attribute: QFPrimaryMap.get(item),
                  coords: null,
                  id: null,
                  image_index: null,
                  value: null,
                  certain: null,
                });
            });
          }
        }
        if (document.ocr_data.secondary_customer_info) {
          const ocr_data = document.ocr_data.secondary_customer_info;
          if (ocr_data && ocr_data instanceof Object && Object.keys(ocr_data).length > 0) {
            Array.from(QFSecondaryMap.keys()).forEach((item: any, _: any) => {
              if (ocr_data[item]) {
                let field = Array.isArray(ocr_data[item]) ? ocr_data[item][0] : ocr_data[item];
                ocrData.push({
                  attribute: QFSecondaryMap.get(item),
                  coords: field.coordinates,
                  id: field.id,
                  value: field.value,
                  image_index: field.image_index,
                  score: isNaN(field.score)
                    ? field.score
                    : field.score === -1
                    ? 0
                    : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                });
              } else
                ocrData.push({
                  attribute: QFSecondaryMap.get(item),
                  coords: null,
                  id: null,
                  image_index: null,
                  value: null,
                  certain: null,
                });
            });
          }
        }
        if (document.ocr_data.qf_info) {
          const ocr_data = document.ocr_data.qf_info;
          if (ocr_data && ocr_data instanceof Object && Object.keys(ocr_data).length > 0) {
            Array.from(QFDocMap.keys()).forEach((item: any, _: any) => {
              if (ocr_data[item]) {
                let field = Array.isArray(ocr_data[item]) ? ocr_data[item][0] : ocr_data[item];
                ocrData.push({
                  attribute: QFDocMap.get(item),
                  coords: field.coordinates,
                  value: field.value,
                  image_index: field.image_index,
                  id: field.id,
                  score: isNaN(field.score)
                    ? field.score
                    : field.score === -1
                    ? 0
                    : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                });
              } else
                ocrData.push({
                  attribute: QFDocMap.get(item),
                  coords: null,
                  id: null,
                  image_index: null,
                  value: null,
                  certain: null,
                });
            });
          }
        }
      }
    }

    return {
      type: document.document_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};

export const A1Mapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.document_code,
      score: isNaN(document.document_code_score)
        ? document.document_code_score
        : document.document_code_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.document_code_score) * 100, 99).toFixed(0),
    });

    if (document.document_type !== 'QF') {
      if (document.ocr_data) {
        const doc_type =
          document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
            ? Object.keys(document.ocr_data)[0]
            : null;
        if (doc_type) {
          const ocr_data = document.ocr_data[`${doc_type}`]; // List
          const data_map = getMap(document.document_type);
          if (ocr_data && ocr_data.length > 0 && data_map) {
            ocr_data.forEach((ocr_item: any, index: any) => {
              if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
                Array.from(data_map.keys()).forEach((item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      id: field.id,
                      coords: field.coordinates,
                      value: field.value,
                      image_index: field.image_index,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      id: null,
                      coords: null,
                      image_index: null,
                      value: null,
                      certain: null,
                    });
                });
              }
            });
          }
        }
      }
    }

    if (document.document_type === 'QF') {
      if (document.ocr_data && typeof document.ocr_data === 'object') {
        Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
          const ocr_data = document.ocr_data[`${data_field}`];
          const data_map = getMap(data_field);
          if (ocr_data && ocr_data.length > 0 && data_map) {
            ocr_data.forEach((ocr_item: any, index: any) => {
              if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
                Array.from(data_map.keys()).forEach((item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      coords: field.coordinates,
                      value: field.value,
                      id: field.id,
                      image_index: field.image_index,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      coords: null,
                      id: null,
                      image_index: null,
                      value: null,
                      certain: null,
                    });
                });
              }
            });
          }
        });
      }
    }

    return {
      type: document.document_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};

export const A1DetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.doc_code,
      score: isNaN(document.doc_score)
        ? document.doc_score
        : document.doc_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
    });

    if (document.doc_type !== 'QF') {
      if (document.ocr_data) {
        const doc_type =
          document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
            ? Object.keys(document.ocr_data)[0]
            : null;
        if (doc_type) {
          const ocr_data = document.ocr_data[`${doc_type}`]; // List
          const data_map = getMap(document.doc_type);
          const ocr_update = document.ocr_update ? document.ocr_update : null;
          if (ocr_data && ocr_data.length > 0 && data_map) {
            ocr_data.forEach((ocr_item: any, index: any) => {
              if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
                Array.from(data_map.keys()).forEach((item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      id: field.id,
                      coords: field.coordinates,
                      value: field.value,
                      value_update: ocr_update && ocr_update[field.id],
                      image_index: field.image_index,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      id: null,
                      coords: null,
                      image_index: null,
                      value: null,
                      value_update: null,
                      certain: null,
                    });
                });
              }
            });
          }
        }
      }
    }

    if (document.doc_type === 'QF') {
      if (document.ocr_data && typeof document.ocr_data === 'object') {
        const ocr_update = document.ocr_update ? document.ocr_update : null;
        Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
          const ocr_data = document.ocr_data[`${data_field}`];
          const data_map = getMap(data_field);

          if (ocr_data && ocr_data.length > 0 && data_map) {
            ocr_data.forEach((ocr_item: any, index: any) => {
              if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
                Array.from(data_map.keys()).forEach((item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      coords: field.coordinates,
                      value: field.value,
                      value_update: ocr_update && ocr_update[field.id],
                      id: field.id,
                      image_index: field.image_index,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      coords: null,
                      id: null,
                      image_index: null,
                      value: null,
                      value_update: null,
                      certain: null,
                    });
                });
              }
            });
          }
        });
      }
    }

    return {
      type: document.doc_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};
