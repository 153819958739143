import actions from './actions';

export const initState = {
  request_id: '',
  request_state: -1, // -1 fail-idle, 0 pending, 1 success
  client_id: '',
  token: '',
};

const RequestReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case actions.types.SET_INIT_STATE:
      return initState;

    case actions.types.LOAD_REQUEST_ID_SUCCESS:
      return {
        ...state,
        ...{
          request_id: action.payload.request_id,
        },
      };
    case actions.types.UPDATE_REQUEST_STATE:
      return {
        ...state,
        ...{
          request_state: action.payload.requestState,
        },
      };

    default:
      return state;
  }
};

export default RequestReducer;
