export const DailyTabComponent = (props: any) => {
  const { currentTab, onChangeTab } = props;

  return (
    <div className='DailyTab'>
      <div
        className={currentTab === 'PENDING' ? 'TabItem Active' : 'TabItem'}
        onClick={() => onChangeTab('PENDING')}
      >
        <div>Yêu cầu đang chờ xử lý</div>
      </div>
      <div
        className={currentTab === 'PROCESSING' ? 'TabItem Active' : 'TabItem'}
        onClick={() => onChangeTab('PROCESSING')}
      >
        <div>Yêu cầu đang xử lý</div>
      </div>
      <div
        className={currentTab === 'SUCCESS' ? 'TabItem Active' : 'TabItem'}
        onClick={() => onChangeTab('SUCCESS')}
      >
        <div>Yêu cầu xử lý thành công</div>
      </div>
      <div
        className={currentTab === 'FAILED' ? 'TabItem Active' : 'TabItem'}
        onClick={() => onChangeTab('FAILED')}
      >
        <div>Yêu cầu xử lý thất bại</div>
      </div>
    </div>
  );
};
