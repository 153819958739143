import React from "react";
import { NotificationService } from "..";
import { LuCopyPlus } from "react-icons/lu";

interface ButtonCopyProps {
  value: string;
}
const ButtonCopy: React.FC<ButtonCopyProps> = ({ value }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
      }}
      onClick={() => {
        navigator.clipboard.writeText(value);
        NotificationService.success("Copied");
      }}
    >
      <LuCopyPlus />
    </div>
  );
};

export default ButtonCopy;
