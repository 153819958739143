const prefix = 'daily/';

const types = {
  RESET: prefix + 'reset',

  UPDATE_FILTER: prefix + 'update_filter',
  UPDATE_PAGE_NUMBER: prefix + 'update_page_number',
  UPDATE_PAGE_SIZE: prefix + 'update_page_size',

  LOAD_DASHBOARD: prefix + 'load_dashboard',
  LOAD_DASHBOARD_SUCCESS: prefix + 'load_dashboard_success',

  LOAD_DATA: prefix + 'load_data',
  LOAD_DATA_SUCCESS: prefix + 'load_data_success',

  UPDATE_PRIORITY: prefix + 'update_priority',
  RETRY_REQUEST: prefix + 'retry_request',

  UPDATE_CURRENT_REQUEST: prefix + 'update_current_request',
  LOAD_DETAIL_DATA: prefix + 'load_detail_data',
  LOAD_DETAIL_DATA_SUCCESS: prefix + 'load_detail_data_success',
};

const action = {
  reset: () => {
    return {
      type: types.RESET,
    };
  },
  updateFilter: (filter: any) => {
    return {
      type: types.UPDATE_FILTER,
      payload: { filter },
    };
  },
  updatePageNumber: (page_number: any) => {
    return {
      type: types.UPDATE_PAGE_NUMBER,
      payload: { page_number },
    };
  },
  updatePageSize: (page_size: any) => {
    return {
      type: types.UPDATE_PAGE_SIZE,
      payload: { page_size },
    };
  },

  loadDashboard: () => {
    return {
      type: types.LOAD_DASHBOARD,
    };
  },
  loadDashboardSuccess: (data: any) => {
    return {
      type: types.LOAD_DASHBOARD_SUCCESS,
      payload: { data },
    };
  },

  loadData: () => {
    return {
      type: types.LOAD_DATA,
    };
  },
  loadDataSuccess: (total: any, data: any) => {
    return {
      type: types.LOAD_DATA_SUCCESS,
      payload: { total, data },
    };
  },

  updatePriority: (data: any) => {
    return {
      type: types.UPDATE_PRIORITY,
      payload: { data },
    };
  },
  retryRequest: (request_id: any) => {
    return {
      type: types.RETRY_REQUEST,
      payload: { request_id },
    };
  },

  updateCurrentRequest: (request: any) => {
    return {
      type: types.UPDATE_CURRENT_REQUEST,
      payload: { request },
    };
  },
  loadDetailData: () => {
    return {
      type: types.LOAD_DETAIL_DATA,
    };
  },
  loadDetailDataSuccess: (data: any) => {
    return {
      type: types.LOAD_DETAIL_DATA_SUCCESS,
      payload: { data },
    };
  },
};

const actions = {
  types,
  action,
};

export default actions;

export const DailyActions = action;
