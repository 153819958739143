import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationService } from "../../components";
import { APIServices } from "../../utils";
import actions from "./actions";
import reqActions from "../request/actions";
import authActions from "../auth/actions";

function* saga_LoadRequestData() {
  try {
    let _currentFilter: Promise<any> = yield select(
      (state: any) => state.report.current_filter
    );
    let currentFilter = _currentFilter;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Report.GetRequestMetric(
      currentFilter
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(actions.action.loadRequestDataSuccess(data));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải dữ liệu báo cáo Request thất bại");
    }
  } catch (ex: any) {
    console.log("[OCR] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải dữ liệu báo cáo Request thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_LoadDocumentData() {
  try {
    let _currentFilter: Promise<any> = yield select(
      (state: any) => state.report.current_filter
    );
    let currentFilter = _currentFilter;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Report.GetDocumentMetric(
      currentFilter
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      // let { records } = data;

      yield put(actions.action.loadDocumentDataSuccess(data));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải dữ liệu báo cáo Document thất bại");
    }
  } catch (ex: any) {
    console.log("[OCR] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải dữ liệu báo cáo Document thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}
function* saga_LoadEvaluateData() {
  try {
    let _currentFilter: Promise<any> = yield select(
      (state: any) => state.report.current_filter
    );
    let currentFilter = _currentFilter;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Report.GetEvaluateMetric(
      currentFilter
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(actions.action.loadEvaluateDataSuccess(data));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải dữ liệu tỉ lệ xử lý giấy tờ thất bại");
    }
  } catch (ex: any) {
    console.log("[OCR] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải dữ liệu tỉ lệ xử lý giấy tờ thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_LoadDoctypeData() {
  try {
    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Report.GetDocTypes();
    let response: any = _response;
    console.log("response", response);
    let { data } = response;

    if (data) {
      yield put(actions.action.loadDocTypesSuccess(data));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải dữ liệu loại giấy tờ thất bại");
    }
  } catch (ex: any) {
    console.log("[OCR] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải dữ liệu loại giấy tờ thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_DOCUMENT_DATA, saga_LoadDocumentData);
  yield takeEvery(actions.types.LOAD_REQUEST_DATA, saga_LoadRequestData);
  yield takeEvery(actions.types.LOAD_EVALUATE_DATA, saga_LoadEvaluateData);
  yield takeEvery(actions.types.LOAD_DOC_TYPES, saga_LoadDoctypeData);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
