const envSettings = window.global as any;

export const ServerConfigs = {
  server:
    process.env.NODE_ENV === 'development' ? 'https://ocr-staging.vng.ai/' : envSettings.BASE_URL,
  acb_server:
    process.env.NODE_ENV === 'development'
      ? 'https://ocr-staging.vng.ai/'
      : envSettings.ACB_BASE_URL,
  client_id:
    process.env.NODE_ENV === 'development'
      ? '143918e1232989fea4faec54edbad19a'
      : envSettings.CLIENT_ID,
  client_secret:
    process.env.NODE_ENV === 'development'
      ? 'r2vXfKWud6cfm8yERdYWr3m1kXPaft19e+WSLMHKGIA='
      : envSettings.CLIENT_SECRET,

  //   {
  //     "email": "admin",
  //     "password": "TrueidP@ssword",
  //     "role": "OPERATOR_OCR"
  //    }
  //   {
  //     "email": "phongtt.cnm@gmail.com",
  //     "password": "abcd@test",
  //     "role": "BUSINESS_OCR"
  //    }
};

export const AuthConfigs = {
  login_method: process.env.NODE_ENV === 'development' ? 'both' : envSettings.LOGIN_METHOD,
  tenant_id:
    process.env.NODE_ENV === 'development'
      ? '264faa5c-2f25-4214-b52b-a0ba16e61f32'
      : envSettings.MFA_TENANT_ID,
  client_id:
    process.env.NODE_ENV === 'development'
      ? '4f6b984a-14b4-46f6-a07f-a4f5a05b0ab8'
      : envSettings.MFA_CLIENT_ID,
  redirect_uri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/login'
      : envSettings.MFA_REDIRECT_URI,
  response_type: process.env.NODE_ENV === 'development' ? 'code' : envSettings.MFA_RESPONSE_TYPE,
  response_mode: process.env.NODE_ENV === 'development' ? 'query' : envSettings.MFA_RESPONSE_MODE,
  scope: ['openid', 'offline_access'].join(' '),
  state: JSON.stringify({ provider: 'Microsoft' }),
};

export const EnvConfigs = {
  img_size_upper_bound:
    process.env.NODE_ENV === 'development' ? 10000000 : envSettings.IMAGE_SIZE_UPPER_BOUND,
  img_size_lower_bound:
    process.env.NODE_ENV === 'development' ? 10000 : envSettings.IMAGE_SIZE_LOWER_BOUND,
  pdf_size_upper_bound:
    process.env.NODE_ENV === 'development' ? 10000000 : envSettings.PDF_SIZE_UPPER_BOUND,
  pdf_size_lower_bound:
    process.env.NODE_ENV === 'development' ? 10000 : envSettings.PDF_SIZE_LOWER_BOUND,
  image_file_number: process.env.NODE_ENV === 'development' ? 8 : envSettings.IMAGE_FILE_NUMBER,
  ocr_result_interval_time: envSettings.OCR_RESULT_INTERVAL_TIME ?? 5000,
};
