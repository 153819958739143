const IDMap = new Map(
  Object.entries({
    id_number: "Số CMND/CCCD",
    name: "Họ và tên",
    name_surname: "Họ và tên (Họ)",
    name_middle_name: "Họ và tên (Chữ lót)",
    name_primary_name: "Họ và tên (Tên)",
    dob: "Ngày sinh",
    gender: "Giới tính",
    nation: "Quốc tịch",
    address: "Nơi thường trú",
    address_number: "Nơi thường trú (Số nhà)",
    address_street: "Nơi thường trú (Đường)",
    address_ward: "Nơi thường trú (Phường/Xã)",
    address_ward_code: "Mã nơi thường trú (Phường/Xã)",
    address_district: "Nơi thường trú (Quận/Huyện)",
    address_district_code: "Mã nơi thường trú (Quận/Huyện)",
    address_province: "Nơi thường trú (Tỉnh/Thành phố)",
    address_province_code: "Mã nơi thường trú (Tỉnh/Thành phố)",
    address_country: "Nơi thường trú (Quốc gia)",
    doi: "Ngày cấp",
    poi: "Nơi cấp",
  })
);

const PassportMap = new Map(
  Object.entries({
    id_number: "Số CMND/CCCD",
    passport_number: "Số hộ chiếu",
    name: "Họ và tên",
    name_surname: "Họ và tên (Họ)",
    name_middle_name: "Họ và tên (Chữ lót)",
    name_primary_name: "Họ và tên (Tên)",
    dob: "Ngày sinh",
    gender: "Giới tính",
    nation: "Quốc tịch",
    doi: "Ngày cấp",
    poi: "Nơi cấp giấy tờ",
    doe: "Ngày hết hạn",
    id_type: "Loại giấy tờ",
  })
);

const TaxMap = new Map(
  Object.entries({
    tax_code: "Mã số người nộp thuế",
    tax_doi: "Ngày cấp mã số thuế",
    name: "Tên người nộp thuế",
    name_surname: "Tên người nộp thuế (Họ)",
    name_middle_name: "Tên người nộp thuế (Chữ lót)",
    name_primary_name: "Tên người nộp thuế (Tên)",
    id_number: "Số giấy tờ tùy thân",
    id_poi: "Nơi cấp giấy tờ tùy thân",
    brc_code: "Số đăng ký kinh doanh",
    brc_doi: "Ngày cấp số đăng ký Hộ kinh doanh",
    tax_agency: "Cơ quan thuế quản lý trực tiếp",
  })
);

const BRC_DN_Map = new Map(
  Object.entries({
    brc_poi: "Nơi cấp GCN ĐKHKD",
    brc_id: "Mã số doanh nghiệp",
    brc_first_doi: "Ngày đăng ký lần đầu",
    brc_last_doi: "Ngày đăng ký thay đổi",
    company_name: "Tên công ty viết bằng tiếng việt",
    company_foreign_name: "Tên công ty viết bằng tiếng nước ngoài",
    company_short_name: "Tên công ty viết tắt",
    company_address: "Địa chỉ trụ sở chính",
    company_address_number: "Địa chỉ trụ sở chính - Số nhà",
    company_address_street: "Địa chỉ trụ sở chính - Đường",
    company_address_ward: "Địa chỉ trụ sở chính (Phường/Xã)",
    company_address_ward_code: "Mã địa chỉ trụ sở chính (Phường/Xã)",
    company_address_district: "Địa chỉ trụ sở chính (Quận/Huyện)",
    company_address_district_code: "Mã địa chỉ trụ sở chính (Quận/Huyện)",
    company_address_province: "Địa chỉ trụ sở chính (Tỉnh/Thành phố)",
    company_address_province_code: "Mã địa chỉ trụ sở chính (Tỉnh/Thành phố)",
    company_address_country: "Địa chỉ trụ sở chính (Quốc gia)",
    company_type: "Loại hình doanh nghiệp",
    company_phone: "Điện thoại",
    company_fax: "Fax",
    company_email: "Email",
    company_website: "Website",
    capital: "Vốn điều lệ",
  })
);

const BRC_DN_Representer_Map = new Map(
  Object.entries({
    representer_name: "Họ tên người đại diện",
    representer_name_surname: "Họ tên người đại diện (Họ)",
    representer_name_middle_name: "Họ tên người đại diện (Chữ lót)",
    representer_name_primary_name: "Họ tên người đại diện (Tên)",
    representer_gender: "Giới tính",
    representer_dob: "Ngày sinh",
    representer_ethnic: "Dân tộc",
    representer_nation: "Quốc tịch",
    representer_id_type: "Loại giấy tờ tùy thân",
    representer_id_number: "Số giấy tờ pháp lý",
    representer_doi: "Ngày cấp",
    representer_poi: "Nơi cấp",
  })
);

const BRC_DN_Owner_Map = new Map(
  Object.entries({
    owner_name: "Họ tên chủ sở hữu",
    owner_name_surname: "Họ tên chủ sở hữu (Họ)",
    owner_name_middle_name: "Họ tên chủ sở hữu (Chữ lót)",
    owner_name_primary_name: "Họ tên chủ sở hữu (Tên)",
    owner_nation: "Quốc tịch chủ sở hữu",
    owner_id_number: "Số giấy tờ pháp lý chủ sở hữu",
    owner_contribute_percent: "Tỷ lệ góp vốn chủ sở hữu",
  })
);

const BRC_CN_Map = new Map(
  Object.entries({
    brc_poi: "Nơi cấp GCN hoạt động Chi nhánh/VPĐD",
    brc_id: "Mã số chi nhánh/VPĐD",
    brc_first_doi: "Ngày đăng ký lần đầu",
    brc_last_doi: "Ngày đăng ký thay đổi",
    company_name: "Tên chi nhánh/VPĐD viết bằng tiếng việt",
    company_foreign_name: "Tên chi nhánh/VPĐD viết bằng tiếng nước ngoài",
    company_short_name: "Tên chi nhánh/VPĐD viết tắt",
    company_address: "Địa điểm kinh doanh",
    company_address_number: "Địa điểm kinh doanh - Số nhà",
    company_address_street: "Địa điểm kinh doanh - Đường",
    company_address_ward: "Địa điểm kinh doanh (Phường/Xã)",
    company_address_ward_code: "Mã địa điểm kinh doanh (Phường/Xã)",
    company_address_district: "Địa điểm kinh doanh (Quận/Huyện)",
    company_address_district_code: "Mã địa điểm kinh doanh (Quận/Huyện)",
    company_address_province: "Địa điểm kinh doanh (Tỉnh/Thành phố)",
    company_address_province_code: "Mã địa điểm kinh doanh (Tỉnh/Thành phố)",
    company_address_country: "Địa điểm kinh doanh (Quốc gia)",
    company_type: "Loại hình doanh nghiệp",
    company_phone: "Điện thoại",
    company_fax: "Fax",
    company_email: "Email",
    company_website: "Website",
    capital: "Vốn kinh doanh",
    holding_name: "Tên công ty mẹ",
    holding_id: "Mã số công ty mẹ",
    holding_address: "Địa chỉ công ty mẹ",
    holding_address_number: "Địa chỉ công ty mẹ (Số nhà)",
    holding_address_street: "Địa chỉ công ty mẹ (Đường)",
    holding_address_ward: "Địa chỉ công ty mẹ (Phường/Xã)",
    holding_address_ward_code: "Mã địa chỉ công ty mẹ (Phường/Xã)",
    holding_address_district: "Địa chỉ công ty mẹ (Quận/Huyện)",
    holding_address_district_code: "Mã địa chỉ công ty mẹ (Quận/Huyện)",
    holding_address_province: "Địa chỉ công ty mẹ (Tỉnh/Thành phố)",
    holding_address_province_code: "Mã địa chỉ công ty mẹ (Tỉnh/Thành phố)",
    holding_address_country: "Địa chỉ công ty mẹ (Quốc gia)",
  })
);

const BRC_CN_Representer_Map = new Map(
  Object.entries({
    representer_name: "Họ tên người đại diện kinh doanh",
    representer_name_surname: "Họ tên người đại diện kinh doanh (Họ)",
    representer_name_middle_name: "Họ tên người đại diện kinh doanh (Chữ lót)",
    representer_name_primary_name: "Họ tên người đại diện kinh doanh (Tên)",
    representer_gender: "Giới tính",
    representer_dob: "Ngày sinh",
    representer_ethnic: "Dân tộc",
    representer_nation: "Quốc tịch",
    representer_id_type: "Loại giấy tờ tùy thân",
    representer_id_number: "Số giấy tờ pháp lý",
    representer_doi: "Ngày cấp",
    representer_poi: "Nơi cấp",
  })
);

const BRC_CN_Owner_Map = new Map(
  Object.entries({
    owner_name: "Họ tên người góp vốn",
    owner_name_surname: "Họ tên người góp vốn (Họ)",
    owner_name_middle_name: "Họ tên người góp vốn (Chữ lót)",
    owner_name_primary_name: "Họ tên người góp vốn (Tên)",
    owner_nation: "Quốc tịch người góp vốn",
    owner_id_number: "Số giấy tờ pháp lý người GV",
    owner_contribute_percent: "Tỷ lệ GV người góp vốn thường trú",
  })
);

const BRC_HKD_Map = new Map(
  Object.entries({
    brc_poi: "Nơi cấp GCN ĐKHKD",
    brc_id: "Số đăng ký HKD",
    brc_first_doi: "Ngày đăng ký lần đầu",
    brc_last_doi: "Ngày đăng ký thay đổi",
    company_name: "Tên hộ kinh doanh",
    company_address: "Địa điểm kinh doanh",
    company_address_number: "Địa điểm kinh doanh - Số nhà",
    company_address_street: "Địa điểm kinh doanh - Đường",
    company_address_ward: "Địa điểm kinh doanh (Phường/Xã)",
    company_address_ward_code: "Mã địa điểm kinh doanh (Phường/Xã)",
    company_address_district: "Địa điểm kinh doanh (Quận/Huyện)",
    company_address_district_code: "Mã địa điểm kinh doanh (Quận/Huyện)",
    company_address_province: "Địa điểm kinh doanh (Tỉnh/Thành phố)",
    company_address_province_code: "Mã địa điểm kinh doanh (Tỉnh/Thành phố)",
    company_address_country: "Địa điểm kinh doanh (Quốc gia)",
    company_type: "Loại hình kinh doanh",
    company_phone: "Điện thoại",
    company_fax: "Fax",
    company_email: "Email",
    company_website: "Website",
    capital: "Vốn kinh doanh",
  })
);

const BRC_HKD_Representer_Map = new Map(
  Object.entries({
    representer_name: "Họ tên người đại diện kinh doanh",
    representer_name_surname: "Họ tên người đại diện kinh doanh (Họ)",
    representer_name_middle_name: "Họ tên người đại diện kinh doanh (Chữ lót)",
    representer_name_primary_name: "Họ tên người đại diện kinh doanh (Tên)",
    representer_gender: "Giới tính",
    representer_dob: "Ngày sinh",
    representer_ethnic: "Dân tộc",
    representer_nation: "Quốc tịch",
    representer_id_type: "Loại giấy tờ tùy thân",
    representer_id_number: "Số giấy tờ pháp lý",
    representer_doi: "Ngày cấp",
    representer_poi: "Nơi cấp",
  })
);

const BRC_HKD_Owner_Map = new Map(
  Object.entries({
    owner_name: "Họ tên người góp vốn",
    owner_name_surname: "Họ tên người góp vốn (Họ)",
    owner_name_middle_name: "Họ tên người góp vốn (Chữ lót)",
    owner_name_primary_name: "Họ tên người góp vốn (Tên)",
    owner_nation: "Quốc tịch người góp vốn",
    owner_id_number: "Số giấy tờ pháp lý người GV",
    owner_contribute_percent: "Tỷ lệ GV người góp vốn thường trú",
  })
);

export const GREEN_FLOW_DOC_TYPE = [
  "CMND",
  "CCCD",
  "EID",
  "PP",
  "TAX",
  "BRC_DN",
  "BRC_CN",
  "BRC_HKD",
];

const getMap = (type: any) => {
  if (type === "CMND" || type === "CCCD" || type === "EID") return IDMap;
  if (type === "PP") return PassportMap;
  if (type === "TAX") return TaxMap;
  if (type === "brc_dn") return BRC_DN_Map;
  if (type === "brc_dn_representer") return BRC_DN_Representer_Map;
  if (type === "brc_dn_owner") return BRC_DN_Owner_Map;
  if (type === "brc_cn") return BRC_CN_Map;
  if (type === "brc_cn_representer") return BRC_CN_Representer_Map;
  if (type === "brc_cn_owner") return BRC_CN_Owner_Map;
  if (type === "brc_hkd") return BRC_HKD_Map;
  if (type === "brc_hkd_representer") return BRC_HKD_Representer_Map;
  if (type === "brc_hkd_owner") return BRC_HKD_Owner_Map;
};

export const GreenMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => GreenItemMapping(document));
};

export const GreenItemMapping = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.document_code,
    score: isNaN(document.document_code_score)
      ? document.document_code_score
      : document.document_code_score === -1
      ? 0
      : Math.min(
          Number.parseFloat(document.document_code_score) * 100,
          99
        ).toFixed(0),
  });

  if (
    document.document_type === "CMND" ||
    document.document_type === "CCCD" ||
    document.document_type === "EID" ||
    document.document_type === "PP" ||
    document.document_type === "TAX"
  ) {
    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object &&
        Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        const data_map = getMap(document.document_type);
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }
  }

  if (
    document.document_type === "BRC_DN" ||
    document.document_type === "BRC_CN" ||
    document.document_type === "BRC_HKD"
  ) {
    if (document.ocr_data && typeof document.ocr_data === "object") {
      Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
        const ocr_data = document.ocr_data[`${data_field}`];
        const data_map = getMap(data_field);
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: field.coordinates,
                    value: field.value,
                    id: field.id,
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: null,
                    id: null,
                    image_index: null,
                    value: null,
                    certain: null,
                  });
              });
            }
          });
        }
      });
    }
  }

  return {
    type: document.document_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};

export const GreenDetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => GreenItemDetail(document));
};

export const GreenItemDetail = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.doc_code,
    score: isNaN(document.doc_score)
      ? document.doc_score
      : document.doc_score === -1
      ? 0
      : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
  });

  if (
    document.doc_type === "CMND" ||
    document.doc_type === "CCCD" ||
    document.doc_type === "EID" ||
    document.doc_type === "PP" ||
    document.doc_type === "TAX"
  ) {
    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object &&
        Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        const data_map = getMap(document.doc_type);
        const ocr_update = document.ocr_update ? document.ocr_update : null;
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    value_update: ocr_update && ocr_update[field.id],
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    value_update: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }
  }

  if (
    document.doc_type === "BRC_DN" ||
    document.doc_type === "BRC_CN" ||
    document.doc_type === "BRC_HKD"
  ) {
    if (document.ocr_data && typeof document.ocr_data === "object") {
      const ocr_update = document.ocr_update ? document.ocr_update : null;
      Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
        const ocr_data = document.ocr_data[`${data_field}`];
        const data_map = getMap(data_field);
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: field.coordinates,
                    value: field.value,
                    value_update: ocr_update && ocr_update[field.id],
                    id: field.id,
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: null,
                    id: null,
                    image_index: null,
                    value: null,
                    value_update: null,
                    certain: null,
                  });
              });
            }
          });
        }
      });
    }
  }

  return {
    type: document.doc_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};
