const VRMap = new Map(
  Object.entries({
    owner_name: "Tên chủ xe",
    brand: "Nhãn hiệu",
    code: "Số loại",
    engine: "Số máy",
    chassis: "Số khung",
    color: "Màu sơn",
    number_of_sit: "Số chỗ ngồi",
    plate: "Biển số đăng ký",
    number: "Số đăng ký",
    doi: "Ngày cấp",
  })
);

const VPICMap = new Map(
  Object.entries({
    manufactured_year: "Năm sản xuất",
    manufactured_country: "Nước sản xuất",
    fuel_type: "Loại nhiên liệu",
    engine_capacity: "Thể tích làm việc",
    seri: "Số serial",
    doe: "Có hiệu lực đến ngày",
  })
);

const LC_General_Map = new Map(
  Object.entries({
    certificate_type: "Loại chứng từ",
    certificate_number: "Số cấp GCN",
    doi: "Ngày cấp",
    poi: "Nơi cấp",
    register_number: "Số vào sổ",
    updated_owner_info: "Cập nhật thông tin chủ sở hữu",
    updated_assets_info: "Cập nhật thông tin tài sản",
  })
);

const LC_Land_Map = new Map(
  Object.entries({
    land_number: "Thửa đất",
    land_map_number: "Tờ bản đồ",
    land_address: "Địa chỉ nhà",
    land_address_number: "Địa chỉ nhà - số nhà",
    land_address_street: "Địa chỉ nhà - tên đường",
    land_address_ward: "Địa chỉ nhà - phường/xã",
    land_address_ward_code: "Địa chỉ nhà - mã phường/xã",
    land_address_district: "Địa chỉ nhà - quận/huyện/thành phố",
    land_address_district_code: "Địa chỉ nhà - mã quận/huyện/thành phố",
    land_address_province: "Địa chỉ nhà - tỉnh/thành phố trực thuộc TW",
    land_address_province_code: "Địa chỉ nhà - mã tỉnh/thành phố trực thuộc TW",
    land_area: "Diện tích (m2)",
    land_uses: "Hình thức sử dụng",
    land_uses_common_area: "Diện tích đất sử dụng chung (m2)",
    land_uses_private_area: "Diện tích đất sử dụng riêng (m2)",
    land_purpose: "Mục đích sử dụng đất",
    land_purpose_residential: "Mục đích sử dụng đất ở",
    land_purpose_plant: "Mục đích sử dụng đất nông nghiệp",
    land_purpose_business: "Mục đích sử dụng đất kinh doanh",
    land_doe: "Thời hạn sử dụng đất",
    land_source: "Nguồn gốc sử dụng đất",
  })
);

const LC_Other_Map = new Map(
  Object.entries({
    other_construction_categories: "Loại tài sản gắn liền với đất",
    other_construction_area: "Tài sản gắn liền với đất - diện tích",
    other_construction_building_area:
      "Tài sản gắn liền với đất - diện tích xây dựng",
    other_construction_structure: "Tài sản gắn liền với đất - kết cấu",
    other_construction_floors: "Tài sản gắn liền với đất - số tầng",
    other_construction_build_year: "Tài sản gắn liền với đất - năm xây dựng",
  })
);

const LC_Owner_Map = new Map(
  Object.entries({
    owner_name: "Họ tên chủ sở hữu",
    owner_name_surname: "Họ tên chủ sở hữu - họ",
    owner_name_middle_name: "Họ tên chủ sở hữu - tên đệm",
    owner_name_primary_name: "Họ tên chủ sở hữu - tên",
    gender: "Giới tính",
    dob: "Ngày sinh",
    owner_id_type: "Loại giấy tờ tuỳ thân",
    owner_id_number: "Số giấy tờ tuỳ thân",
  })
);

const LC_Transfer_Map = new Map(
  Object.entries({
    full_text: "Toàn bộ thông tin chuyển nhượng",
    new_owner_name: "Họ tên chủ sở hữu mới",
    new_owner_name_surname: "Họ tên chủ sở hữu mới - họ",
    new_owner_name_middle_name: "Họ tên chủ sở hữu mới - tên đệm",
    new_owner_name_primary_name: "Họ tên chủ sở hữu mới - tên",
    new_owner_gender: "Chủ sở hữu mới - giới tính",
    new_owner_dob: "Chủ sở hữu mới - ngày sinh",
    new_owner_id_type: "Chủ sở hữu mới - loại giấy tờ tuỳ thân",
    new_owner_id_number: "Chủ sở hữu mới - số giấy tờ tuỳ thân",
  })
);

const HOC_General_Map = new Map(
  Object.entries({
    certificate_type: "Loại chứng từ",
    doi: "Ngày cấp",
    poi: "Nơi cấp",
    register_number: "Số vào sổ",
    updated_owner_info: "Cập nhật thông tin chủ sở hữu",
    updated_assets_info: "Cập nhật thông tin tài sản",
  })
);

const HOC_Land_Map = new Map(
  Object.entries({
    land_number: "Thửa đất",
    land_map_number: "Tờ bản đồ",
    land_address: "Địa chỉ nhà",
    land_address_number: "Địa chỉ nhà - số nhà",
    land_address_street: "Địa chỉ nhà - tên đường",
    land_address_ward: "Địa chỉ nhà - phường/xã",
    land_address_ward_code: "Địa chỉ nhà - mã phường/xã",
    land_address_district: "Địa chỉ nhà - quận/huyện/thành phố",
    land_address_district_code: "Địa chỉ nhà - mã quận/huyện/thành phố",
    land_address_province: "Địa chỉ nhà - tỉnh/thành phố trực thuộc TW",
    land_address_province_code: "Địa chỉ nhà - mã tỉnh/thành phố trực thuộc TW",
    land_area: "Diện tích (m2)",
    land_uses: "Hình thức sử dụng",
    land_uses_common_area: "Diện tích đất sử dụng chung (m2)",
    land_uses_private_area: "Diện tích đất sử dụng riêng (m2)",
  })
);

const HOC_House_Map = new Map(
  Object.entries({
    house_total_area: "Diện tích nhà",
    house_building_area: "Diện tích xây dựng",
    house_structure: "Kết cấu nhà",
    house_floors: "Số tầng",
  })
);

const HOC_Owner_Map = new Map(
  Object.entries({
    owner_name: "Họ tên chủ sở hữu",
    owner_name_surname: "Họ tên chủ sở hữu - họ",
    owner_name_middle_name: "Họ tên chủ sở hữu - tên đệm",
    owner_name_primary_name: "Họ tên chủ sở hữu - tên",
    gender: "Giới tính",
    dob: "Ngày sinh",
    owner_id_type: "Loại giấy tờ tuỳ thân",
    owner_id_number: "Số giấy tờ tuỳ thân",
  })
);

const HOC_Transfer_Map = new Map(
  Object.entries({
    full_text: "Toàn bộ thông tin chuyển nhượng",
    new_owner_name: "Họ tên chủ sở hữu mới",
    new_owner_name_surname: "Họ tên chủ sở hữu mới - họ",
    new_owner_name_middle_name: "Họ tên chủ sở hữu mới - tên đệm",
    new_owner_name_primary_name: "Họ tên chủ sở hữu mới - tên",
    new_owner_gender: "Chủ sở hữu mới - giới tính",
    new_owner_dob: "Chủ sở hữu mới - ngày sinh",
    new_owner_id_type: "Chủ sở hữu mới - loại giấy tờ tuỳ thân",
    new_owner_id_number: "Chủ sở hữu mới - số giấy tờ tuỳ thân",
  })
);

const LAAC_General_Map = new Map(
  Object.entries({
    certificate_type: "Loại chứng từ",
    certificate_number: "Số cấp GCN",
    doi: "Ngày cấp",
    poi: "Nơi cấp",
    register_number: "Số vào sổ",
    updated_owner_info: "Cập nhật thông tin chủ sở hữu",
    updated_assets_info: "Cập nhật thông tin tài sản",
    note: "Ghi chú",
  })
);

const LAAC_Land_Map = new Map(
  Object.entries({
    land_number: "Thửa đất",
    land_map_number: "Tờ bản đồ",
    land_address: "Địa chỉ nhà",
    land_address_number: "Địa chỉ nhà - số nhà",
    land_address_street: "Địa chỉ nhà - tên đường",
    land_address_ward: "Địa chỉ nhà - phường/xã",
    land_address_ward_code: "Địa chỉ nhà - mã phường/xã",
    land_address_district: "Địa chỉ nhà - quận/huyện/thành phố",
    land_address_district_code: "Địa chỉ nhà - mã quận/huyện/thành phố",
    land_address_province: "Địa chỉ nhà - tỉnh/thành phố trực thuộc TW",
    land_address_province_code: "Địa chỉ nhà - mã tỉnh/thành phố trực thuộc TW",
    land_area: "Diện tích (m2)",
    land_uses: "Hình thức sử dụng",
    land_uses_common_area: "Diện tích đất sử dụng chung (m2)",
    land_uses_private_area: "Diện tích đất sử dụng riêng (m2)",
    land_purpose: "Mục đích sử dụng đất",
    land_purpose_residential: "Mục đích sử dụng đất ở",
    land_purpose_plant: "Mục đích sử dụng đất nông nghiệp",
    land_purpose_business: "Mục đích sử dụng đất kinh doanh",
    land_doe: "Thời hạn sử dụng đất",
    land_source: "Nguồn gốc sử dụng đất",
  })
);

const LAAC_House_Map = new Map(
  Object.entries({
    house_type: "Loại nhà ở",
    house_name: "Tên chung cư",
    house_total_area: "Diện tích nhà",
    house_building_area: "Diện tích xây dựng",
    house_structure: "Kết cấu nhà",
    house_floors: "Số tầng",
    house_build_year: "Năm xây dựng",
    house_ownership_period: "Thời hạn sở hữu",
    house_ownership_form: "Hình thức sở hữu",
  })
);

const LAAC_Other_Map = new Map(
  Object.entries({
    other_construction_categories: "Loại tài sản gắn liền với đất",
    other_construction_area: "Tài sản gắn liền với đất - diện tích",
    other_construction_building_area:
      "Tài sản gắn liền với đất - diện tích xây dựng",
    other_construction_structure: "Tài sản gắn liền với đất - kết cấu",
    other_construction_floors: "Tài sản gắn liền với đất - số tầng",
    other_construction_build_year: "Tài sản gắn liền với đất - năm xây dựng",
  })
);

const LAAC_Owner_Map = new Map(
  Object.entries({
    owner_name: "Họ tên chủ sở hữu",
    owner_name_surname: "Họ tên chủ sở hữu - họ",
    owner_name_middle_name: "Họ tên chủ sở hữu - tên đệm",
    owner_name_primary_name: "Họ tên chủ sở hữu - tên",
    gender: "Giới tính",
    dob: "Ngày sinh",
    owner_id_type: "Loại giấy tờ tuỳ thân",
    owner_id_number: "Số giấy tờ tuỳ thân",
    owner_address: "Địa chỉ chủ sở hữu",
    owner_address_number: "Địa chỉ chủ sở hữu - số nhà",
    owner_address_street: "Địa chỉ chủ sở hữu - tên đường",
    owner_address_ward: "Địa chỉ chủ sở hữu - phường/xã",
    owner_address_ward_code: "Địa chỉ chủ sở hữu - mã phường/xã",
    owner_address_district: "Địa chỉ chủ sở hữu - quận/huyện/thành phố",
    owner_address_district_code: "Địa chỉ chủ sở hữu - mã quận/huyện/thành phố",
    owner_address_province: "Địa chỉ chủ sở hữu - tỉnh/thành phố trực thuộc TW",
    owner_address_province_code:
      "Địa chỉ chủ sở hữu - mã tỉnh/thành phố trực thuộc TW",
  })
);

const LAAC_Transfer_Map = new Map(
  Object.entries({
    full_text: "Toàn bộ thông tin chuyển nhượng",
    new_owner_name: "Họ tên chủ sở hữu mới",
    new_owner_name_surname: "Họ tên chủ sở hữu mới - họ",
    new_owner_name_middle_name: "Họ tên chủ sở hữu mới - tên đệm",
    new_owner_name_primary_name: "Họ tên chủ sở hữu mới - tên",
    new_owner_gender: "Chủ sở hữu mới - giới tính",
    new_owner_dob: "Chủ sở hữu mới - ngày sinh",
    new_owner_id_type: "Chủ sở hữu mới - loại giấy tờ tuỳ thân",
    new_owner_id_number: "Chủ sở hữu mới - số giấy tờ tuỳ thân",
  })
);

const getMap = (type: any, data_field: any = "") => {
  if (type === "VR") return VRMap;
  if (type === "VPIC") return VPICMap;
  if (type === "LC") {
    if (data_field === "general_info") return LC_General_Map;
    if (data_field === "land_info") return LC_Land_Map;
    if (data_field === "other_construction_info") return LC_Other_Map;
    if (data_field === "owner_info") return LC_Owner_Map;
    if (data_field === "transfer_info") return LC_Transfer_Map;
  }
  if (type === "HOC") {
    if (data_field === "general_info") return HOC_General_Map;
    if (data_field === "land_info") return HOC_Land_Map;
    if (data_field === "house_info") return HOC_House_Map;
    if (data_field === "owner_info") return HOC_Owner_Map;
    if (data_field === "transfer_info") return HOC_Transfer_Map;
  }
  if (type === "LAAC") {
    if (data_field === "general_info") return LAAC_General_Map;
    if (data_field === "land_info") return LAAC_Land_Map;
    if (data_field === "house_info") return LAAC_House_Map;
    if (data_field === "other_construction_info") return LAAC_Other_Map;
    if (data_field === "owner_info") return LAAC_Owner_Map;
    if (data_field === "transfer_info") return LAAC_Transfer_Map;
  }
};

export const CLMSMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => CLMSItemMapping(document));
};

export const CLMSItemMapping = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.document_code,
    score: isNaN(document.document_code_score)
      ? document.document_code_score
      : document.document_code_score === -1
      ? 0
      : Math.min(
          Number.parseFloat(document.document_code_score) * 100,
          99
        ).toFixed(0),
  });

  if (document.document_type === "VR" || document.document_type === "VPIC") {
    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object &&
        Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        const data_map = getMap(document.document_type);
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }
  }

  if (
    document.document_type === "LC" ||
    document.document_type === "HOC" ||
    document.document_type === "LAAC"
  ) {
    if (document.ocr_data && typeof document.ocr_data === "object") {
      Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
        const ocr_data = document.ocr_data[`${data_field}`];
        const data_map = getMap(document.document_type, data_field);
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: field.coordinates,
                    value: field.value,
                    id: field.id,
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: null,
                    id: null,
                    image_index: null,
                    value: null,
                    certain: null,
                  });
              });
            }
          });
        }
      });
    }
  }

  return {
    type: document.document_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};

export const CLMS_DOC_TYPE = ["VR", "VPIC", "LC", "HOC", "LAAC"];

export const CLMSDetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => CLMSItemDetail(document));
};

export const CLMSItemDetail = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.doc_code,
    score: isNaN(document.doc_score)
      ? document.doc_score
      : document.doc_score === -1
      ? 0
      : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
  });

  if (document.doc_type === "VR" || document.doc_type === "VPIC") {
    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object &&
        Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        const data_map = getMap(document.doc_type);
        const ocr_update = document.ocr_update ? document.ocr_update : null;
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    value_update: ocr_update && ocr_update[field.id],
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    value_update: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }
  }

  if (
    document.doc_type === "LC" ||
    document.doc_type === "HOC" ||
    document.doc_type === "LAAC"
  ) {
    if (document.ocr_data && typeof document.ocr_data === "object") {
      const ocr_update = document.ocr_update ? document.ocr_update : null;
      Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
        const ocr_data = document.ocr_data[`${data_field}`];
        const data_map = getMap(document.doc_type, data_field);
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (
              ocr_item &&
              ocr_item instanceof Object &&
              Object.keys(ocr_item).length > 0
            ) {
              for (const item of Array.from(data_map.keys())) {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];

                  if (item === "full_text" && index > 0) {
                    // Skip full_text for index > 0: Chỉ lấy value đầu tiên
                    continue;
                  }
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: field.coordinates,
                    value: field.value,
                    value_update: ocr_update && ocr_update[field.id],
                    id: field.id,
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: null,
                    id: null,
                    image_index: null,
                    value: null,
                    value_update: null,
                    certain: null,
                  });
              }
            }
          });
        }
      });
    }
  }

  return {
    type: document.doc_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};
