export const DATE_RANGE_FORMAT = "YYYY-MM-DD";
export const SHOW_DATE_FORMAT = "DD-MM-YYYY - HH:mm:ss";

export const DOC_TYPE_ENUM = {
  CMND: "CMND",
  CCCD: "CCCD",
  EID: "EID",
  PP: "PP",
  BRC_DN: "BRC_DN",
  BRC_CN: "BRC_CN",
  BRC_HKD: "BRC_HKD",
  TAX: "TAX",
  VR: "VR",
  VPIC: "VPIC",
  LC: "LC",
  HOC: "HOC",
  LAAC: "LAAC",
  SC: "SC",
  FOREIGN_INVOICE: "FOREIGN_INVOICE",
  TTA: "TTA",
  PAYMENT_ORDER: "PAYMENT_ORDER",
  DEPOSIT_SLIP: "DEPOSIT_SLIP",
};

export interface SingleDocType {}

export const MAP_SINGLE_DOC_TYPE = {
  CMND: "Chứng minh nhân dân",
  CCCD: "Căn cước công dân",
  EID: "Căn cước công dân gắn chíp",
  PP: "Hộ chiếu",
  BRC_DN: "Giấy chứng nhận ĐKKD doanh nghiệp",
  BRC_CN: "Giấy chứng nhận ĐKKD Chi nhánh / Văn phòng đại diện",
  BRC_HKD: "Giấy chứng nhận ĐK Hộ kinh doanh",
  TAX: "Đăng ký thuế",
  VR: "Giấy chứng nhận đăng ký xe",
  VPIC: "Giấy chứng nhận đăng kiểm xe",
  LC: "Giấy chứng nhận quyền sử dụng đất",
  HOC: "Giấy chứng nhận quyền sở hữu nhà ở và quyền sử dụng đất ở",
  LAAC: "Giấy chứng nhận quyền sử dụng đất, quyền sở hữu nhà ở và tài sản gắn liền với đất",
  SC: "Hợp đồng mua bán",
  FOREIGN_INVOICE: "Hoá đơn",
  TTA: "Giấy đề nghị chuyển tiền bằng điện",
  PAYMENT_ORDER: "Uỷ nhiệm chi",
  DEPOSIT_SLIP: "Giấy nộp tiền",
} as any;

export const NUMBER_OF_PAGE = 5;
