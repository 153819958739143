import createApiServices from "../createApiServices";

const api = createApiServices();

const SaveClientApp = (data: any) => {
  if (data && data.id)
    return api.makeAuthRequest({
      url: `/ocr-internal/v1/client`,
      method: "PUT",
      data: {
        id: data.id,
        client_alias: data.name,
        priority: parseInt(data.priority),
        webhook_url: data.webhook_url,
        auth_type: data.auth_type,
        auth_url: data.auth_url,
        auth_resp_key: data.auth_resp_key,
        auth_header: data.auth_header,
        auth_body: data.auth_body,
        webhook_header: data.webhook_header,
        bucket_path: data.bucket_path,
        bucket_name: data.bucket_name,
      },
    });
  else
    return api.makeAuthRequest({
      url: `/ocr-internal/v1/client`,
      method: "POST",
      data: {
        email: data.name,
        name: data.name,
        priority: parseInt(data.priority),
        webhook_url: data.webhook_url,
        auth_type: data.auth_type,
        auth_url: data.auth_url,
        auth_resp_key: data.auth_resp_key,
        auth_header: data.auth_header,
        auth_body: data.auth_body,
        webhook_header: data.webhook_header,
        bucket_path: data.bucket_path,
        bucket_name: data.bucket_name,
      },
    });
};

const GetClientApp = (pageNumber: any, pageSize: any, currentFilter: any) => {
  const { clientId, clientApp } = currentFilter;
  let url = `/ocr-internal/v1/client?clientId=${clientId}&clientApp=${clientApp}`;
  if (pageNumber && pageSize) {
    url = url + `&page=${pageNumber}&limit=${pageSize}`;
  }
  return api.makeAuthRequest({
    url: url,
    method: "GET",
  });
};

const DeleteClientApp = (client_id: any) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/client/${client_id}`,
    method: "DELETE",
  });
};

const GetUser = (pageNumber: any, pageSize: any, currentFilter: any) => {
  const { username, role, groupId } = currentFilter;
  console.log({ username, role, groupId });
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/user?limit=${pageSize}&page=${pageNumber}&username=${
      username ? username : ""
    }&role=${role ? role : ""}&groupId=${groupId ? groupId : ""}`,
    method: "GET",
  });
};

const SaveUser = (data: any) => {
  if (data && data.id)
    return api.makeAuthRequest({
      url: `/ocr-internal/v1/user`,
      method: "PUT",
      data: {
        account_id: data.id,
        group_id: data.groups,
        role: data.role,
      },
    });
  else
    return api.makeAuthRequest({
      url: `/ocr-internal/v1/user`,
      method: "POST",
      data: {
        ...data,
        email: data.username,
      },
    });
};

const DeleteUser = (group_id: any) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/user/${group_id}`,
    method: "DELETE",
  });
};

const GetUserGroup = (pageSize: any, pageNumber: any, currentFilter: any) => {
  const { id, name, clientApp } = currentFilter;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/group?limit=${pageSize}&page=${pageNumber}&id=${id}&name=${name}&clientApp=${clientApp}`,
    method: "GET",
  });
};

const GetUserbyGroup = (group_id: any) => {
  var limit = 100000;
  var page = 0;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/user?limit=${limit}&page=${page}&group_id=${group_id}`,
    method: "GET",
  });
};

const SaveUserGroup = (data: any) => {
  if (data && data.id)
    return api.makeAuthRequest({
      url: `/ocr-internal/v1/group`,
      method: "PUT",

      data: {
        group_id: data.id,
        client_id: data.clients,
        group_name: data.group_name,
      },
    });
  else
    return api.makeAuthRequest({
      url: `/ocr-internal/v1/group`,
      method: "POST",
      data,
    });
};

const DeleteUserGroup = (group_id: any) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/group/${group_id}`,
    method: "DELETE",
  });
};

const GetUserRole = () => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/role`,
    method: "GET",
  });
};

const PushUserFile = (file: any) => {
  let form_data = new FormData();
  form_data.append("File", file);

  return api.makeAuthRequest({
    url: `/ocr-internal/v1/user/import`,
    method: "POST",
    data: form_data,
  });
};

export const User = {
  SaveClientApp,
  GetClientApp,
  DeleteClientApp,
  GetUser,
  SaveUser,
  DeleteUser,
  GetUserGroup,
  GetUserbyGroup,
  SaveUserGroup,
  DeleteUserGroup,
  GetUserRole,
  PushUserFile,
};
