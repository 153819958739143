import { useState, useEffect } from 'react';
import { APIServices } from '../../../utils';
import { Spin } from 'antd';

export const FileComponent = (props: any) => {
  const [file, setFile] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const { type, documentID, fileIDs, clientID, fileIndex, requestID, handleClick, allowClick } =
    props;

  useEffect(() => {
    async function getFile() {
      let fileContent;
      try {
        setLoading(true);
        fileContent = await APIServices.OCR.GetFileContent(
          type,
          documentID,
          fileIDs[fileIndex],
          requestID,
          clientID
        );
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return null;
      }

      setFile(`data:image/png;base64,${fileContent.data.file_base64}`);
    }

    if (fileIDs && fileIDs.length > 0 && !fileIDs[0].includes('pdf')) getFile();
  }, [type, fileIDs, fileIndex, documentID, requestID, clientID]);

  return (
    <>
      {loading && <Spin spinning={loading}></Spin>}
      {!loading && (
        <img
          src={file}
          alt={`file`}
          id='document_image'
          onClick={() => handleClick(documentID, fileIDs, file)}
          style={{ cursor: allowClick ? 'pointer' : 'default' }}
        />
      )}
    </>
  );
};
