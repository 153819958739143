import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const dateFormat = "DD-MM";
const dateYearFormat = "DD-MM-YYYY";

export const DocTypeChartComponent = (props: any) => {
  const { documentData, currentFilter } = props;
  const COLORS = [
    "#FFE59D",
    "#F7B509",
    "#B55B08",
    "#06347D",
    "#7BC7FF",
    "#3C9DF3",
    "#CCF5E5",
  ];

  var listDocType: any[] = [];
  if (documentData && documentData.length > 0) {
    documentData.forEach((item: any) => {
      const { DocType } = item;

      const existData = listDocType.findIndex((e: any) => e === DocType);

      if (existData === -1) listDocType.push(DocType);
    });
  }

  var areaChartData: any[] = [];

  if (documentData && documentData.length > 0) {
    documentData.forEach((item: any) => {
      const { CreatedAt, DocType, DocTypeCount } = item;
      const time_value = moment(CreatedAt).format(dateFormat);
      const unix_value = moment(time_value, dateFormat).unix();
      const existData = areaChartData.findIndex(
        (e: any) => e.name === time_value
      );
      if (existData === -1) {
        var newData: any = {
          name: time_value,
          unix_value,
          [DocType]: DocTypeCount,
        };
        areaChartData.push(newData);
      } else {
        areaChartData[existData] = {
          ...areaChartData[existData],
          [DocType]: DocTypeCount,
        };
      }
    });
    areaChartData.sort((a, b) => a.unix_value - b.unix_value);
  }

  if (!documentData || documentData.length === 0)
    return <div className="ReportBlock">Không có dữ liệu</div>;
  return (
    <div className="ReportBlock">
      <div className="BlockTitle">Số lượng yêu cầu OCR từng loại chứng từ</div>
      <div className="BlockTime">
        {moment(currentFilter.from_time).format(dateYearFormat)} &#8226;{" "}
        {moment(currentFilter.to_time).format(dateYearFormat)}
      </div>
      <div className="BlockBody OCR_Status">
        <div className="ChartContainer">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={areaChartData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {listDocType.map((type: any, index: any) => {
                return (
                  <Area
                    key={`area_${index}`}
                    type="monotone"
                    dataKey={type}
                    stackId="1"
                    stroke={COLORS[index % COLORS.length]}
                    fill={COLORS[index % COLORS.length]}
                  />
                );
              })}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
