import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationService } from "../../components";
import { APIServices } from "../../utils";
import actions from "./actions";
import reqActions from "../request/actions";
import authActions from "../auth/actions";

function* saga_LoadData(): Generator<any, void, any> {
  try {
    yield put(reqActions.action.updateRequestState(0));
    let isFullGroup: any = yield select(
      (state: any) => state.user_group.isFullGroup
    );
    let pageNumber = yield select((state: any) => state.user_group.page_number);
    let _pageSize = yield select((state: any) => state.user_group.page_size);
    let pageSize = isFullGroup ? 99999999 : _pageSize;
    let currentFilter = yield select(
      (state: any) => state.user_group.current_filter
    );
    let _response: Promise<any> = yield APIServices.User.GetUserGroup(
      pageSize,
      pageNumber,
      currentFilter
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      const { group, paging } = data;
      yield put(actions.action.loadDataSuccess(group, paging));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải danh sách User Group thất bại");
    }
  } catch (ex: any) {
    console.log("[User Group] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải danh sách User Group thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_LoadUserData(action: any) {
  try {
    let { group_id } = action.payload;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.GetUserbyGroup(
      group_id
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(actions.action.loadUserDataSuccess(data));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải danh sách User thất bại");
    }
  } catch (ex: any) {
    console.log("[User Group] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải danh sách User thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_saveData() {
  try {
    let _currentItem: Promise<any> = yield select(
      (state: any) => state.user_group.current_item
    );
    let currentItem = _currentItem;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.SaveUserGroup(
      currentItem
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(reqActions.action.updateRequestState(1));
      yield saga_LoadData();
      NotificationService.success("Lưu User Group thành công");
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Lưu User Group thất bại");
    }
  } catch (ex: any) {
    console.log(
      "[User Group] saga_LoadData Error: ",
      ex && ex.data && ex.data.message
    );
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error(
      ex && ex.data && ex.data.message
        ? ex.data.message
        : "Lưu User Group thất bại"
    );

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_deleteData(action: any) {
  try {
    let { group_id } = action.payload;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.User.DeleteUserGroup(
      group_id
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(reqActions.action.updateRequestState(1));
      yield saga_LoadData();
      NotificationService.success("Xoá User Group thành công");
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Xoá User Group thất bại");
    }
  } catch (ex: any) {
    console.log("[User Group] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Xoá User Group thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_DATA, saga_LoadData);
  yield takeEvery(actions.types.LOAD_USER_DATA, saga_LoadUserData);
  yield takeEvery(actions.types.SAVE_CURRENT_ITEM, saga_saveData);
  yield takeEvery(actions.types.DELETE_CURRENT_ITEM, saga_deleteData);
  yield takeEvery(actions.types.UPDATE_PAGE_NUMBER, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_SIZE, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_FILTER, saga_LoadData);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
