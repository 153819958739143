import { Spin } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DATE_RANGE_FORMAT } from "../../common";
import FilterComponent from "../../components/filterComponent";
import { PaginationComponent } from "../../components/pagination";
import { useActions } from "../../redux";
import { FileComponent } from "./component";
import { renderDateTime } from "../../utils";

const options = [
  { label: "Request ID", value: 1 },
  { label: "Loại File", value: 2 },
  { label: "Định dạng File", value: 3 },
  { label: "Client ID", value: 4 },
];

export const SignPage = () => {
  const dispatch = useDispatch();
  const actions = useActions();
  const requestState = useSelector((state: any) => state.request.request_state);
  let data = useSelector((state: any) => state.sign.data);
  let clientAppData = useSelector((state: any) => state.client_app.data);
  let totalRecord = useSelector((state: any) => state.sign.total_record);
  let pageNumber = useSelector((state: any) => state.sign.page_number);
  let pageSize = useSelector((state: any) => state.sign.page_size);

  useEffect(() => {
    if (!clientAppData) dispatch(actions.ClientAppActions.loadData(true));
    if (clientAppData && !data) dispatch(actions.SignActions.loadData());
  }, [
    actions.SignActions,
    actions.ClientAppActions,
    clientAppData,
    data,
    dispatch,
  ]);

  const handleChangePageNumber = (page_number: any) => {
    dispatch(actions.SignActions.updatePageNumber(page_number));
  };

  const handleChangePageSize = (page_size: any) => {
    dispatch(actions.SignActions.updatePageSize(page_size));
  };

  const onSearch = (
    fromTime: moment.Moment,
    toTime: moment.Moment,
    searchText: string
  ) => {
    dispatch(
      actions.SignActions.updateFilter({
        from_time: moment(fromTime).format(DATE_RANGE_FORMAT),
        to_time: moment(toTime).format(DATE_RANGE_FORMAT),
        search_text: searchText,
      })
    );
    //dispatch(actions.SignActions.loadData());
  };

  return (
    <div className="AppPage">
      <div className="PageTitle">Signature Logs</div>

      <FilterComponent
        options={options}
        showSearchType={false}
        onSearch={onSearch}
      />

      <Spin
        spinning={requestState === 0}
        size="large"
        style={{ position: "static" }}
      >
        <div className="TableContainer">
          <table className="table table-striped sticky-header">
            <thead>
              <tr>
                <th>Request ID</th>
                <th>Vùng chữ ký bóc tách</th>
                <th>Vùng chữ ký cần so khớp</th>
                <th>Độ chính xác</th>
                <th>Thời gian bắt đầu</th>
                <th>Thời gian so khớp</th>
                <th>Thời gian kết thúc</th>
                <th>Trạng thái OCR</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item: any, index: any) => {
                  const { compared_signatures } = item;

                  if (!compared_signatures)
                    return (
                      <tr key={`row_${index}`}>
                        <td>{item.request_id}</td>
                        <td>
                          <FileComponent
                            size="original"
                            path={item.file_path + "/" + item.file_id}
                            clientID={item.client_id}
                            requestID={item.request_id}
                            allowClick={false}
                          />
                        </td>
                        <td colSpan={3}></td>
                        <td>Thành công</td>
                      </tr>
                    );
                  else
                    return compared_signatures.map(
                      (compare: any, comp_index: any) => {
                        return (
                          <tr key={`row_${comp_index}`}>
                            {comp_index === 0 && (
                              <td rowSpan={compared_signatures.length}>
                                {item.request_id}
                              </td>
                            )}
                            {comp_index === 0 && (
                              <td rowSpan={compared_signatures.length}>
                                <FileComponent
                                  size="original"
                                  path={item.file_path + "/" + item.file_id}
                                  clientID={item.client_id}
                                  requestID={item.request_id}
                                  allowClick={false}
                                />
                              </td>
                            )}
                            <td>
                              <FileComponent
                                size="original"
                                path={item.file_path + "/" + compare.file_id}
                                clientID={item.client_id}
                                requestID={item.request_id}
                                allowClick={false}
                              />
                            </td>
                            <td>
                              <div>
                                {Number(compare.match_score.toFixed(2) ?? 0)}
                              </div>
                            </td>
                            <td>{renderDateTime(compare.created_at)}</td>
                            <td>{(compare.time_used / 1000).toFixed(2)}</td>
                            <td>{renderDateTime(compare.finished_at)}</td>
                            {comp_index === 0 && (
                              <td rowSpan={compared_signatures.length}>
                                Thành công
                              </td>
                            )}
                          </tr>
                        );
                      }
                    );
                })}
              {(!data || data.length === 0) && (
                <tr>
                  <td colSpan={8}>Không có dữ liệu</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
          totalRecord={totalRecord}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onChangePageNumber={handleChangePageNumber}
          onChangePageSize={handleChangePageSize}
        />
      </Spin>
    </div>
  );
};
