const BirthCertificateMap = new Map(
  Object.entries({
    name: 'Họ và tên',
  })
);

const GuardianRegistrationMap = new Map(
  Object.entries({
    guardian_name: 'Họ tên người giám hộ',
    ward_name: 'Họ tên người được giám hộ',
  })
);

const AcbsMap = new Map(
  Object.entries({
    name: 'Họ tên',
    qf_code: 'Mã QF',
  })
);

const VisaMap = new Map(
  Object.entries({
    passport_number: 'Hộ chiếu số',
    doe: 'Đến ngày',
  })
);

const WorkPermitMap = new Map(
  Object.entries({
    name: 'Họ tên',
    doe: 'Đến ngày',
  })
);

const TemporaryResidentMap = new Map(
  Object.entries({
    name: 'Họ tên',
    doe: 'Thẻ có giá trị đến',
  })
);

const DriverLicenseMap = new Map(
  Object.entries({
    name: 'Họ tên',
  })
);

const SalaryConfirmationMap = new Map(
  Object.entries({
    title: 'Tiêu đề',
  })
);

const CCCDMap = new Map(
  Object.entries({
    id_number: 'Số CCCD / eID',
    name: 'Họ và tên',
    doi: 'Ngày cấp',
  })
);

const CMNDMap = new Map(
  Object.entries({
    id_number: 'Số CMND',
    name: 'Họ và tên',
    doi: 'Ngày cấp',
    poi: 'Nơi cấp',
  })
);

const PassportMap = new Map(
  Object.entries({
    id_number: 'Số CMND / CCCD/ eID',
    passport_number: 'Số Hộ Chiếu',
    name: 'Họ và tên',
    doi: 'Ngày cấp',
  })
);

const QFPrimaryMap = new Map(
  Object.entries({
    customer_name: 'Tên khách hàng',
    person_code: 'Mã số giấy tờ tuỳ thân',
    company_name: 'Tên công ty',
    brc_code: 'Mã công ty',
    account_number: 'Tài khoản ngân hàng',
    tax_code: 'Mã số thuế',
  })
);

const QFSecondaryMap = new Map(
  Object.entries({
    customer_name: 'Tên khách hàng phụ',
    person_code: 'Mã số giấy tờ tuỳ thân khách hàng phụ',
    company_name: 'Tên công ty khách hàng phụ',
    brc_code: 'Mã công ty khách hàng phụ',
    account_number: 'Tài khoản ngân hàng khách hàng phụ',
    tax_code: 'Mã số thuế khách hàng phụ',
  })
);

const QFDocMap = new Map(
  Object.entries({
    doi: 'Ngày tài liệu',
    phone: 'Số điện thoại',
    qf_date: 'Ngày QF',
    account_number: 'Số tài khoản',
  })
);

const getMap = (type: any) => {
  if (type === 'BIRTH_CERTIFICATE') return BirthCertificateMap;
  if (type === 'GUARDIANSHIP_REGISTRATION') return GuardianRegistrationMap;
  if (type === 'ACBS') return AcbsMap;
  if (type === 'VISA') return VisaMap;
  if (type === 'WORK_PERMIT') return WorkPermitMap;
  if (type === 'TEMPORARY_RESIDENT') return TemporaryResidentMap;
  if (type === 'DRIVER_LICENSE') return DriverLicenseMap;
  if (type === 'SALARY_CONFIRMATION') return SalaryConfirmationMap;
  if (type === 'CCCD' || type === 'EID') return CCCDMap;
  if (type === 'CMND') return CMNDMap;
  if (type === 'PP') return PassportMap;
  if (type === 'primary_customer_info') return QFPrimaryMap;
  if (type === 'secondary_customer_info') return QFSecondaryMap;
  if (type === 'qf_info') return QFDocMap;
};

export const A2Mapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.document_code,
      score: isNaN(document.document_code_score)
        ? document.document_code_score
        : document.document_code_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.document_code_score) * 100, 99).toFixed(0),
    });

    if (document.document_type !== 'QF') {
      if (document.ocr_data) {
        const doc_type =
          document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
            ? Object.keys(document.ocr_data)[0]
            : null;
        if (doc_type) {
          const ocr_data = document.ocr_data[`${doc_type}`]; // List
          const data_map = getMap(document.document_type);
          if (ocr_data && ocr_data.length > 0 && data_map) {
            ocr_data.forEach((ocr_item: any, index: any) => {
              if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
                Array.from(data_map.keys()).forEach((item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      id: field.id,
                      coords: field.coordinates,
                      value: field.value,
                      image_index: field.image_index,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      id: null,
                      coords: null,
                      image_index: null,
                      value: null,
                      certain: null,
                    });
                });
              }
            });
          }
        }
      }
    }

    if (document.document_type === 'QF') {
      if (document.ocr_data && typeof document.ocr_data === 'object') {
        Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
          const ocr_data = document.ocr_data[`${data_field}`];
          const data_map = getMap(data_field);
          if (ocr_data && ocr_data.length > 0 && data_map) {
            ocr_data.forEach((ocr_item: any, index: any) => {
              if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
                Array.from(data_map.keys()).forEach((item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      coords: field.coordinates,
                      value: field.value,
                      id: field.id,
                      image_index: field.image_index,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      coords: null,
                      id: null,
                      image_index: null,
                      value: null,
                      certain: null,
                    });
                });
              }
            });
          }
        });
      }
    }

    return {
      type: document.document_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};

export const A2DetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.doc_code,
      score: isNaN(document.doc_score)
        ? document.doc_score
        : document.doc_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
    });

    if (document.doc_type !== 'QF') {
      if (document.ocr_data) {
        const doc_type =
          document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
            ? Object.keys(document.ocr_data)[0]
            : null;
        if (doc_type) {
          const ocr_data = document.ocr_data[`${doc_type}`]; // List
          const data_map = getMap(document.doc_type);
          const ocr_update = document.ocr_update ? document.ocr_update : null;
          if (ocr_data && ocr_data.length > 0 && data_map) {
            ocr_data.forEach((ocr_item: any, index: any) => {
              if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
                Array.from(data_map.keys()).forEach((item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      id: field.id,
                      coords: field.coordinates,
                      value: field.value,
                      value_update: ocr_update && ocr_update[field.id],
                      image_index: field.image_index,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      id: null,
                      coords: null,
                      image_index: null,
                      value: null,
                      value_update: null,
                      certain: null,
                    });
                });
              }
            });
          }
        }
      }
    }

    if (document.doc_type === 'QF') {
      if (document.ocr_data && typeof document.ocr_data === 'object') {
        const ocr_update = document.ocr_update ? document.ocr_update : null;
        Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
          const ocr_data = document.ocr_data[`${data_field}`];
          const data_map = getMap(data_field);

          if (ocr_data && ocr_data.length > 0 && data_map) {
            ocr_data.forEach((ocr_item: any, index: any) => {
              if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
                Array.from(data_map.keys()).forEach((item: any, _: any) => {
                  if (ocr_item[item]) {
                    let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      coords: field.coordinates,
                      value: field.value,
                      value_update: ocr_update && ocr_update[field.id],
                      id: field.id,
                      image_index: field.image_index,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                    });
                  } else
                    ocrData.push({
                      attribute:
                        index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                      coords: null,
                      id: null,
                      image_index: null,
                      value: null,
                      value_update: null,
                      certain: null,
                    });
                });
              }
            });
          }
        });
      }
    }

    return {
      type: document.doc_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};
