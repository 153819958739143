import actions from "./actions";

const default_filter = {
  searchText: "",
  searchType: 1,
};

const initState = {
  current_filter: default_filter,
  data: null,
  current_item: null,
  page_number: 1,
  page_size: 10,
  total_record: 0,
};

const FlowReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case actions.types.UPDATE_FILTER:
      return {
        ...state,
        ...{
          current_filter: action.payload.filter,
        },
      };
    case actions.types.UPDATE_PAGE_NUMBER:
      return {
        ...state,
        ...{
          page_number: action.payload.page_number,
        },
      };
    case actions.types.UPDATE_PAGE_SIZE:
      return {
        ...state,
        ...{
          page_size: action.payload.page_size,
        },
      };
    case actions.types.LOAD_DATA:
      return {
        ...state,
      };
    case actions.types.LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.data,
          total_record: action.payload.total,
        },
      };
    case actions.types.UPDATE_CURRENT_ITEM:
      return {
        ...state,
        ...{
          current_item: action.payload.data,
        },
      };
    case actions.types.SAVE_CURRENT_ITEM:
      return {
        ...state,
      };
    case actions.types.DELETE_CURRENT_ITEM:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default FlowReducer;
