/* eslint-disable */
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { persistReducer } from 'redux-persist';
// import { PersistGate } from 'redux-persist/integration/react';

// import { pStore, persistor } from './redux/persistStore';
import { history, store } from './redux/store';
import { RouterLinks } from './const';
import { LoginPage, OTPPage } from './pages';

import { ReactNotifications } from 'react-notifications-component';
import './app.scss';
import 'antd/dist/antd.css';
import 'react-notifications-component/dist/theme.css';
import { AuthorizationComponent } from './components';
import { AuthorizedPage } from './pages/authorized-page';

export default () => {
  return (
    <>
      <ReactNotifications />
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <Router>
          <div className='MainApp'>
            <div className='MainContent'>
              <div className='ContentPage'>
                <Switch>
                  <Route component={LoginPage} path={RouterLinks.LOGIN_PAGE} />
                  <Route component={OTPPage} path={RouterLinks.OTP_PAGE} />
                  <AuthorizationComponent component={AuthorizedPage} path={RouterLinks.APP} />
                  <Redirect to={RouterLinks.LOGIN_PAGE} />
                </Switch>
              </div>
            </div>
          </div>
        </Router>
        {/* </PersistGate> */}
      </Provider>
    </>
  );
};
