import { useState, useEffect } from "react";
import { Spin } from "antd";
import moment from "moment";

import { APIServices } from "../../../utils";
import { ModalComponent } from "../../../components";
import "./index.scss";

const dateFormat = "DD-MM-YYYY - HH:mm:ss";

export const UserModalComponent = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<any>(null);
  const [loadedData, setLoadedData] = useState(null);
  const { groupID, onCancel } = props;

  useEffect(() => {
    async function getUserData() {
      let res;
      setLoadedData(groupID);
      setUsers(null);
      try {
        setLoading(true);
        res = await APIServices.User.GetUserbyGroup(groupID);
        if (res.data) {
          setUsers(res.data?.account);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return null;
      }
    }
    if (groupID && groupID !== loadedData) getUserData();
  });

  const handleCancel = () => {
    setLoadedData(null);
    onCancel();
  };
  console.log({ users });
  const _renderViewModal = () => {
    return (
      <Spin spinning={loading} style={{ position: "static" }}>
        <div className="UserModalContainer">
          <table>
            <thead>
              <tr>
                <th style={{ width: "10vw" }}>User ID</th>
                <th style={{ width: "20vw" }}>User Name</th>
                <th style={{ width: "15vw" }}>Role</th>
                <th style={{ width: "15vw" }}>Created At</th>
              </tr>
            </thead>
            <tbody>
              {(!users || users.length === 0) && (
                <tr>
                  <td colSpan={4}>Không có dữ liệu</td>
                </tr>
              )}
              {users &&
                users?.map((user: any, row_index: any) => {
                  return (
                    <tr key={`row_${row_index}`}>
                      <td>{user.id}</td>
                      <td>{user.username}</td>
                      <td>{user.role}</td>
                      <td>{moment(user.createdAt).format(dateFormat)}</td>
                    </tr>
                  );
                })}
            </tbody>
            {users && users.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={4}>Số lượng users: {users.length}</td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </Spin>
    );
  };

  return (
    <>
      <ModalComponent
        title={`Group User: ${groupID}`}
        isModalVisible={groupID}
        onCancel={handleCancel}
        bodyStyle={{ padding: 0, width: "auto" }}
        footer={null}
        header={null}
      >
        {_renderViewModal()}
      </ModalComponent>
    </>
  );
};
