import { all, fork, put, takeEvery, select } from 'redux-saga/effects';
import { NotificationService } from '../../components';
import { APIServices } from '../../utils';
import actions from './actions';
import reqActions from '../request/actions';
import authActions from '../auth/actions';

function* saga_LoadData() {
  try {
    let _pageNumber: Promise<any> = yield select((state: any) => state.flow.page_number);
    let pageNumber = _pageNumber;
    let _pageSize: Promise<any> = yield select((state: any) => state.flow.page_size);
    let pageSize = _pageSize;
    let _currentFilter: Promise<any> = yield select((state: any) => state.flow.current_filter);
    let currentFilter = _currentFilter;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Flow.GetFlow(
      pageSize,
      pageNumber,
      currentFilter
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      let { queuePriorities, total } = data;
      yield put(actions.action.loadDataSuccess(total, queuePriorities));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error('Tải danh sách Document Workflow thất bại');
    }
  } catch (ex: any) {
    console.log('[Flow] saga_LoadData Error: ', ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error('Tải danh sách Document Workflow thất bại');

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_saveData() {
  try {
    let _currentItem: Promise<any> = yield select((state: any) => state.flow.current_item);
    let currentItem = _currentItem;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Flow.SaveFlow(currentItem);
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(reqActions.action.updateRequestState(1));
      yield saga_LoadData();
      NotificationService.success('Lưu Document Workflow thành công');
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error('Lưu Document Workflow thất bại');
    }
  } catch (ex: any) {
    console.log('[Client app] saga_LoadData Error: ', ex);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error(
      ex && ex.data && ex.data.message ? ex.data.message : 'Lưu Document Workflow thất bại'
    );

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_deleteData(action: any) {
  try {
    let { id } = action.payload;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Flow.DeleteFlow(id);
    let response: any = _response;
    let { data } = response;

    if (data) {
      yield put(reqActions.action.updateRequestState(1));
      yield saga_LoadData();
      NotificationService.success('Xoá Document Workflow thành công');
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error('Xoá Document Workflow thất bại');
    }
  } catch (ex: any) {
    console.log('[Document Workflow] saga_LoadData Error: ', ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error('Xoá Document Workflow thất bại');

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_DATA, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_FILTER, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_NUMBER, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_SIZE, saga_LoadData);
  yield takeEvery(actions.types.SAVE_CURRENT_ITEM, saga_saveData);
  yield takeEvery(actions.types.DELETE_CURRENT_ITEM, saga_deleteData);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
