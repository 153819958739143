import moment from "moment";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
const dateFormat = "DD-MM-YYYY";
const EvaluateChartComponent = (props: any) => {
  const { evaluateData, currentFilter } = props;
  const data = evaluateData?.map((item: any) => {
    return {
      Name: item.DocType,
      Success: item.Success,
      Fail: item.Fail,
      Pending: item.Pending,
      Processing: item.Processing,
    };
  });
  if (!data || data.length === 0)
    return <div className="ReportBlock">Không có dữ liệu</div>;
  return (
    <div className="ReportBlock">
      <div className="BlockTitle">Tỉ lệ kết quả xử lý chứng từ</div>
      <div className="BlockTime">
        {moment(currentFilter?.from_time).format(dateFormat)} &#8226;{" "}
        {moment(currentFilter?.to_time).format(dateFormat)}
      </div>
      <div className="BlockBody OCR_Status">
        <div className="ChartContainer" style={{ width: "100%" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Success" stackId="a" fill="#82ca9d" />
              <Bar dataKey="Fail" stackId="a" fill="#FF8042" />
              <Bar dataKey="Pending" stackId="a" fill="#656565" />
              <Bar dataKey="Processing" stackId="a" fill="#0051ff" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default EvaluateChartComponent;
