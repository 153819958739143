export const parseJw = (token: any) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const createQueryString = (json: any) => {
  // Check if the input is a valid JSON object
  if (typeof json !== 'object' || json === null) {
    throw new Error('Invalid input. Please provide a valid JSON object.');
  }

  // Convert the JSON object to a query string
  const queryString = Object.keys(json)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
    .join('&');

  return queryString;
};
