import { useEffect, useState } from "react";
import { Spin } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { useActions } from "../../redux";
import {
  ReportFilterComponent,
  OCRStatusChartComponent,
  RequestNumberChartComponent,
  DocTypeChartComponent,
  DocTimeChartComponent,
} from "./component";
import { APIServices } from "../../utils";
import EvaluateChartComponent from "./component/evaluate-chart";
import ConfidentChartComponent from "./component/confident-chart";
import { DATE_RANGE_FORMAT } from "../../common";

export const ReportPage = () => {
  const dispatch = useDispatch();
  const actions = useActions();
  useEffect(() => {
    dispatch(actions.ReportActions.loadDocTypes());
  }, [dispatch]); // This will run when the component mounts because of the empty dependency array

  const requestState = useSelector((state: any) => state.request.request_state);
  let currentFilter = useSelector((state: any) => state.report.current_filter);
  let requestData = useSelector((state: any) => state.report.request_data);
  let documentData = useSelector((state: any) => state.report.document_data);
  let evaluateData = useSelector((state: any) => state.report.evaluate_data);
  let clientAppData = useSelector((state: any) => state.client_app.data);
  const doc_types = useSelector((state: any) => state.report.doc_types);
  const [docTypes, setDocTypes] = useState([]);

  useEffect(() => {
    dispatch(
      actions.ReportActions.updateFilter({
        from_time: moment().format(DATE_RANGE_FORMAT),
        to_time: moment().format(DATE_RANGE_FORMAT),
        clients: [],
        doc_types: doc_types
          ? doc_types.slice(0, 5).map((item: any) => item.doc_type)
          : [],
      })
    );
    if (!clientAppData) dispatch(actions.ClientAppActions.loadData(true));
    if (clientAppData) {
      dispatch(actions.ReportActions.loadDocumentData());
      dispatch(actions.ReportActions.loadRequestData());
      dispatch(actions.ReportActions.loadEvaluateData());
    }
  }, [
    actions.ReportActions,
    actions.ClientAppActions,
    clientAppData,
    dispatch,
  ]);

  useEffect(() => {
    if (!doc_types) return;
    const docTypes = doc_types?.map((item: any) => {
      return { label: item.doc_type, value: item.doc_type };
    });
    setDocTypes(docTypes);
  }, [doc_types]);

  currentFilter = {
    ...currentFilter,
    from_time: currentFilter.from_time
      ? moment(currentFilter.from_time, DATE_RANGE_FORMAT)
      : "",
    to_time: currentFilter.to_time
      ? moment(currentFilter.to_time, DATE_RANGE_FORMAT)
      : "",
  };

  const handleChangeFilter = (filter: any) => {
    dispatch(actions.ReportActions.updateFilter(filter));
  };

  const handleSearch = () => {
    dispatch(actions.ReportActions.loadDocumentData());
    dispatch(actions.ReportActions.loadRequestData());
    dispatch(actions.ReportActions.loadEvaluateData());
  };

  const handleReset = () => {
    dispatch(
      actions.ReportActions.updateFilter({
        from_time: moment().subtract(7, "days").format(DATE_RANGE_FORMAT),
        to_time: moment().format(DATE_RANGE_FORMAT),
        clients: [],
      })
    );
    dispatch(actions.ReportActions.loadDocumentData());
    dispatch(actions.ReportActions.loadRequestData());
    dispatch(actions.ReportActions.loadEvaluateData());
  };

  const handleDownload = () => {
    getFile();
  };

  var clientAppList = [];
  if (clientAppData && clientAppData.length > 0) {
    clientAppList = clientAppData.map((item: any, _: any) => {
      return {
        value: item.client_id,
        label: item.client_alias,
      };
    });
    // clientAppList.push({
    //   value: 'all',
    //   label: 'Chọn tất cả',
    // });
    // clientAppList.reverse();
  }

  async function getFile() {
    let blob;
    try {
      blob = await APIServices.Report.DownloadData({
        from_time: moment(currentFilter.from_time).format(DATE_RANGE_FORMAT),
        to_time: moment(currentFilter.to_time).format(DATE_RANGE_FORMAT),
        clients: currentFilter.clients,
      });
      var data = new Blob([blob]);
      var csvURL = window.URL.createObjectURL(data);
      var tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "ocr_report.xlsx");
      tempLink.click();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  return (
    <div className="AppPage" style={{ backgroundColor: "#E6E8ED" }}>
      <div className="PageTitle">OCR Reports</div>

      <ReportFilterComponent
        currentFilter={currentFilter}
        onFilterChange={handleChangeFilter}
        onSearch={handleSearch}
        onReset={handleReset}
        onDownload={handleDownload}
        clientAppList={clientAppList}
        docTypes={docTypes}
      />

      <div className="ReportContainer">
        <OCRStatusChartComponent
          requestData={requestData}
          currentFilter={currentFilter}
        />
        <RequestNumberChartComponent
          requestData={requestData}
          currentFilter={currentFilter}
        />
        <DocTypeChartComponent
          documentData={documentData}
          currentFilter={currentFilter}
        />
        <DocTimeChartComponent
          documentData={documentData}
          currentFilter={currentFilter}
        />
        <EvaluateChartComponent
          evaluateData={evaluateData}
          currentFilter={currentFilter}
        />
        <ConfidentChartComponent
          evaluateData={evaluateData}
          currentFilter={currentFilter}
        />
      </div>

      {(!requestData || !documentData) && (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 300px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin
            spinning={requestState === 0}
            size="large"
            style={{ position: "static" }}
          ></Spin>
        </div>
      )}
    </div>
  );
};
