import { Redirect } from 'react-router-dom';
import { Button, Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';
import { useActions } from '../../redux';
import { RouterLinks } from '../../const';
import wc from '../../assets/ocr.png';
import logo from '../../assets/logo2.png';

export const OTPPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const actions = useActions();

  const requestState = useSelector((state: any) => state.request.request_state);
  const token = useSelector((state: any) => state.auth.token);
  const loginInfo = useSelector((state: any) => state.auth.login_info);
  const otp = useSelector((state: any) => state.auth.otp);

  const handleValuesChange = () => {
    dispatch(actions.AuthActions.updateOTP(form.getFieldsValue()));
  };

  const checkValidateForm = () => {
    const { otp_value } = loginInfo;
    if (otp_value === '') return false;
    return true;
  };

  const handleOTP = () => {
    dispatch(actions.AuthActions.verifyOTP());
  };

  const handleResend = () => {
    dispatch(actions.AuthActions.loadAccessToken());
  };

  const parseJw = (token: any) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  if (token && token !== 'otp_await') {
    if (loginInfo) {
      window.localStorage.setItem('username', loginInfo.username);
    }
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('role', parseJw(token).role);
    return <Redirect to={RouterLinks.APP} />;
  }

  return (
    <div id='OTPPage'>
      <div id='LP__Left'>
        <div>
          <img src={logo} alt='logo' />
        </div>
        <Spin spinning={requestState === 0} size='large' style={{ position: 'static' }}>
          <Form
            form={form}
            layout='vertical'
            initialValues={otp}
            onValuesChange={handleValuesChange}
          >
            <div className='Container'>
              <div className='FormItem'>
                <Form.Item name='otp_value' label='OTP'>
                  <Input />
                </Form.Item>
              </div>
            </div>

            <div className='Container'>
              <Form.Item style={{ marginBottom: 0 }} shouldUpdate>
                {() => (
                  <Button
                    type='primary'
                    className={checkValidateForm() ? 'Button' : 'Button Disabled'}
                    htmlType='submit'
                    disabled={!checkValidateForm()}
                    onClick={handleOTP}
                  >
                    Send
                  </Button>
                )}
              </Form.Item>
            </div>
            <div className='Container'>
              <div className='Forgot' onClick={handleResend}>
                Resend
              </div>
            </div>
          </Form>
        </Spin>
      </div>
      <div id='LP__Right'>
        <img src={wc} alt='mural' />
      </div>
    </div>
  );
};
