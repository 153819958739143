import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';
import { useActions } from '../../redux';
import { RouterLinks, parseJw, AuthConfigs, createQueryString } from '../../const';
import wc from '../../assets/ocr.png';
import logo from '../../assets/logo.png';

const loginMethod = AuthConfigs.login_method;

export const LoginPage = (props: any) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const actions = useActions();

  const requestState = useSelector((state: any) => state.request.request_state);
  const loginInfo = useSelector((state: any) => state.auth.login_info);
  const token = useSelector((state: any) => state.auth.token);
  const mfaUserInfo = useSelector((state: any) => state.auth.mfa_user_info);

  useEffect(() => {
    let code_url = /((\?|&)code=)[^&]+/.exec(props.location.search);
    if (code_url !== null) {
      let code = decodeURIComponent(String(code_url[0]).replace(/(\?|&)?code=/, ''));
      if (code) tokenExchange(code);
    }
  }, []);

  const tokenExchange = (code: any) => {
    const data = {
      code,
      redirect_uri: AuthConfigs.redirect_uri,
      scope: AuthConfigs.scope,
    };
    dispatch(actions.AuthActions.loadExchangeAccessToken(data));
  };

  // const SsoLogin = (code: any) => {
  //   const data = {
  //     code,
  //     redirect_uri: AuthConfigs.redirect_uri,
  //     scope: AuthConfigs.scope,
  //   };
  //   dispatch(actions.AuthActions.ssoLogin(data));
  // };

  const handleValuesChange = () => {
    dispatch(actions.AuthActions.updateLoginInfo(form.getFieldsValue()));
  };

  const checkValidateForm = () => {
    const { username, password } = loginInfo;
    if (username === '' || password === '') return false;
    return true;
  };

  const handleLogin = () => {
    dispatch(actions.AuthActions.loadAccessToken());
  };

  const handleLoginLDAP = () => {
    const url = `https://login.microsoftonline.com/${AuthConfigs.tenant_id}/oauth2/v2.0/authorize`;
    const query = {
      client_id: AuthConfigs.client_id,
      redirect_uri: AuthConfigs.redirect_uri,
      response_type: AuthConfigs.response_type,
      response_mode: AuthConfigs.response_mode,
      scope: AuthConfigs.scope,
      state: AuthConfigs.state,
    };
    const AuthUrl = `${url}?${createQueryString(query)}`;
    window.location.href = AuthUrl;
  };

  if (token === 'otp_await') return <Redirect to={RouterLinks.OTP_PAGE} />;
  else if (token) {
    window.localStorage.setItem('username', parseJw(token).username);
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('role', parseJw(token).role);
    if (mfaUserInfo) window.localStorage.setItem('mfaUserInfo', JSON.stringify(mfaUserInfo));
    
    window.location.href = RouterLinks.APP;
    return null
    // return <Redirect to={RouterLinks.APP} />;
  }

  return (
    <div id='LoginPage'>
      <div id='LP__Left'>
        <div>
          <img src={logo} alt='logo' style={{ maxHeight: '70px' }} />
        </div>
        <Spin spinning={requestState === 0} size='large' style={{ position: 'static' }}>
          <Form
            form={form}
            layout='vertical'
            initialValues={loginInfo}
            onValuesChange={handleValuesChange}
          >
            {(loginMethod === 'both' || loginMethod === 'account') && (
              <>
                <div className='Container'>
                  <div className='FormItem'>
                    <Form.Item name='username' label='Username'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='Container'>
                  <div className='FormItem'>
                    <Form.Item name='password' label='Password'>
                      <Input type='password' />
                    </Form.Item>
                  </div>
                </div>
                <div className='Container'>
                  <Form.Item style={{ marginBottom: 0 }} shouldUpdate>
                    {() => (
                      <Button
                        type='primary'
                        className={checkValidateForm() ? 'Button' : 'Button Disabled'}
                        htmlType='submit'
                        disabled={!checkValidateForm()}
                        onClick={handleLogin}
                      >
                        Log in
                      </Button>
                    )}
                  </Form.Item>
                </div>
              </>
            )}
            {(loginMethod === 'both' || loginMethod === 'mfa') && (
              <div className='Container' style={{ marginTop: '10px' }}>
                <Button
                  type='default'
                  className='Button'
                  onClick={(e) => {
                    e.preventDefault();
                    handleLoginLDAP();
                  }}
                >
                  Log in via MFA
                </Button>
              </div>
            )}
          </Form>
        </Spin>
      </div>
      <div id='LP__Right'>
        <img src={wc} alt='mural' />
      </div>
    </div>
  );
};
