import moment from "moment";
import actions from "./actions";
import { DATE_RANGE_FORMAT } from "../../common";
import { SignatureCompareFilterInterface } from "../../interfaces/Sign.interfaces";

const default_filter: SignatureCompareFilterInterface = {
  search_text: "",
  search_type: 1,
  from_time: moment().subtract(7, "days").format(DATE_RANGE_FORMAT),
  to_time: moment().format(DATE_RANGE_FORMAT),
};

const initState = {
  current_filter: default_filter,
  data: null,

  page_number: 1,
  page_size: 10,
  total_record: 0,
};

const SignatureCompareReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case actions.types.RESET:
      return initState;
    case actions.types.UPDATE_FILTER:
      return {
        ...state,
        ...{
          current_filter: action.payload.filter,
        },
      };
    case actions.types.UPDATE_PAGE_NUMBER:
      return {
        ...state,
        ...{
          page_number: action.payload.page_number,
        },
      };
    case actions.types.UPDATE_PAGE_SIZE:
      return {
        ...state,
        ...{
          page_size: action.payload.page_size,
        },
      };

    case actions.types.LOAD_DATA:
      return {
        ...state,
      };
    case actions.types.LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.data,
          total_record: action.payload.paging.total_record,
        },
      };

    default:
      return state;
  }
};

export default SignatureCompareReducer;
