export const DailyStatComponent = (props: any) => {
  const { dailyStat } = props;

  var countPending = 'NaN',
    countSuccess = 'NaN',
    countFailed = 'NaN',
    countProcessing = 'NaN';

  if (dailyStat) {
    countPending = dailyStat.countPending;
    countSuccess = dailyStat.countSuccess;
    countFailed = dailyStat.countFailed;
    countProcessing = dailyStat.countProcessing;
  }

  return (
    <div className='DailyStat'>
      <div className='StatCard Wait'>
        <div>Yêu cầu đang chờ xử lý</div>
        <div className='StatNumber'>{countPending}</div>
      </div>
      <div className='StatCard Progress'>
        <div>Yêu cầu đang xử lý</div>
        <div className='StatNumber'>{countProcessing}</div>
      </div>
      <div className='StatCard Success'>
        <div>Yêu cầu xử lý thành công</div>
        <div className='StatNumber'>{countSuccess}</div>
      </div>
      <div className='StatCard Fail'>
        <div>Yêu cầu xử lý thất bại</div>
        <div className='StatNumber'>{countFailed}</div>
      </div>
    </div>
  );
};
