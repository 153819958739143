import createApiServices from "../createApiServices";

const api = createApiServices();

const GetRequestMetric = (filter: any) => {
  const { from_time, to_time, clients, doc_types } = filter;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/report/request?from_time=${from_time}&to_time=${to_time}`,
    method: "POST",
    data: {
      client_id: clients,
      doc_types,
    },
  });
};

const GetDocumentMetric = (filter: any) => {
  const { from_time, to_time, clients, doc_types } = filter;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/report/document?from_time=${from_time}&to_time=${to_time}`,
    method: "POST",
    data: {
      client_id: clients,
      doc_types,
    },
  });
};
const GetEvaluateMetric = (filter: any) => {
  const { from_time, to_time, clients, doc_types } = filter;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/report/evaluate?from_time=${from_time}&to_time=${to_time}`,
    method: "POST",
    data: {
      client_id: clients,
      doc_types,
    },
  });
};

const DownloadData = (filter: any) => {
  const { from_time, to_time, clients } = filter;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/report/download?from_time=${from_time}&to_time=${to_time}`,
    method: "POST",
    responseType: "blob",
    data: {
      client_id: clients,
    },
  });
};

const GetDocTypes = () => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/report/get-document`,
    method: "POST",
  });
};

export const Report = {
  GetDocumentMetric,
  GetRequestMetric,
  DownloadData,
  GetEvaluateMetric,
  GetDocTypes,
};
