import actions from "./actions";

const default_filter = {
  search: "",
  search_type: 1,
  status: "PENDING",
  sortByPriority: "DESC",
};

const default_request = {
  client_id: "",
  request_id: "",
  time: "",
  doc_code: "",
  status: "",
  page: "",
};

const initState = {
  current_filter: default_filter,
  data: null,
  dashboad: null,

  page_number: 1,
  page_size: 10,
  total_record: 0,

  current_request: default_request,
  detail_data: null,
};

const DailyReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case actions.types.RESET:
      return initState;
    case actions.types.UPDATE_FILTER:
      return {
        ...state,
        ...{
          current_filter: action.payload.filter,
        },
      };

    case actions.types.UPDATE_PAGE_NUMBER:
      return {
        ...state,
        ...{
          page_number: action.payload.page_number,
        },
      };
    case actions.types.UPDATE_PAGE_SIZE:
      return {
        ...state,
        ...{
          page_size: action.payload.page_size,
        },
      };

    case actions.types.LOAD_DASHBOARD:
      return {
        ...state,
      };
    case actions.types.LOAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...{
          dashboard: action.payload.data,
        },
      };

    case actions.types.LOAD_DATA:
      return {
        ...state,
      };
    case actions.types.LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.data,
          total_record: action.payload.total,
        },
      };

    case actions.types.UPDATE_PRIORITY:
      return {
        ...state,
      };
    case actions.types.RETRY_REQUEST:
      return {
        ...state,
      };

    case actions.types.UPDATE_CURRENT_REQUEST:
      return {
        ...state,
        ...{
          current_request: action.payload.request,
        },
      };
    case actions.types.LOAD_DETAIL_DATA:
      return {
        ...state,
      };
    case actions.types.LOAD_DETAIL_DATA_SUCCESS:
      return {
        ...state,
        ...{
          detail_data: action.payload.data,
        },
      };

    default:
      return state;
  }
};

export default DailyReducer;
