import { Form, Input, Switch } from "antd";

import { ModalComponent } from "../../../components";

export const DocumentModalComponent = (props: any) => {
  const {
    modalMode,
    docType,
    desc,
    isOcr,
    hasSignature,
    onSave,
    onCancel,
    onChangeDocType,
    onChangeDesc,
    onChangeIsOcr,
    onChangeHasSignature,
  } = props;

  const _renderModal = () => {
    return (
      <div className="ClientAppModal">
        <Form layout="vertical">
          <div className="Container">
            <div className="FormItem">
              <Form.Item label="Loại chứng từ">
                <Input
                  value={docType}
                  maxLength={500}
                  onChange={(e: any) => onChangeDocType(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="Container">
              <div className="FormItem">
                <Form.Item label="Cần OCR">
                  <Switch checked={isOcr} onChange={onChangeIsOcr} />
                </Form.Item>
              </div>
            </div>

            <div className="Container">
              <div className="FormItem">
                <Form.Item label="Nhận dạng chữ ký">
                  <Switch
                    checked={hasSignature}
                    onChange={onChangeHasSignature}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="Container">
            <div className="FormItem">
              <Form.Item label="Mô tả chứng từ">
                <Input.TextArea
                  rows={3}
                  maxLength={500}
                  value={desc}
                  onChange={(e: any) => onChangeDesc(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      <ModalComponent
        title={
          modalMode === "update"
            ? "Cập nhật mô tả chứng từ"
            : "Tạo mô tả chứng từ"
        }
        isModalVisible={modalMode}
        onOk={onSave}
        onCancel={onCancel}
        cancelText="Huỷ"
        okText="Lưu"
      >
        {_renderModal()}
      </ModalComponent>
    </>
  );
};
