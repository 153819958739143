import actions from './actions';

export const default_login_info = {
  username: '',
  password: '',
};

export const initState = {
  login_info: default_login_info,
  otp: {
    otp_value: '',
  },
  token: '',
  mfa_user_info: null,
};

const AuthReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case actions.types.UPDATE_LOGIN_INFO:
      return {
        ...state,
        ...{
          login_info: action.payload.loginInfo,
        },
      };
    case actions.types.LOAD_ACCESS_TOKEN:
      return {
        ...state,
      };
    case actions.types.LOAD_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        ...{
          token: action.payload.token,
        },
      };

    case actions.types.UPDATE_OTP:
      return {
        ...state,
        ...{
          otp: action.payload.otp,
        },
      };
    case actions.types.VERIFY_OTP:
      return {
        ...state,
      };
    case actions.types.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        ...{
          token: action.payload.token,
        },
      };

    case actions.types.LOG_OUT:
      return initState;

    case actions.types.SSO_LOGIN:
      return {
        ...state,
      };
    case actions.types.LOAD_EXCHANGE_ACCESS_TOKEN:
      return {
        ...state,
      };
    case actions.types.LOAD_EXCHANGE_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        ...{
          token: action.payload.token,
          mfa_user_info: action.payload.mfa_user_info,
        },
      };

    default:
      return state;
  }
};

export default AuthReducer;
