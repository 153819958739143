import { all, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIServices } from '../../utils';
import { NotificationService } from '../../components';
import reqActions from '../request/actions';
import actions from './actions';

function* saga_LoadAccessToken() {
  try {
    let _loginInfo: Promise<any> = yield select((state: any) => state.auth.login_info);
    let loginInfo = _loginInfo;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Auth.GetAccessToken(loginInfo);
    let response: any = _response;

    if (response.errorCode && response.errorCode === 1) {
      yield put(actions.action.loadAccessTokenSuccess('otp_await'));
      NotificationService.success(response.data ? response.data : 'Đã gửi mã OTP');
    }
    if (response.access_token) {
      yield put(actions.action.loadAccessTokenSuccess(response.access_token));
    }
    yield put(reqActions.action.updateRequestState(1));
  } catch (ex: any) {
    console.error('saga_LoadAccessToken');
    NotificationService.error('Đăng nhập thất bại');
    yield put(reqActions.action.updateRequestState(-1));
  }
}

function* saga_VerifyOTP() {
  try {
    let _loginInfo: Promise<any> = yield select((state: any) => state.auth.login_info);
    let loginInfo = _loginInfo;

    let _otp: Promise<any> = yield select((state: any) => state.auth.otp);
    let otp = _otp;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Auth.VerifyOTP(otp, loginInfo);
    let response: any = _response;

    if (response.access_token) {
      yield put(actions.action.verifyOTPSuccess(response.access_token));
      window.localStorage.setItem('token', response.access_token);
    }
    yield put(reqActions.action.updateRequestState(1));
  } catch (ex: any) {
    console.error('saga_VerifyOTP');
    NotificationService.error('Xác thực OTP thất bại');
    yield put(reqActions.action.updateRequestState(-1));
  }
}

function* saga_SsoLogin(action: any) {
  try {
    let { data } = action.payload;
    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Auth.SsoLogin(data);
    let response: any = _response;

    if (response.accessToken && response.refreshToken) {
      const { accessToken, refreshToken } = response;
      let _response_info: Promise<any> = yield APIServices.Auth.GetInfo(accessToken);
      let response_info: any = _response_info;

      if (response_info && response_info.data) {
        const { username } = response_info.data;

        yield put(
          actions.action.loadExchangeAccessToken({
            refreshToken,
            email: username,
          })
        );
      }
    }

    yield put(reqActions.action.updateRequestState(1));
  } catch (ex: any) {
    console.error('saga_SsoLogin');
    NotificationService.error('Đăng nhập SSO thất bại');
    yield put(reqActions.action.updateRequestState(-1));
  }
}

function* saga_LoadExchangeAccessToken(action: any) {
  try {
    let { data } = action.payload;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Auth.GetExchangeAccessToken(data);
    let response: any = _response;

    if (response.access_token) {
      yield put(
        actions.action.loadExchangeAccessTokenSuccess(response.access_token, response.info)
      );
    }

    yield put(reqActions.action.updateRequestState(1));
  } catch (ex: any) {
    console.error('saga_LoadExchangeAccessToken');
    NotificationService.error('Đăng nhập Azure thất bại');
    yield put(reqActions.action.updateRequestState(-1));
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_ACCESS_TOKEN, saga_LoadAccessToken);
  yield takeEvery(actions.types.VERIFY_OTP, saga_VerifyOTP);
  yield takeEvery(actions.types.SSO_LOGIN, saga_SsoLogin);
  yield takeEvery(actions.types.LOAD_EXCHANGE_ACCESS_TOKEN, saga_LoadExchangeAccessToken);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
