const PaymentOrderA3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    transaction_number: "Số giao dịch",
    sender_name: "Tên người nộp tiền",
    sender_account: "Tài khoản người nộp tiền",
    receiver_name: "Tên người thụ hưởng",
    receiver_id_number: "Số giấy tờ tuỳ thân người thụ hưởng",
    receiver_account: "Tài khoản người người thụ hưởng",
    amount: "Số tiền bằng số",
  })
);

const DepositSlipA3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    date: "Ngày tài liệu",
    transaction_number: "Số giao dịch",
    sender_name: "Tên người nộp tiền",
    sender_id_number: "Số giấy tờ tuỳ thân người nộp tiền",
    receiver_name: "Tên người thụ hưởng",
    receiver_id_number: "Số giấy tờ tuỳ thân người thụ hưởng",
    receiver_account: "Tài khoản người người thụ hưởng",
    amount: "Số tiền",
  })
);

const GIAY_LINH_TIEN_TK_A3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    date: "Ngày tài liệu",
    transaction_number: "Số giao dịch",
    account_number: "Số tài khoản",
    amount: "Số tiền",
    capital: "Vốn",
  })
);

const GDN_TAT_TOAN_TTK_A3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    date: "Ngày tài liệu",
    account_number: "Số tài khoản",
    amount: "Số tiền",
  })
);

const WITHDRAWAL_SLIP_A3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    date: "Ngày tài liệu",
    transaction_number: "Số giao dịch",
    account_number: "Số tài khoản",
    amount: "Số tiền",
  })
);

const PHIEU_DIEU_CHINH_LAI_A3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    date: "Ngày tài liệu",
    transaction_number: "Số giao dịch",
    account_number: "Số tài khoản",
    amount: "Số tiền",
  })
);

const GDN_DONG_TK_A3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    account_name: "Tên tài khoản",
    account_number: "Số tài khoản",
  })
);

const SO_TK_A3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    serial_number: "Số seri",
    account_number: "Số tài khoản",
    amount: "Số tiền",
  })
);

const CHEQUE_A3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    date: "Ngày tài liệu",
    serial_number: "Số seri",
    account_number: "Số tài khoản",
    amount: "Số tiền",
    stamp: "Con dấu",
  })
);

const GDN_THUC_HIEN_GD_A3A4Map = new Map(
  Object.entries({
    type: "Tên loại chứng từ",
    transaction_code: "Mã giao dịch",
    transaction_number: "Số giao dịch",
    customer_name: "Tên khách hàng",
    id_number: "Số giấy tờ tuỳ thân",
  })
);

const CustomerSignatureMap = new Map(
  Object.entries({
    signature: "Chữ ký",
    full_signature: "Chữ ký kèm họ và tên",
  })
);

const TellerSignatureMap = new Map(
  Object.entries({
    full_signature: "Teller - Chữ ký kèm họ và tên",
  })
);

const getMap = (type: any, data_field: any = "") => {
  if (type === "PAYMENT_ORDER") {
    if (data_field === "payment_order") return PaymentOrderA3A4Map;
    if (data_field === "customer_signature") return CustomerSignatureMap;
    if (data_field === "teller_signature") return TellerSignatureMap;
  }
  if (type === "DEPOSIT_SLIP") {
    if (data_field === "deposit_slip") return DepositSlipA3A4Map;
    if (data_field === "customer_signature") return CustomerSignatureMap;
    if (data_field === "teller_signature") return TellerSignatureMap;
  }
  if (type === "GIAY_LINH_TIEN_TK") {
    if (data_field === "info") return GIAY_LINH_TIEN_TK_A3A4Map;
    if (data_field === "customer_signature") return CustomerSignatureMap;
  }
  if (type === "GDN_TAT_TOAN_TTK") {
    if (data_field === "info") return GDN_TAT_TOAN_TTK_A3A4Map;
    if (data_field === "customer_signature") return CustomerSignatureMap;
  }
  if (type === "WITHDRAWAL_SLIP") {
    if (data_field === "info") return WITHDRAWAL_SLIP_A3A4Map;
    if (data_field === "customer_signature") return CustomerSignatureMap;
  }
  if (type === "PHIEU_DIEU_CHINH_LAI") {
    if (data_field === "info") return PHIEU_DIEU_CHINH_LAI_A3A4Map;
  }
  if (type === "GDN_DONG_TK") {
    if (data_field === "info") return GDN_DONG_TK_A3A4Map;
    if (data_field === "customer_signature") return CustomerSignatureMap;
  }
  if (type === "SO_TK") {
    if (data_field === "info") return SO_TK_A3A4Map;
  }
  if (type === "CHEQUE") {
    if (data_field === "info") return CHEQUE_A3A4Map;
    if (data_field === "customer_signature") return CustomerSignatureMap;
  }
  if (type === "GDN_THUC_HIEN_GD") {
    if (data_field === "info") return GDN_THUC_HIEN_GD_A3A4Map;
    if (data_field === "customer_signature") return CustomerSignatureMap;
  }
};

const CAPEX_SPECIAL_KEY_HAS_IMAGES = ["signature", "full_signature", "stamp"];

export const A3A4Mapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => A3A4ItemMapping(document));
};

export const A3A4ItemMapping = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.document_code,
    score: isNaN(document.document_code_score)
      ? document.document_code_score
      : document.document_code_score === -1
      ? 0
      : Math.min(
          Number.parseFloat(document.document_code_score) * 100,
          99
        ).toFixed(0),
  });

  if (document.ocr_data && typeof document.ocr_data === "object") {
    Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
      const ocr_data = document.ocr_data[`${data_field}`];
      const data_map = getMap(document.document_type, data_field);
      if (ocr_data && ocr_data.length > 0 && data_map) {
        ocr_data.forEach((ocr_item: any, index: any) => {
          if (
            ocr_item &&
            ocr_item instanceof Object &&
            Object.keys(ocr_item).length > 0
          ) {
            Array.from(data_map.keys()).forEach((item: any, _: any) => {
              if (ocr_item[item]) {
                if (
                  Array.isArray(ocr_item[item]) &&
                  ocr_item[item].length > 1
                ) {
                  ocr_item[item].forEach((field: any, indexField: any) => {
                    const hasImage = CAPEX_SPECIAL_KEY_HAS_IMAGES.includes(item)
                      ? true
                      : false;
                    ocrData.push({
                      attribute:
                        indexField === 0
                          ? data_map.get(item)
                          : `${data_map.get(item)} ${indexField + 1}`,
                      coords: field.coordinates,
                      value: field.value,
                      id: field.id,
                      image_index: field.image_index,
                      hasImage: hasImage,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(
                            Number.parseFloat(field.score) * 100,
                            99
                          ).toFixed(0),
                    });
                  });
                } else {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];

                  const hasImage = CAPEX_SPECIAL_KEY_HAS_IMAGES.includes(item)
                    ? true
                    : false;
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: field.coordinates,
                    value: field.value,
                    id: field.id,
                    image_index: field.image_index,
                    hasImage: hasImage,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                }
              } else
                ocrData.push({
                  attribute:
                    index === 0
                      ? data_map.get(item)
                      : `${data_map.get(item)} ${index + 1}`,
                  coords: null,
                  id: null,
                  image_index: null,
                  value: null,
                  value_update: null,
                  certain: null,
                });
            });
          }
        });
      }
    });
  }

  return {
    type: document.document_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};

export const A3A4DetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  return ocr.map((document: any, _: any) => A3A4ItemDetail(document));
};

export const A3A4ItemDetail = (document: any) => {
  let ocrData = [] as any;
  ocrData.push({
    attribute: "Mã hồ sơ",
    id: null,
    coords: null,
    value: document.doc_code,
    score: isNaN(document.doc_score)
      ? document.doc_score
      : document.doc_score === -1
      ? 0
      : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
  });

  if (document.ocr_data && typeof document.ocr_data === "object") {
    const ocr_update = document.ocr_update ? document.ocr_update : null;
    Object.keys(document.ocr_data).forEach((data_field: any, _: any) => {
      const ocr_data = document.ocr_data[`${data_field}`];
      const data_map = getMap(document.doc_type, data_field);
      if (ocr_data && ocr_data.length > 0 && data_map) {
        ocr_data.forEach((ocr_item: any, index: any) => {
          if (
            ocr_item &&
            ocr_item instanceof Object &&
            Object.keys(ocr_item).length > 0
          ) {
            Array.from(data_map.keys()).forEach((item: any, _: any) => {
              if (ocr_item[item]) {
                if (
                  Array.isArray(ocr_item[item]) &&
                  ocr_item[item].length > 1
                ) {
                  ocr_item[item].forEach((field: any, indexField: any) => {
                    const hasImage = CAPEX_SPECIAL_KEY_HAS_IMAGES.includes(item)
                      ? true
                      : false;
                    ocrData.push({
                      attribute:
                        indexField === 0
                          ? data_map.get(item)
                          : `${data_map.get(item)} ${indexField + 1}`,
                      coords: field.coordinates,
                      value: field.value,
                      value_update: ocr_update && ocr_update[field.id],
                      id: field.id,
                      image_index: field.image_index,
                      hasImage: hasImage,
                      score: isNaN(field.score)
                        ? field.score
                        : field.score === -1
                        ? 0
                        : Math.min(
                            Number.parseFloat(field.score) * 100,
                            99
                          ).toFixed(0),
                    });
                  });
                } else {
                  let field = Array.isArray(ocr_item[item])
                    ? ocr_item[item][0]
                    : ocr_item[item];

                  const hasImage = CAPEX_SPECIAL_KEY_HAS_IMAGES.includes(item)
                    ? true
                    : false;
                  ocrData.push({
                    attribute:
                      index === 0
                        ? data_map.get(item)
                        : `${data_map.get(item)} ${index + 1}`,
                    coords: field.coordinates,
                    value: field.value,
                    value_update: ocr_update && ocr_update[field.id],
                    id: field.id,
                    image_index: field.image_index,
                    hasImage: hasImage,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(
                          Number.parseFloat(field.score) * 100,
                          99
                        ).toFixed(0),
                  });
                }
              } else
                ocrData.push({
                  attribute:
                    index === 0
                      ? data_map.get(item)
                      : `${data_map.get(item)} ${index + 1}`,
                  coords: null,
                  id: null,
                  image_index: null,
                  value: null,
                  value_update: null,
                  certain: null,
                });
            });
          }
        });
      }
    });
  }

  return {
    type: document.doc_type,
    document_id: document.document_id,
    file_id: document.file_id,
    ocrData,
  };
};
