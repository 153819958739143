import { Button, Spin, Upload } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { RiAddCircleLine, RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { NotificationService, PaginationComponent } from "../../components";
import { showConfirm } from "../../components/modal";
import { error } from "../../components/notification";
import { useActions } from "../../redux";
import { APIServices, renderDateTime } from "../../utils";
import { UserModalComponent } from "./component";
import LoadFile from "./component/load_file";
import UserFilter from "./component/UserFilter";

const user_name = localStorage.getItem("username");
var role = localStorage.getItem("role");
const options = [
  { label: "Tên người dùng", value: "username" },
  { label: "Phân quyền", value: "role" },
  { label: "Nhóm người dùng", value: "groupId" },
];

export const UserPage = () => {
  const dispatch = useDispatch();
  const actions = useActions();
  const [itemShow, setItemShow] = useState<any>(null);

  const requestState = useSelector((state: any) => state.request.request_state);
  let data = useSelector((state: any) => state.user.data);
  let roleData = useSelector((state: any) => state.user.role_data);
  let currentItem = useSelector((state: any) => state.user.current_item);
  let userGroupData = useSelector((state: any) => state.user_group.data);

  let totalRecord = useSelector((state: any) => state.user.total_record);
  let pageNumber = useSelector((state: any) => state.user.page_number);
  let pageSize = useSelector((state: any) => state.user.page_size);

  useEffect(() => {
    dispatch(actions.UserActions.loadData());
    dispatch(actions.UserActions.loadRole());
    dispatch(actions.UserGroupActions.loadData(true));
  }, [actions.UserActions, actions.UserGroupActions, dispatch]);

  const handleCreate = () => {
    dispatch(
      actions.UserActions.updateCurrentItem({
        id: "",
        username: "",
        password: "",
        role: "",
        groups: [],
      })
    );
    setItemShow("");
  };

  const handleUpdate = (item: any) => {
    dispatch(
      actions.UserActions.updateCurrentItem({
        id: item.id.toString(),
        username: item.username,
        password: item.password,
        role: item.role,
        groups: item.groups.map((group: any, _: any) => {
          return group.id.toString();
        }),
      })
    );
    setItemShow(item.id.toString());
  };

  const handleDelete = (user_id: any) => {
    showConfirm({
      title: "Chắc chắn xoá User này?",
      content: "",
      onOk: () => {
        dispatch(actions.UserActions.deleteCurrentItem(user_id));
      },
      onCancel: () => {},
    });
  };

  const handleChangeCurrentItem = (data: any) => {
    dispatch(actions.UserActions.updateCurrentItem(data));
  };

  const handleCancel = () => {
    dispatch(actions.UserActions.updateCurrentItem(null));
    setItemShow(null);
  };

  const onSearch = (searchText: string, searchType: string) => {
    dispatch(
      actions.UserActions.updateFilter({
        [searchType]: searchText,
      })
    );
  };
  const handleChangePageNumber = (page_number: any) => {
    dispatch(actions.UserActions.updatePageNumber(page_number));
  };

  const handleChangePageSize = (page_size: any) => {
    dispatch(actions.UserActions.updatePageSize(page_size));
  };

  const handleSave = () => {
    const { username, role, groups } = currentItem;
    if (
      username === "" ||
      role === "" ||
      (role !== "ADMIN_OCR" && (!groups || groups.length === 0))
    )
      return error("Vui lòng nhập đầy đủ thông tin");
    else {
      dispatch(actions.UserActions.saveCurrentItem());
      dispatch(actions.UserActions.updateCurrentItem(null));
      setItemShow(null);
    }
  };

  var userGroupList = [];
  if (userGroupData && userGroupData.length > 0)
    userGroupList = userGroupData.map((item: any, _: any) => {
      return {
        value: item.id.toString(),
        label: item.name,
      };
    });

  var userRoleList: any[] = [];
  if (roleData && roleData.length > 0)
    roleData.forEach((item: any, _: any) => {
      if (role === "OPERATOR_OCR" && item === "ADMIN_OCR") return;
      userRoleList.push({
        value: item,
        label: item,
      });
    });

  async function sendUserFile(file: any) {
    let fileContent;
    try {
      dispatch(actions.RequestActions.updateRequestState(0));
      fileContent = await APIServices.User.PushUserFile(file);
      dispatch(actions.RequestActions.updateRequestState(1));
      const { data } = fileContent;
      let count = 0;
      let response = "User import thất bại:\n";
      if (data && data.length > 0) {
        data.forEach((item: any, index: any) => {
          if (item.error !== "") {
            count++;
            response += `- User ${index + 1}: ${item.error}\n`;
          }
        });
      }
      if (count === 0)
        NotificationService.success("Import tất cả user thành công");
      else NotificationService.error(response, count * 2000);
      dispatch(actions.UserActions.loadData());
    } catch (err) {
      dispatch(actions.RequestActions.updateRequestState(-1));
      NotificationService.error("Nhập file User thất bại");
      return null;
    }
  }

  const props: any = {
    beforeUpload: (file: any) => {
      sendUserFile(file);
      return false;
    },
  };

  if (!role || role === "") role = localStorage.getItem("role");

  return (
    <div className="AppPage">
      <div className="PageTitle">User Management</div>
      <div className="head-section">
        <UserFilter
          onSearch={onSearch}
          options={options}
          userGroupList={userGroupList}
        />
        <div className="CreateButtonContainer">
          <div
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "5px",
              display: "flex",
            }}
          >
            <Button
              type="default"
              //href='https://vngms-my.sharepoint.com/:x:/r/personal/longhd2_vng_com_vn/_layouts/15/Doc.aspx?sourcedoc=%7BE6252726-37E9-4DF4-A3D2-4B0B8F8D9EAA%7D&file=Book.xlsx&action=default&mobileredirect=true&DefaultItemOpen=1&web=1'
            >
              <LoadFile />
            </Button>
            <Upload showUploadList={false} {...props}>
              <Button type="primary">
                <AiOutlineUsergroupAdd /> Import User
              </Button>
            </Upload>
          </div>
          {(role === "ADMIN_OCR" || role === "OPERATOR_OCR") && (
            <Button type="primary" onClick={handleCreate}>
              <RiAddCircleLine /> Tạo User
            </Button>
          )}
        </div>
      </div>

      <Spin
        spinning={requestState === 0}
        size="large"
        style={{ position: "static" }}
      >
        <div className="TableContainer">
          <table className="table table-striped sticky-header">
            <thead>
              <tr>
                <th>Tên người dùng</th>
                <th>Phân quyền</th>
                <th>Nhóm người dùng</th>
                <th>Thời gian tạo</th>
                <th>Tạo bởi</th>
                <th>Thời gian cập nhật</th>
                <th>Cập nhật bởi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item: any, index: any) => {
                  return (
                    <tr key={`row_${index}`}>
                      <td>{item.username}</td>
                      <td>{item.role}</td>
                      <td>
                        {item.groups &&
                          item.groups.map((group: any, group_index: any) => {
                            return (
                              <div key={`label_${group_index}`}>
                                {group.name}
                              </div>
                            );
                          })}
                      </td>
                      <td>{renderDateTime(item.createdAt)}</td>
                      <td>{item.createdUser}</td>
                      <td>{renderDateTime(item.updatedAt)}</td>
                      <td>{item.updatedUser}</td>
                      <td>
                        <div className="ButtonContainer">
                          <Button
                            type="default"
                            className="SearchButton"
                            onClick={() => handleUpdate(item)}
                          >
                            <RiEditLine />
                            Cập nhật
                          </Button>
                          {user_name !== item.username &&
                            role !== "OPERATOR_OCR" && (
                              <Button
                                type="primary"
                                className="ResetButton"
                                onClick={() => handleDelete(item.id)}
                                danger
                              >
                                <RiDeleteBin6Line /> Xoá
                              </Button>
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {(!data || data.length === 0) && (
                <tr>
                  <td colSpan={8}>Không có dữ liệu</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <UserModalComponent
          modalStatus={currentItem && itemShow === currentItem.id}
          currentItem={currentItem}
          userGroupList={userGroupList}
          userRoleList={userRoleList}
          onCancel={handleCancel}
          onSave={handleSave}
          onChangeCurrentItem={handleChangeCurrentItem}
        />
        <div className="client-pagination">
          {totalRecord > 0 && (
            <div className="QuantityContainer">
              Số lượng Users: {totalRecord}
            </div>
          )}

          <PaginationComponent
            totalRecord={totalRecord}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onChangePageNumber={handleChangePageNumber}
            onChangePageSize={handleChangePageSize}
          />
        </div>
      </Spin>
    </div>
  );
};
