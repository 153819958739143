const SCMap = new Map(
  Object.entries({
    contract_no: 'Số hợp đồng',
    contract_date: 'Ngày hợp đồng',
    seller_name: 'Tên người bán',
    seller_address: 'Địa chỉ người bán',
    seller_address_city: 'Địa chỉ người bán (Tỉnh/Thành phố)',
    seller_address_nation: 'Địa chỉ người bán (Quốc gia)',
    buyer_name: 'Tên người mua',
    buyer_address: 'Địa chỉ người mua',
    buyer_address_city: 'Địa chỉ người mua (Tỉnh/Thành phố)',
    buyer_address_nation: 'Địa chỉ người mua (Quốc gia)',
    total_money: 'Số tiền',
    currency: 'Ngoại tệ',
    loading_port: 'Nơi đi',
    discharge_port: 'Nơi đến',
    payment_term: 'Phương thức thanh toán',
    receiver_bank_swift: 'Mã ngân hàng người hưởng',
    receiver_name: 'Tên người thụ hưởng',
    receiver_address: 'Địa chỉ người thụ hưởng',
    receiver_address_city: 'Địa chỉ người thụ hưởng (Tỉnh/Thành phố)',
    receiver_address_nation: 'Địa chỉ người thụ hưởng (Quốc gia)',
    receiver_account_number: 'Số tài khoản/ số IBAN người hưởng',
    producer: 'Nhà sản xuất',
    consignor: 'Người gửi hàng',
    receiver_bank_name: 'Tên ngân hàng người thụ hưởng',
    delivery_time: 'Thời gian giao hàng',
  })
);

const ForeignInvoiceMap = new Map(
  Object.entries({
    invoice_no: 'Số hóa đơn',
    invoice_date: 'Ngày hóa đơn',
    seller_name: 'Tên Người phát hành hóa đơn',
    seller_address: 'Địa chỉ người phát hành hóa đơn',
    seller_address_city: 'Địa chỉ người phát hành hóa đơn (Tỉnh/Thành phố)',
    seller_address_nation: 'Địa chỉ người phát hành hóa đơn (Quốc gia)',
    buyer_name: 'Tên Người nhận hóa đơn',
    buyer_address: 'Địa chỉ người nhận hóa đơn',
    buyer_address_city: 'Địa chỉ người nhận hóa đơn (Tỉnh/Thành phố)',
    buyer_address_nation: 'Địa chỉ người nhận hóa đơn (Quốc gia)',
    total_money: 'Số tiền',
    currency: 'Ngoại tệ',
    receiver_bank_swift: 'Mã ngân hàng người hưởng',
    receiver_name: 'Tên người thụ hưởng',
    receiver_address: 'Địa chỉ người thụ hưởng',
    receiver_account_number: 'Số tài khoản/ số IBAN người hưởng',
  })
);

const TTAMap = new Map(
  Object.entries({
    sender_name: 'Tên Người chuyển tiền',
    sender_address: 'Địa chỉ người chuyển tiền',
    sender_address_city: 'Địa chỉ người chuyển tiền (Tỉnh/Thành phố)',
    sender_address_nation: 'Địa chỉ người chuyển tiền (Quốc gia)',
    currency: 'Loai ngoại tệ',
    total_money: 'Số tiền',
    inter_bank_name: 'Tên ngân hàng trung gian',
    inter_bank_address: 'Địa chỉ ngân hàng trung gian',
    inter_bank_address_city: 'Địa chỉ ngân hàng trung gian (Tỉnh/Thành phố)',
    inter_bank_address_nation: 'Địa chỉ ngân hàng trung gian (Quốc gia)',
    inter_bank_swift: 'Mã ngân hàng trung gian',
    inter_bank_chips: 'Chips/ Fedwire/ Sort code ngân hàng trung gian',
    receiver_bank_name: 'Tên ngân hàng người hưởng',
    receiver_bank_swift: 'Mã ngân hàng người hưởng',
    receiver_bank_chips: 'Chips/ Fedwire/ Sort code ngân hàng người hưởng',
    receiver_bank_address: 'Địa chỉ ngân hàng người hưởng',
    receiver_bank_address_city: 'Địa chỉ ngân hàng người hưởng (Tỉnh/Thành phố)',
    receiver_bank_address_nation: 'Địa chỉ ngân hàng người hưởng (Quốc gia)',
    receiver_name: 'Tên người hưởng',
    receiver_address: 'Địa chỉ người hưởng',
    receiver_address_city: 'Địa chỉ người hưởng (Tỉnh/Thành phố)',
    receiver_address_nation: 'Địa chỉ người hưởng (Quốc gia)',
    receiver_account_number: 'Số tài khoản/ số IBAN người hưởng',
    payment_details: 'Nội dung thanh toán',
    charges: 'Phí',
    fee_account_number: 'Số tài khoản thu phí',
    debit_account_number: 'Số tài khoản trích tiền',
    doi: 'Ngày giấy đề nghị chuyển tiền',
    goods: 'Hàng Hóa',
    total_money_text: 'Số tiền bằng chữ',
  })
);

const getMap = (type: any) => {
  if (type === 'SC') return SCMap;
  if (type === 'FOREIGN_INVOICE') return ForeignInvoiceMap;
  if (type === 'TTA') return TTAMap;
};

export const NWFMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.document_code,
      score: isNaN(document.document_code_score)
        ? document.document_code_score
        : document.document_code_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.document_code_score) * 100, 99).toFixed(0),
    });

    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        const data_map = getMap(document.document_type);
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }

    return {
      type: document.document_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};

export const NWFDetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.doc_code,
      score: isNaN(document.doc_score)
        ? document.doc_score
        : document.doc_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
    });

    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        const data_map = getMap(document.doc_type);
        const ocr_update = document.ocr_update ? document.ocr_update : null;
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    value_update: ocr_update && ocr_update[field.id],
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    value_update: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }

    return {
      type: document.document_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};
