import { useState, useEffect } from 'react';
import { APIServices } from '../../../utils';
import { Spin } from 'antd';

export const SignComponent = (props: any) => {
  const [listSign, setListSign] = useState<any>([]);

  const [loading, setLoading] = useState(false);
  const { documentID, requestID } = props;

  useEffect(() => {
    async function getFile() {
      let response;
      try {
        setLoading(true);
        response = await APIServices.OCR.DetectSignature(documentID, requestID, null);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return null;
      }

      if (response && response.data && response.data.signature_data)
        setListSign(response.data.signature_data);
    }

    getFile();
  }, [documentID, requestID]);

  return (
    <>
      {loading && <Spin spinning={loading}></Spin>}
      {!loading &&
        listSign &&
        listSign.length > 0 &&
        listSign.map((sign: any, index: any) => {
          return (
            <tr key={`sign_row_${index}`}>
              <td className='First'>Hình ảnh chữ ký {index + 1}</td>
              <td colSpan={2} style={{ textAlign: 'center' }}>
                <img
                  src={`data:image/png;base64,${sign.file_base64}`}
                  alt={`file`}
                  style={{ width: '100%', maxHeight: '200px' }}
                />
              </td>
              <td className='Last'>--%</td>
            </tr>
          );
        })}
    </>
  );
};
