const PaymentOrderMap = new Map(
  Object.entries({
    type: 'Tên loại chứng từ',
    sender_name: 'Tên người nộp tiền',
    sender_account: 'Tài khoản người nộp tiền',
    sender_bank: 'Ngân hàng người nộp tiền',
    receiver_name: 'Tên người thụ hưởng',
    receiver_id_number: 'Số giấy tờ tuỳ thân người thụ hưởng',
    receiver_id_doi: 'Ngày cấp giấy tờ tuỳ thân người thụ hưởng',
    receiver_id_poi: 'Nơi cấp giấy tờ tuỳ thân người thụ hưởng',
    receiver_account: 'Tài khoản người người thụ hưởng',
    receiver_bank: 'Ngân hàng người người thụ hưởng',
    receiver_bank_address: 'Địa chỉ ngân hàng người người thụ hưởng',
    amount: 'Số tiền',
    amount_word: 'Số tiền bằng chữ',
    detail: 'Nội dung',
  })
);

const DepositSlipMap = new Map(
  Object.entries({
    type: 'Tên loại chứng từ',
    sender_name: 'Tên người nộp tiền',
    sender_id_number: 'Số giấy tờ tuỳ thân người nộp tiền',
    sender_id_doi: 'Ngày cấp giấy tờ tuỳ thân người nộp tiền',
    sender_id_poi: 'Nơi cấp giấy tờ tuỳ thân người nộp tiền',
    sender_address: 'Địa chỉ người nộp tiền',
    sender_phone: 'Số điện thoại người nộp tiền',
    receiver_name: 'Tên người thụ hưởng',
    receiver_id_number: 'Số giấy tờ tuỳ thân người thụ hưởng',
    receiver_id_doi: 'Ngày cấp giấy tờ tuỳ thân người thụ hưởng',
    receiver_id_poi: 'Nơi cấp giấy tờ tuỳ thân người thụ hưởng',
    receiver_account: 'Tài khoản người người thụ hưởng',
    receiver_bank: 'Ngân hàng người người thụ hưởng',
    receiver_bank_address: 'Địa chỉ ngân hàng người người thụ hưởng',
    amount: 'Số tiền',
    amount_word: 'Số tiền bằng chữ',
    detail: 'Nội dung',
    fee_object: 'Đơn vị chịu phí',
  })
);

const getMap = (type: any) => {
  if (type === 'PAYMENT_ORDER') return PaymentOrderMap;
  if (type === 'DEPOSIT_SLIP') return DepositSlipMap;
};

export const DNAMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.document_code,
      score: isNaN(document.document_code_score)
        ? document.document_code_score
        : document.document_code_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.document_code_score) * 100, 99).toFixed(0),
    });

    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        const data_map = getMap(document.document_type);
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }

    return {
      type: document.document_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};

export const DNADetailMapping = (ocr: any) => {
  if (!ocr || ocr.length === 0) return [];
  var processedOCR = ocr.map((document: any, _: any) => {
    let ocrData = [] as any;
    ocrData.push({
      attribute: 'Mã hồ sơ',
      id: null,
      coords: null,
      value: document.doc_code,
      score: isNaN(document.doc_score)
        ? document.doc_score
        : document.doc_score === -1
        ? 0
        : Math.min(Number.parseFloat(document.doc_score) * 100, 99).toFixed(0),
    });

    if (document.ocr_data) {
      const doc_type =
        document.ocr_data instanceof Object && Object.keys(document.ocr_data).length > 0
          ? Object.keys(document.ocr_data)[0]
          : null;
      if (doc_type) {
        const ocr_data = document.ocr_data[`${doc_type}`]; // List
        const data_map = getMap(document.doc_type);
        const ocr_update = document.ocr_update ? document.ocr_update : null;
        if (ocr_data && ocr_data.length > 0 && data_map) {
          ocr_data.forEach((ocr_item: any, index: any) => {
            if (ocr_item && ocr_item instanceof Object && Object.keys(ocr_item).length > 0) {
              Array.from(data_map.keys()).forEach((item: any, _: any) => {
                if (ocr_item[item]) {
                  let field = Array.isArray(ocr_item[item]) ? ocr_item[item][0] : ocr_item[item];

                  ocrData.push({
                    attribute:
                      index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                    id: field.id,
                    coords: field.coordinates,
                    value: field.value,
                    value_update: ocr_update && ocr_update[field.id],
                    image_index: field.image_index,
                    score: isNaN(field.score)
                      ? field.score
                      : field.score === -1
                      ? 0
                      : Math.min(Number.parseFloat(field.score) * 100, 99).toFixed(0),
                  });
                } else
                  ocrData.push({
                    attribute:
                      index === 0 ? data_map.get(item) : `${data_map.get(item)} ${index + 1}`,
                    id: null,
                    coords: null,
                    image_index: null,
                    value: null,
                    value_update: null,
                    certain: null,
                  });
              });
            }
          });
        }
      }
    }

    return {
      type: document.document_type,
      document_id: document.document_id,
      file_id: document.file_id,
      ocrData,
    };
  });

  return processedOCR;
};
