import createApiServices from '../createApiServices';
import { ServerConfigs } from '../../../const';

let { client_id, client_secret, acb_server } = ServerConfigs;

const api = createApiServices();

const GetAccessToken = (loginInfo: any) => {
  const { username, password } = loginInfo;
  var data = {
    username,
    password,
    client_id,
    client_secret,
    grant_type: 'password',
  };

  return api.makeRequest({
    url: '/auth/v1/oauth/accessToken',
    method: 'POST',
    data,
  });
};

const VerifyOTP = (otp: any, loginInfo: any) => {
  const { otp_value } = otp;
  const { username, password } = loginInfo;
  var data = {
    username,
    password,
    client_id,
    client_secret,
    grant_type: 'password',
    otp: otp_value,
  };
  return api.makeRequest({
    url: '/auth/v1/oauth/otp/verify',
    method: 'POST',
    data,
  });
};

const SsoLogin = (data: any) => {
  return api.makeRequest({
    url: `${acb_server}/api/v1/sso/login`,
    method: 'POST',
    headers: {
      'X-APP-CODE': 'OCR-MFA',
      'X-TOKEN-TYPE': 'OPTL',
    },
    data,
  });
};

const GetInfo = (token: any) => {
  return api.makeAuthRequest({
    url: `${acb_server}/api/v1/me/infos`,
    method: 'GET',
    data: {
      token,
    },
  });
};

const GetExchangeAccessToken = (data: any) => {
  return api.makeRequest({
    url: '/auth/v1/oauth/acb/accessToken', //'/auth/v1/oauth/accessToken/exchange',
    method: 'POST',
    data,
  });
};

export const Auth = {
  GetAccessToken,
  VerifyOTP,
  SsoLogin,
  GetInfo,
  GetExchangeAccessToken,
};
