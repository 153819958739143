import RequestReducer from "./request/reducer";
import AuthReducer from "./auth/reducer";
import OCRReducer from "./ocr/reducer";
import LogReducer from "./log/reducer";
import SignReducer from "./sign/reducer";
import DocReducer from "./doc/reducer";
import UserReducer from "./user/reducer";
import UserGroupReducer from "./user-group/reducer";
import ClientAppReducer from "./client-app/reducer";
import ReportReducer from "./report/reducer";
import DailyReducer from "./daily/reducer";
import FlowReducer from "./flow/reducer";
import SignatureCompareReducer from "./signature-compare/reducer";

const rootReducer = {
  request: RequestReducer,
  auth: AuthReducer,
  ocr: OCRReducer,
  log: LogReducer,
  sign: SignReducer,
  doc: DocReducer,
  user: UserReducer,
  user_group: UserGroupReducer,
  client_app: ClientAppReducer,
  report: ReportReducer,
  daily: DailyReducer,
  flow: FlowReducer,
  signature_compare: SignatureCompareReducer,
};

export default rootReducer;
