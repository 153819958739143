import { useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import "./index.scss";
import { RouterLinks } from "../../const";
const envSettings = window.global as any;
export const SideBarComponent = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [childPath, setChildPath] = useState("");
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.split("/")[2];
    setChildPath(pathname);
  }, [location.pathname]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      id="SideBar"
      style={{
        width: collapsed ? "24px" : "300px",
        minWidth: collapsed ? "24px" : "300px",
      }}
    >
      <div className="CollapseBtn" onClick={handleCollapse}>
        {collapsed ? <FaChevronRight /> : <FaChevronLeft />}
      </div>
      <div
        className="ListCollapse"
        style={{ display: collapsed ? "none" : "block" }}
      >
        <Link to={RouterLinks.OCR_PAGE}>
          <div
            className={
              childPath === "ocr" ? "ItemCollapse Active" : "ItemCollapse"
            }
          >
            New OCR
          </div>
        </Link>
        <Link to={RouterLinks.DAILY_PAGE}>
          <div
            className={
              childPath === "daily" ? "ItemCollapse Active" : "ItemCollapse"
            }
          >
            Daily Request
          </div>
        </Link>
        <Link to={RouterLinks.LOG_PAGE}>
          <div
            className={
              childPath === "log" ? "ItemCollapse Active" : "ItemCollapse"
            }
          >
            OCR Logs
          </div>
        </Link>
        <Link to={RouterLinks.SIGN_PAGE}>
          <div
            className={
              childPath === "sign" ? "ItemCollapse Active" : "ItemCollapse"
            }
          >
            Signature Logs
          </div>
        </Link>
        <Link to={RouterLinks.SIGNATURE_COMPARE_PAGE}>
          <div
            className={
              childPath === "signature-compare"
                ? "ItemCollapse Active"
                : "ItemCollapse"
            }
          >
            Signature Compare
          </div>
        </Link>
        <Link to={RouterLinks.FLOW_PAGE}>
          <div
            className={
              childPath === "flow" ? "ItemCollapse Active" : "ItemCollapse"
            }
          >
            Document Workflow
          </div>
        </Link>
        <Link to={RouterLinks.DOC_PAGE}>
          <div
            className={
              childPath === "doc" ? "ItemCollapse Active" : "ItemCollapse"
            }
          >
            Document Management
          </div>
        </Link>{" "}
        {localStorage.getItem("role") !== "BUSINESS_OCR" && (
          <>
            <Link to={RouterLinks.USER_PAGE}>
              <div
                className={
                  childPath === "user" ? "ItemCollapse Active" : "ItemCollapse"
                }
              >
                User Management
              </div>
            </Link>
            <Link to={RouterLinks.USER_GROUP_PAGE}>
              <div
                className={
                  childPath === "user-group"
                    ? "ItemCollapse Active"
                    : "ItemCollapse"
                }
              >
                UserGroup Management
              </div>
            </Link>
            <Link to={RouterLinks.CLIENT_APP_PAGE}>
              <div
                className={
                  childPath === "client-app"
                    ? "ItemCollapse Active"
                    : "ItemCollapse"
                }
              >
                Client App Management
              </div>
            </Link>
            <Link to={RouterLinks.REPORT_PAGE}>
              <div
                className={
                  childPath === "report"
                    ? "ItemCollapse Active"
                    : "ItemCollapse"
                }
              >
                OCR Reports
              </div>
            </Link>
          </>
        )}
      </div>
      <div className="version-tag">{envSettings.TAG_VERSION ?? "v1.0.0"}</div>
    </div>
  );
};
