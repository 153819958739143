import createApiServices from "../createApiServices";

const api = createApiServices();

const GetSignLog = (
  client_id: any,
  page_size: any,
  page_number: any,
  current_filter: any
) => {
  var listClient: any = [];
  if (client_id && client_id.length > 0)
    listClient = client_id.map((client: any, _: any) => {
      return client.client_id;
    });

  const { from_time, to_time, search_text } = current_filter;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/history/signature?limit=${page_size}&from_time=${from_time}&to_time=${to_time}&page=${page_number}&rid=${search_text}`,
    method: "POST",
    data: { client_id: listClient },
  });
};

const GetFileContent = (
  client_id: any,
  request_id: any,
  path: any,
  size: any = "thumbnail"
) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/file/${request_id}?size=${size}`,
    method: "POST",
    data: { client_id, path },
    responseType: "blob",
  });
};

export const Sign = {
  GetSignLog,
  GetFileContent,
};
