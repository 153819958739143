export const RouterLinks = {
  LOGIN_PAGE: "/login",
  OTP_PAGE: "/otp",

  APP: "/app",

  OCR_PAGE: "/app/ocr",
  DAILY_PAGE: "/app/daily",
  LOG_PAGE: "/app/log",
  SIGN_PAGE: "/app/sign",
  FLOW_PAGE: "/app/flow",
  DOC_PAGE: "/app/doc",
  USER_PAGE: "/app/user",
  USER_GROUP_PAGE: "/app/user-group",
  CLIENT_APP_PAGE: "/app/client-app",
  REPORT_PAGE: "/app/report",
  SIGNATURE_COMPARE_PAGE: "/app/signature-compare",
};
