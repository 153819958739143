import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationService } from "../../components";
import { APIServices } from "../../utils";
import actions from "./actions";
import reqActions from "../request/actions";
import authActions from "../auth/actions";

function* saga_LoadData(payloadData: any) {
  try {
    const isFilter = payloadData.type === actions.types.UPDATE_FILTER;
    let _pageNumber: Promise<any> = yield select(
      (state: any) => state.sign.page_number
    );
    let pageNumber = isFilter ? 1 : _pageNumber;
    let _pageSize: Promise<any> = yield select(
      (state: any) => state.sign.page_size
    );
    let pageSize = _pageSize;
    let _currentFilter: Promise<any> = yield select(
      (state: any) => state.sign.current_filter
    );
    let currentFilter = _currentFilter;
    let _clientID: Promise<any> = yield select(
      (state: any) => state.client_app.data
    );
    let clientID = _clientID;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Sign.GetSignLog(
      clientID,
      pageSize,
      pageNumber,
      currentFilter
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      let { paging, documents } = data;

      yield put(actions.action.loadDataSuccess(paging, documents));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải dữ liệu thất bại");
    }
  } catch (ex: any) {
    console.log("[Sign] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải dữ liệu thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_DATA, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_NUMBER, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_SIZE, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_FILTER, saga_LoadData);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
