import createApiServices from "../createApiServices";

const api = createApiServices();

const GetConfig = () => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/config`,
    method: "GET",
  });
};

const PushFile = (files: any, doc_type: any, client_id: any, ocr_type: any) => {
  let file_type =
    files[0].type.includes("png") ||
    files[0].type.includes("jpg") ||
    files[0].type.includes("jpeg")
      ? "image"
      : "pdf";

  let form_data = new FormData();
  Array.from(files).forEach((file: any) => {
    form_data.append("file", file);
  });
  form_data.append("doc_type", doc_type);
  form_data.append("full_flow", "true");
  form_data.append("file_type", file_type);
  if (ocr_type === "SINGLE") {
    form_data.append("ocr_type", ocr_type);
  }

  return api.makeAuthRequest({
    url: `/ocr/v1.2/push`,
    method: "POST",
    data: form_data,
    client_id,
  });
};

const GetFileContent = (
  type: any,
  document_id: any,
  file_id: any,
  request_id: any,
  client_id: any
) => {
  return api.makeAuthRequest({
    url: `/ocr/v1.2/file/${request_id}`,
    method: "POST",
    data: { type, document_id, file_id },
    client_id,
  });
};

const GetResult = (request_id: any, client_id: any) => {
  return api.makeAuthRequest({
    url: `/ocr/v1.2/result/${request_id}`,
    method: "POST",
    client_id,
  });
};

const DetectSignature = (document_id: any, request_id: any, client_id: any) => {
  return api.makeAuthRequest({
    url: `/ocr/v1.2/signature/detect/${request_id}`,
    method: "POST",
    data: { document_id, compare_mode: true },
    client_id,
  });
};

const DetectTable = (document_id: any, request_id: any, client_id: any) => {
  return api.makeAuthRequest({
    url: `/ocr/v1.1/table/detect/${request_id}`,
    method: "POST",
    data: { document_id },
    client_id,
  });
};

const UpdateResult = (data: any, request_id: any, client_id: any) => {
  return api.makeAuthRequest({
    url: `/ocr/v1.2/update/${request_id}`,
    method: "POST",
    data,
    client_id,
  });
};

export const OCR = {
  GetConfig,
  PushFile,
  GetFileContent,
  GetResult,
  DetectSignature,
  DetectTable,
  UpdateResult,
};
