import { useState, useEffect } from 'react';
import { APIServices } from '../../../utils';
import { Spin } from 'antd';

export const FileComponent = (props: any) => {
  const [file, setFile] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const { size, path, clientID, requestID, handleClick, allowClick, loaded } = props;

  useEffect(() => {
    async function getFile() {
      let fileContent;
      try {
        setLoading(true);
        fileContent = await APIServices.Log.GetFileContent(clientID, requestID, path, size);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return null;
      }

      setFile(URL.createObjectURL(fileContent));
      if (size === 'original') {
        var img = document.createElement('img');
        img.src = URL.createObjectURL(fileContent);
        img.onload = function () {
          var w = img.width;
          var h = img.height;
          loaded(h, w);
        };
      }
    }

    getFile();
  }, [clientID, path, size, requestID]);

  return (
    <>
      {loading && <Spin spinning={loading}></Spin>}
      {!loading && (
        <img
          src={file}
          alt={`file`}
          id={`document_image_${size}`}
          onClick={handleClick}
          style={{ cursor: allowClick ? 'pointer' : 'default' }}
        />
      )}
    </>
  );
};
