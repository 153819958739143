import { all, fork, put, takeEvery, select } from "redux-saga/effects";

import { NotificationService } from "../../components";
import { APIServices } from "../../utils";
import actions from "./actions";
import reqActions from "../request/actions";
import authActions from "../auth/actions";
import { errorCodeMap, errorBizAppMap } from "../../const";

function* handleFail(code: any, error_code: any = []) {
  yield put(reqActions.action.updateRequestState(-1));
  if (code === 2) {
    let msg = "";
    if (error_code.length > 1) {
      error_code.forEach((item: any, id: any) => {
        msg = msg.concat(`- ${errorBizAppMap.get(item.toString())}\n`);
      });
    } else msg = errorBizAppMap.get(error_code[0].toString())!;
    NotificationService.error(msg);
  } else if (errorCodeMap.has(code)) {
    NotificationService.error(errorCodeMap.get(code.toString()));
  } else NotificationService.error("Unknown Error");
}

function* saga_LoadConfig() {
  try {
    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.OCR.GetConfig();
    let response: any = _response;
    let { data } = response;

    if (data) {
      let { flow, single_docs } = data;

      yield put(actions.action.loadConfigSuccess(flow, single_docs));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải mô tả chứng từ thất bại");
    }
  } catch (ex: any) {
    console.log("[OCR] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải mô tả chứng từ thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_PushFile() {
  try {
    let _files: Promise<any> = yield select((state: any) => state.ocr.files);
    let files = _files;
    let _doc_type: Promise<any> = yield select(
      (state: any) => state.ocr.doc_type
    );
    let doc_type = _doc_type;
    let _client_id: Promise<any> = yield select(
      (state: any) => state.ocr.client_id
    );
    let client_id = _client_id;

    let _ocr_type: Promise<any> = yield select(
      (state: any) => state.ocr.ocr_type
    );
    let ocr_type = _ocr_type;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.OCR.PushFile(
      files,
      doc_type,
      client_id,
      ocr_type
    );
    let response: any = _response;
    let { data, code } = response;

    if (code === 1 && data) {
      yield put(reqActions.action.updateRequestState(1));

      const request_id = data.request_id ? data.request_id : "";
      const file_id = data.file_id ? data.file_id[0] : "";
      const documents = data.documents ? data.documents : null;
      yield put(actions.action.pushFileSuccess(request_id, file_id, documents));
      yield put(actions.action.ocrFile());
      if (!documents) NotificationService.error("OCR File Failed");
    } else {
      yield handleFail(code, data.error_code);
      yield put(actions.action.updateFile([]));
    }
  } catch (ex: any) {
    console.error("saga_PushFile", ex);
    NotificationService.error(
      ex && ex.data && ex.data.message ? ex.data.message : "Push file Failed"
    );
    yield put(reqActions.action.updateRequestState(-1));
    yield put(actions.action.updateFile([]));

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_GetResult() {
  try {
    let _request_id: Promise<any> = yield select(
      (state: any) => state.ocr.request_id
    );
    let request_id = _request_id;
    let _client_id: Promise<any> = yield select(
      (state: any) => state.ocr.client_id
    );
    let client_id = _client_id;

    //yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.OCR.GetResult(
      request_id,
      client_id
    );
    let response: any = _response;
    let { data, code } = response;

    if (code === 1 && data) {
      //yield put(reqActions.action.updateRequestState(1));
      let progress = data.progress;
      let ocr_done = false;
      if (progress.total_doc === progress.finished + progress.ignored) {
        ocr_done = true;
      }
      yield put(actions.action.ocrFileSuccess(ocr_done, data.documents));
    } else {
      yield handleFail(code, data.error_code);
    }
  } catch (ex: any) {
    console.error("saga_GetResult", ex);
    NotificationService.error(
      ex && ex.data && ex.data.message ? ex.data.message : "Get result Failed"
    );
    yield put(reqActions.action.updateRequestState(-1));

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_DetectSignature(action: any) {
  try {
    let _request_id: Promise<any> = yield select(
      (state: any) => state.ocr.request_id
    );
    let request_id = _request_id;
    let _client_id: Promise<any> = yield select(
      (state: any) => state.ocr.client_id
    );
    let client_id = _client_id;
    let { document_id } = action.payload;

    //yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.OCR.DetectSignature(
      document_id,
      request_id,
      client_id
    );
    let response: any = _response;
    let { data, code } = response;

    if (code === 1 && data) {
      //yield put(reqActions.action.updateRequestState(1));
      //yield put(actions.action.pushFileSuccess(request_id, file_id, documents));
      console.log(data);
    } else {
      yield handleFail(code, data.error_code);
    }
  } catch (ex: any) {
    console.error("saga_DetectSign", ex);
    NotificationService.error(
      ex && ex.data && ex.data.message ? ex.data.message : "Detect Sign Failed"
    );
    yield put(reqActions.action.updateRequestState(-1));

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_PushResult(action: any) {
  try {
    let { result } = action.payload;
    let _request_id: Promise<any> = yield select(
      (state: any) => state.ocr.request_id
    );
    let request_id = _request_id;
    let _client_id: Promise<any> = yield select(
      (state: any) => state.ocr.client_id
    );
    let client_id = _client_id;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.OCR.UpdateResult(
      result,
      request_id,
      client_id
    );
    let response: any = _response;
    let { data, code } = response;

    if (code === 1 && data) {
      yield put(reqActions.action.updateRequestState(1));
      yield put(actions.action.pushResultSuccess(data));
      NotificationService.success("Update OCR result successfully");
    } else {
      yield handleFail(code, data.error_code);
      yield put(actions.action.updateResult(null));
      NotificationService.error("Update OCR result failed");
    }
  } catch (ex: any) {
    console.error("saga_PushFile", ex);
    NotificationService.error(
      ex && ex.data && ex.data.message ? ex.data.message : "Push file Failed"
    );
    yield put(reqActions.action.updateRequestState(-1));
    yield put(actions.action.updateResult(null));

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_CONFIG, saga_LoadConfig);
  yield takeEvery(actions.types.PUSH_FILE, saga_PushFile);
  yield takeEvery(actions.types.OCR_FILE, saga_GetResult);
  yield takeEvery(actions.types.DETECT_SIGNATURE, saga_DetectSignature);
  yield takeEvery(actions.types.PUSH_RESULT, saga_PushResult);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
