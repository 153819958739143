const prefix = 'flow/';

const types = {
  LOAD_DATA: prefix + 'load_data',
  LOAD_DATA_SUCCESS: prefix + 'load_data_success',

  UPDATE_FILTER: prefix + 'update_filter',
  UPDATE_PAGE_NUMBER: prefix + 'update_page_number',
  UPDATE_PAGE_SIZE: prefix + 'update_page_size',

  UPDATE_CURRENT_ITEM: prefix + 'set_current_item',
  SAVE_CURRENT_ITEM: prefix + 'save_current_item',
  DELETE_CURRENT_ITEM: prefix + 'delete_current_item',
};

const action = {
  updateFilter: (filter: any) => {
    return {
      type: types.UPDATE_FILTER,
      payload: { filter },
    };
  },
  updatePageNumber: (page_number: any) => {
    return {
      type: types.UPDATE_PAGE_NUMBER,
      payload: { page_number },
    };
  },
  updatePageSize: (page_size: any) => {
    return {
      type: types.UPDATE_PAGE_SIZE,
      payload: { page_size },
    };
  },
  loadData: () => {
    return {
      type: types.LOAD_DATA,
    };
  },
  loadDataSuccess: (total: any, data: any) => {
    return {
      type: types.LOAD_DATA_SUCCESS,
      payload: { total, data },
    };
  },
  updateCurrentItem: (data: any) => {
    return {
      type: types.UPDATE_CURRENT_ITEM,
      payload: { data },
    };
  },
  saveCurrentItem: () => {
    return {
      type: types.SAVE_CURRENT_ITEM,
    };
  },
  deleteCurrentItem: (id: any) => {
    return {
      type: types.DELETE_CURRENT_ITEM,
      payload: { id },
    };
  },
};

const actions = {
  types,
  action,
};

export default actions;

export const FlowActions = action;
