import { useEffect, useState } from "react";
import moment from "moment";
import { Spin } from "antd";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { LuRotateCw } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";

import { useActions } from "../../redux";
import { PaginationComponent } from "../../components/pagination";
import {
  A1DetailMapping,
  A2DetailMapping,
  B1DetailMapping,
  B2DetailMapping,
  GreenDetailMapping,
  NWFDetailMapping,
  B4DetailMapping,
  CLMSDetailMapping,
  DNADetailMapping,
  LoanDetailMapping,
  A3A4DetailMapping,
  B1PLUSDetailMapping,
} from "../../const";
import {
  Canvas,
  FileComponent,
  SignComponent,
  DailyFilterComponent,
  DailyStatComponent,
  DailyTabComponent,
  PriorityInputComponent,
} from "./component";
import { renderDateTime } from "../../utils";
import ButtonCopy from "../../components/buttonCoppy";
import { D1DetailMapping } from "../../const/d1-flow-mapping";

const dateFormat = "DD-MM-YYYY - HH:mm:ss";

export const DailyPage = () => {
  //PENDING, PROCESSING, SUCCESS, FAILED
  //DESC, ASC
  const [isExpand, setIsExpand] = useState<any>(null);
  const [documentExpand, setDocumentExpand] = useState<any>(null);
  const [documentPath, setDocumentPath] = useState<any>(null);
  const [showCoord, setShowCoord] = useState<any>(null);
  const [fileIDs, setFileIDs] = useState<any>([]);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);

  const dispatch = useDispatch();
  const actions = useActions();
  const requestState = useSelector((state: any) => state.request.request_state);
  let dashboard = useSelector((state: any) => state.daily.dashboard);
  let data = useSelector((state: any) => state.daily.data);
  let totalRecord = useSelector((state: any) => state.daily.total_record);
  let pageNumber = useSelector((state: any) => state.daily.page_number);
  let pageSize = useSelector((state: any) => state.daily.page_size);

  let currentRequest = useSelector((state: any) => state.daily.current_request);
  let detailData = useSelector((state: any) => state.daily.detail_data);
  let clientAppData = useSelector((state: any) => state.client_app.data);

  useEffect(() => {
    if (!clientAppData) dispatch(actions.ClientAppActions.loadData(true));
    if (!dashboard && clientAppData && clientAppData)
      dispatch(actions.DailyActions.loadDashboard());
    if (!data && clientAppData && clientAppData)
      dispatch(actions.DailyActions.loadData());

    if (currentRequest && currentRequest.request_id && !detailData) {
      dispatch(actions.DailyActions.loadDetailData());
    }
  }, [
    actions.DailyActions,
    actions.ClientAppActions,
    dispatch,
    currentRequest,
    dashboard,
    data,
    clientAppData,
    detailData,
  ]);

  let currentFilter = useSelector((state: any) => state.daily.current_filter);
  const handleChangeFilter = (filter: any) => {
    dispatch(
      actions.DailyActions.updateFilter({
        ...currentFilter,
        ...filter,
      })
    );
  };

  const handleChangeTab = (status: any) => {
    dispatch(
      actions.DailyActions.updateFilter({
        ...currentFilter,
        status,
      })
    );
  };

  const handleChangePageNumber = (page_number: any) => {
    dispatch(actions.DailyActions.updatePageNumber(page_number));
  };

  const handleChangePageSize = (page_size: any) => {
    dispatch(actions.DailyActions.updatePageSize(page_size));
  };

  const handleUpdatePriority = (request_id: any, priority: any) => {
    dispatch(
      actions.DailyActions.updatePriority({
        request_id,
        priority: parseInt(priority),
      })
    );
  };

  const handleRetryRequest = (request_id: any) => {
    dispatch(actions.DailyActions.retryRequest(request_id));
  };

  const handleReload = () => {
    dispatch(actions.DailyActions.loadDashboard());
    dispatch(actions.DailyActions.loadData());
  };

  const handleBack = () => {
    handleChangeCurrentRequest("", "", "", "", "", "");
    dispatch(actions.DailyActions.loadDetailDataSuccess(null));
    setIsExpand(null);
    setDocumentExpand(null);
    setShowCoord(null);
    setFileIDs([]);
    setDocumentPath(null);
  };

  const handleChangeCurrentRequest = (
    client_id: any,
    request_id: any,
    time: any,
    doc_code: any,
    status: any,
    page: any
  ) => {
    dispatch(
      actions.DailyActions.updateCurrentRequest({
        client_id,
        request_id,
        time,
        doc_code,
        status,
        page,
      })
    );
  };

  const handleExpandFile = (
    documentID: any,
    documentPath: any,
    fileIDs: any
  ) => {
    setIsExpand(1);
    setDocumentExpand(documentID);
    setShowCoord(null);
    setFileIDs(fileIDs);
    setDocumentPath(documentPath);
  };

  if (detailData && !documentExpand) {
    handleExpandFile(
      detailData[0].document_id,
      detailData[0].file_path,
      detailData[0].file_id
    );
  }

  const getMapping = (ocr: any) => {
    const { doc_code } = currentRequest;

    if (doc_code === "QF") return A1DetailMapping(ocr);
    if (doc_code === "INVOICE") return B1DetailMapping(ocr);
    if (doc_code === "KHDN") return GreenDetailMapping(ocr);
    if (doc_code === "NWF") return NWFDetailMapping(ocr);
    if (doc_code === "A2") return A2DetailMapping(ocr);
    if (doc_code === "B2") return B2DetailMapping(ocr);
    if (doc_code === "B4") return B4DetailMapping(ocr);
    if (doc_code === "CLMS") return CLMSDetailMapping(ocr);
    if (doc_code === "DNA") return DNADetailMapping(ocr);
    if (doc_code === "LOANXPRESS") return LoanDetailMapping(ocr);
    if (doc_code === "A3A4") return A3A4DetailMapping(ocr);
    if (doc_code === "D1") return D1DetailMapping(ocr);
    if (doc_code === "B1PLUS" || doc_code === "BANG_KE")
      return B1PLUSDetailMapping(ocr);

    return null;
  };

  return (
    <div className="AppPage">
      {currentRequest && currentRequest.request_id === "" && (
        <>
          <div className="PageTitle">Daily Requests</div>

          <DailyStatComponent dailyStat={dashboard} />

          <DailyTabComponent
            currentTab={currentFilter.status}
            onChangeTab={handleChangeTab}
          />

          <DailyFilterComponent
            currentFilter={currentFilter}
            onFilterChange={handleChangeFilter}
            onReload={handleReload}
          />

          <Spin
            spinning={requestState === 0}
            size="large"
            style={{ position: "static" }}
          >
            <div
              className="TableContainer"
              style={{
                minHeight: "calc(100vh - 462px)",
                height: "fit-content",
              }}
            >
              <table className="table table-striped sticky-header">
                <thead>
                  <tr>
                    <th>Request ID</th>
                    <th>Client App</th>
                    <th>Client ID</th>
                    <th>Luồng nghiệp vụ</th>
                    <th>Tên chứng từ</th>
                    <th>Số lượng chứng từ</th>
                    <th>Số trang</th>
                    <th>Thời gian gửi yêu cầu</th>
                    <th>Thời gian bắt đầu xử lý</th>
                    <th>Thời gian kết thúc</th>
                    {currentFilter.status === "SUCCESS" ? (
                      <></>
                    ) : currentFilter.status === "FAILED" ? (
                      <th></th>
                    ) : (
                      <th>Mức độ ưu tiên</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((request: any, req_index: any) => {
                      return (
                        <tr key={`row_${req_index}`}>
                          <td style={{ position: "relative" }}>
                            <div style={{ margin: "10px" }}>
                              <div
                                className="clickable-class"
                                onClick={() =>
                                  handleChangeCurrentRequest(
                                    request.client_id,
                                    request.request_id,
                                    moment(request.created_at).format(
                                      dateFormat
                                    ),
                                    request.doc_type,
                                    request.status,
                                    request.total_doc
                                  )
                                }
                              >
                                {request.request_id}
                              </div>
                              <ButtonCopy value={request.request_id} />
                            </div>
                          </td>
                          <td>{request.client_app}</td>
                          <td>{request.client_id}</td>
                          <td>{request.doc_type}</td>
                          <td>{request.document_code}</td>
                          <td>{request.total_doc}</td>
                          <td>{request.total_page}</td>
                          <td>{renderDateTime(request.created_at)}</td>
                          <td>{renderDateTime(request.start_ocr_at)}</td>
                          <td>{renderDateTime(request.finished_at)}</td>
                          {currentFilter.status === "SUCCESS" ? (
                            <></>
                          ) : currentFilter.status === "FAILED" ? (
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="PriorityCell">
                                <div
                                  className="PriorityContainer"
                                  style={{
                                    width: "100px",
                                    flexDirection: "initial",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleRetryRequest(request.request_id)
                                  }
                                >
                                  <LuRotateCw style={{ marginRight: "5px" }} />
                                  Xử lý lại
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td style={{ verticalAlign: "middle" }}>
                              <PriorityInputComponent
                                requestId={request.request_id}
                                priority={request.priority}
                                onUpdate={handleUpdatePriority}
                                status={currentFilter.status}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  {(!data || data.length === 0) && (
                    <tr>
                      <td colSpan={11}>Không có dữ liệu</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <PaginationComponent
              totalRecord={totalRecord}
              pageNumber={pageNumber}
              pageSize={pageSize}
              onChangePageNumber={handleChangePageNumber}
              onChangePageSize={handleChangePageSize}
            />
          </Spin>
        </>
      )}
      {currentRequest && currentRequest.request_id && (
        <div className="DetailContainer">
          <div className="PageTitle">
            <HiArrowLeft onClick={handleBack} />
            Request ID {currentRequest.request_id}
          </div>
          <Spin
            spinning={requestState === 0}
            size="large"
            style={{ position: "static" }}
          >
            <div className="InfoContainer">
              <div className="InfoCol">
                <div>
                  <span className="InfoLabel">Thời gian</span>
                  <span className="InfoValue">{currentRequest.time}</span>
                </div>
                <div>
                  <span className="InfoLabel">Client ID</span>
                  <span className="InfoValue">{currentRequest.client_id}</span>
                </div>
              </div>
              <div className="InfoCol">
                <div>
                  <span className="InfoLabel">Tên chứng từ</span>
                  <span className="InfoValue">{currentRequest.doc_code}</span>
                </div>
                <div>
                  <span className="InfoLabel">Số lượng BCT</span>
                  <span className="InfoValue">{currentRequest.page}</span>
                </div>
              </div>
              <div className="InfoCol">
                <div>
                  <span className="InfoLabel">Trạng thái OCR</span>
                  <span className="InfoValue">
                    {currentRequest.status < 0
                      ? "Thất bại"
                      : currentRequest.status === 0
                      ? "Đang thực hiện"
                      : "Thành công"}
                  </span>
                </div>
                <div></div>
              </div>
            </div>
            <div className="OCRContainer">
              <div className="Page__Left">
                <div className="ExpandContainer">
                  <div className="ExpandController">
                    <div className="ThumbnailFileContainer">
                      {detailData &&
                        detailData.map((document: any, id: any) => {
                          if (
                            document.file_path &&
                            document.file_id &&
                            document.file_id.length > 0
                          ) {
                            return (
                              <div
                                key={`doc_${id}`}
                                style={{
                                  opacity:
                                    document.document_id === documentExpand
                                      ? "1"
                                      : "0.3",
                                }}
                                className="ThumbnailFile"
                              >
                                <FileComponent
                                  size="thumb"
                                  path={
                                    document.file_path +
                                    "/" +
                                    document.file_id[0]
                                  }
                                  clientID={currentRequest.client_id}
                                  requestID={currentRequest.request_id}
                                  handleClick={() =>
                                    handleExpandFile(
                                      document.document_id,
                                      document.file_path,
                                      document.file_id
                                    )
                                  }
                                  allowClick={true}
                                />
                              </div>
                            );
                          } else return <></>;
                        })}
                    </div>
                    <div className="ControllerContainer">
                      <HiArrowLeft
                        onClick={() => {
                          if (isExpand > 1) setIsExpand(isExpand - 1);
                        }}
                      />

                      <span>
                        {isExpand}/{fileIDs.length}
                      </span>

                      <HiArrowRight
                        onClick={() => {
                          if (isExpand < fileIDs.length)
                            setIsExpand(isExpand + 1);
                        }}
                      />
                    </div>
                  </div>
                  {documentExpand && (
                    <div className="CanvasContainer">
                      <div className="PdfCanvas">
                        <FileComponent
                          size="original"
                          path={documentPath + "/" + fileIDs[isExpand - 1]}
                          clientID={currentRequest.client_id}
                          requestID={currentRequest.request_id}
                          allowClick={false}
                          loaded={(height: any, width: any) => {
                            setOriginalHeight(height);
                            setOriginalWidth(width);
                          }}
                        />

                        <Canvas
                          bb={showCoord}
                          originalWidth={originalWidth}
                          originalHeight={originalHeight}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="Page__Right">
                {getMapping(detailData) &&
                  getMapping(detailData).length > 0 &&
                  getMapping(detailData).map((doc: any, index: any) => {
                    if (documentExpand && doc.document_id !== documentExpand)
                      return <></>;
                    var table_data = doc.ocrData;

                    return (
                      <div key={`doc_${index}`}>
                        <div className="ResultTitleContainer">
                          <div className="ResultTitle">Kết quả trích xuất</div>
                        </div>

                        <table>
                          <thead>
                            <tr>
                              <th>Trường thông tin</th>
                              <th>Kết quả OCR</th>
                              <th>Cập nhật OCR</th>
                              <th>Độ tin cậy (%)</th>
                            </tr>
                          </thead>
                          <tbody className="ContractTable">
                            {table_data.map((item: any, id: any) => {
                              return (
                                <tr
                                  key={`row_${id}`}
                                  className={`${
                                    item.coords &&
                                    documentExpand &&
                                    item.image_index === isExpand - 1
                                      ? "RowClick"
                                      : ""
                                  } ${item?.is_bold ? "text-bold" : ""}`}
                                  onMouseOver={() => {
                                    if (item.image_index === isExpand - 1)
                                      setShowCoord(item.coords);
                                  }}
                                  onMouseOut={() => {
                                    setShowCoord(null);
                                  }}
                                >
                                  <td className="First">{item.attribute}</td>
                                  <td
                                    className={
                                      item.value ? "Middle" : "Middle Null"
                                    }
                                  >
                                    {item?.hasImage ? (
                                      <FileComponent
                                        size="original"
                                        path={documentPath + "/" + item.value}
                                        clientID={currentRequest.client_id}
                                        requestID={currentRequest.request_id}
                                        allowClick={false}
                                        loaded={(height: any, width: any) => {
                                          setOriginalHeight(height);
                                          setOriginalWidth(width);
                                        }}
                                      />
                                    ) : item.value ? (
                                      item.value
                                    ) : (
                                      "--"
                                    )}
                                  </td>
                                  <td
                                    className={
                                      item.value_update
                                        ? "Middle"
                                        : "Middle Null"
                                    }
                                  >
                                    {item.value_update
                                      ? item.value_update
                                      : "--"}
                                  </td>
                                  <td
                                    className="Last"
                                    style={{
                                      color:
                                        item.score && parseInt(item.score) < 50
                                          ? "red"
                                          : "initial",
                                    }}
                                  >
                                    {item.score ? item.score : "--%"}
                                  </td>
                                </tr>
                              );
                            })}
                            {doc.type === "QF" && (
                              <SignComponent
                                documentID={doc.document_id}
                                requestID={currentRequest.request_id}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Spin>
        </div>
      )}
    </div>
  );
};
