import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationService } from "../../components";
import { APIServices } from "../../utils";
import actions from "./actions";
import reqActions from "../request/actions";
import authActions from "../auth/actions";
import { errorCodeMap, errorBizAppMap } from "../../const";

function* handleFail(code: any, error_code: any = []) {
  yield put(reqActions.action.updateRequestState(-1));
  if (code === 2) {
    let msg = "";
    if (error_code.length > 1) {
      error_code.forEach((item: any, id: any) => {
        msg = msg.concat(`- ${errorBizAppMap.get(item.toString())}\n`);
      });
    } else msg = errorBizAppMap.get(error_code[0].toString())!;
    NotificationService.error(msg);
  } else if (errorCodeMap.has(code)) {
    NotificationService.error(errorCodeMap.get(code.toString()));
  } else NotificationService.error("Unknown Error");
}

function* saga_LoadData(payloadData: any) {
  try {
    const isFilter = payloadData.type === actions.types.UPDATE_FILTER;
    let _pageNumber: Promise<any> = yield select(
      (state: any) => state.log.page_number
    );
    let pageNumber = isFilter ? 1 : _pageNumber;
    let _pageSize: Promise<any> = yield select(
      (state: any) => state.log.page_size
    );
    let pageSize = _pageSize;
    let _currentFilter: Promise<any> = yield select(
      (state: any) => state.log.current_filter
    );
    let currentFilter = _currentFilter;
    let _clientID: Promise<any> = yield select(
      (state: any) => state.client_app.data
    );
    let clientID = _clientID;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Log.GetOCRLog(
      clientID,
      pageSize,
      pageNumber,
      currentFilter
    );
    let response: any = _response;

    let { data } = response;

    if (data) {
      let { paging, requests } = data;

      yield put(actions.action.loadDataSuccess(paging, requests));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải dữ liệu thất bại");
    }
  } catch (ex: any) {
    console.log("[OCR] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải dữ liệu thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_GetResult() {
  try {
    let _request_id: Promise<any> = yield select(
      (state: any) => state.log.current_request.request_id
    );
    let request_id = _request_id;
    let _client_id: Promise<any> = yield select(
      (state: any) => state.log.current_request.client_id
    );
    let client_id = _client_id;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Log.GetOCRLogDetail(
      client_id,
      request_id
    );
    let response: any = _response;
    let { data, code } = response;

    if (code === 1 && data) {
      yield put(reqActions.action.updateRequestState(1));
      yield put(actions.action.loadDetailDataSuccess(data.documents));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      yield handleFail(code, data.error_code);
    }
  } catch (ex: any) {
    console.error("saga_GetResult", ex);
    NotificationService.error(
      ex && ex.data && ex.data.message
        ? ex.data.message
        : "Tải dữ liệu thất bại"
    );
    yield put(reqActions.action.updateRequestState(-1));

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_DATA, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_NUMBER, saga_LoadData);
  yield takeEvery(actions.types.UPDATE_PAGE_SIZE, saga_LoadData);
  yield takeEvery(actions.types.LOAD_DETAIL_DATA, saga_GetResult);
  yield takeEvery(actions.types.UPDATE_FILTER, saga_LoadData);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
