import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const dateYearFormat = "DD-MM-YYYY";

export const DocTimeChartComponent = (props: any) => {
  const { documentData, currentFilter } = props;

  var listDocType: any[] = [];
  if (documentData && documentData.length > 0) {
    documentData.forEach((item: any) => {
      const { DocType } = item;
      const existData = listDocType.findIndex((e: any) => e.name === DocType);
      if (existData === -1)
        listDocType.push({
          name: DocType,
          count: 0,
          time_used: 0,
          min: 9999999,
          max: 0,
        });
    });

    documentData.forEach((item: any) => {
      const { AvgTime, DocType, MinTime, MaxTime } = item;
      const existData = listDocType.findIndex((e: any) => e.name === DocType);

      listDocType[existData] = {
        ...listDocType[existData],
        count: listDocType[existData].count + 1,
        time_used: listDocType[existData].time_used + AvgTime,
        min:
          listDocType[existData].min > MinTime
            ? MinTime
            : listDocType[existData].min,
        max:
          listDocType[existData].max < MaxTime
            ? MaxTime
            : listDocType[existData].max,
      };
    });

    listDocType = listDocType.map((item: any) => {
      const { time_used, count } = item;
      const avg_time_used = parseFloat((time_used / count).toFixed(2));

      return {
        ...item,
        avg_time_used,
      };
    });
  }

  if (!documentData || documentData.length === 0)
    return <div className="ReportBlock">Không có dữ liệu</div>;
  return (
    <div className="ReportBlock">
      <div className="BlockTitle">Thời gian OCR từng loại chứng từ</div>
      <div className="BlockTime">
        {moment(currentFilter.from_time).format(dateYearFormat)} &#8226;{" "}
        {moment(currentFilter.to_time).format(dateYearFormat)}
      </div>
      <div className="BlockBody OCR_Status">
        <div className="ChartContainer">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              layout="vertical"
              data={listDocType}
              barSize={20}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip
                formatter={(value, name, props) => {
                  const { payload } = props;
                  if (name === "Avg Time Used") {
                    return [
                      <div>
                        <p>{`Avg: ${Number(value).toFixed(2)}s`}</p>
                        <p>{`Min: ${Number(payload.min).toFixed(2)}s`}</p>
                        <p>{`Max: ${Number(payload.max).toFixed(2)}s`}</p>
                      </div>,
                    ];
                  }
                  return [value, name];
                }}
              />
              <Legend />
              <Bar
                dataKey="avg_time_used"
                name="Avg Time Used"
                fill="#1182E7"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
