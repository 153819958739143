import { Form, Input, Select } from "antd";
import { ModalComponent } from "../../../components";

const user_name = localStorage.getItem("username");
const role = localStorage.getItem("role");

export const UserModalComponent = (props: any) => {
  const [form] = Form.useForm();
  const {
    modalStatus,
    currentItem,
    userGroupList,
    userRoleList,
    onSave,
    onCancel,
    onChangeCurrentItem,
  } = props;

  if (currentItem) form.setFieldsValue(currentItem);
  else
    form.setFieldsValue({
      id: null,
      username: "",
      password: "",
      role: "",
      groups: [],
    });

  const _renderModal = () => {
    if (!currentItem) return;

    return (
      <div className="ClientAppModal">
        <Form
          form={form}
          layout="vertical"
          initialValues={currentItem}
          onValuesChange={() => onChangeCurrentItem(form.getFieldsValue())}
        >
          <div className="Container" style={{ display: "none" }}>
            <div className="FormItem">
              <Form.Item name="id" label="User ID">
                <Input readOnly />
              </Form.Item>
            </div>
          </div>
          <div className="Container">
            <div className="FormItem">
              <Form.Item name="username" label="Tên người dùng" required>
                <Input readOnly={currentItem.id} />
              </Form.Item>
            </div>
          </div>
          {!currentItem.id && (
            <div className="Container">
              <div className="FormItem">
                <Form.Item name="password" label="Mật khẩu">
                  <Input type="password" />
                </Form.Item>
              </div>
            </div>
          )}

          <div className="Container">
            <div className="FormItem">
              <Form.Item name="role" label="Phân Quyền" required>
                <Select
                  allowClear
                  disabled={
                    currentItem.id &&
                    (currentItem.username === user_name ||
                      (role === "OPERATOR_OCR" && currentItem.role === role))
                  }
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  options={userRoleList}
                />
              </Form.Item>
            </div>
          </div>
          {form.getFieldValue("role") !== "ADMIN_OCR" && (
            <div className="Container">
              <div className="FormItem">
                <Form.Item name="groups" label="Nhóm người dùng" required>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    options={userGroupList}
                  />
                </Form.Item>
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  };

  return (
    <>
      <ModalComponent
        title={currentItem && currentItem.id ? "Cập nhật User" : "Tạo User"}
        isModalVisible={modalStatus}
        onOk={onSave}
        onCancel={onCancel}
        cancelText="Huỷ"
        okText="Lưu"
      >
        {_renderModal()}
      </ModalComponent>
    </>
  );
};
