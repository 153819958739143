import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { RiAddCircleLine, RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import { PaginationComponent } from "../../components";
import ClientFilter from "../../components/clientFilter";
import { showConfirm } from "../../components/modal";
import { error } from "../../components/notification";
import { useActions } from "../../redux";
import { renderDateTime } from "../../utils";
import { ClientAppModalComponent } from "./component";

var role = localStorage.getItem("role");
const options = [
  { label: "Client ID", value: 1 },
  { label: "Client App", value: 2 },
];

export const ClientAppPage = () => {
  const dispatch = useDispatch();
  const actions = useActions();
  const [itemShow, setItemShow] = useState<any>(null);
  const requestState = useSelector((state: any) => state.request.request_state);
  let data = useSelector((state: any) => state.client_app.data);
  let currentItem = useSelector((state: any) => state.client_app.current_item);
  let totalRecord = useSelector((state: any) => state.client_app.total_record);
  let pageNumber = useSelector((state: any) => state.client_app.page_number);
  let pageSize = useSelector((state: any) => state.client_app.page_size);

  useEffect(() => {
    dispatch(actions.ClientAppActions.loadData());
  }, [actions.ClientAppActions, dispatch]);

  useEffect(() => {
    // This function will be called when the component is unmounted
    return () => {
      dispatch(actions.ClientAppActions.resetFilter());
    };
  }, [dispatch, actions.ClientAppActions]);

  const handleCreate = () => {
    dispatch(
      actions.ClientAppActions.updateCurrentItem({
        id: "",
        name: "",
        priority: 0,
        webhook_url: "",
        auth_type: "API_KEY",
        auth_url: "",
        auth_resp_key: "",
        auth_header: "",
        auth_body: "",
        webhook_header: "",
        bucket_path: "",
        bucket_name: "",
      })
    );
    setItemShow("");
  };

  const handleUpdate = (item: any) => {
    dispatch(
      actions.ClientAppActions.updateCurrentItem({
        ...item,
        id: item.id.toString(),
        name: item.client_alias,
        priority: item.priority,
        webhook_url: item.webhook_url,
        auth_type: item.auth_type,
        auth_url: item.auth_url,
        auth_resp_key: item.auth_resp_key,
        auth_header: item.auth_header ? JSON.stringify(item.auth_header) : "",
        auth_body: item.auth_body ? JSON.stringify(item.auth_body) : "",
        webhook_header: item.webhook_header
          ? JSON.stringify(item.webhook_header)
          : "",
        bucket_path: item.bucket_path,
      })
    );
    setItemShow(item.id.toString());
  };

  const handleDelete = (client_id: any) => {
    showConfirm({
      title: "Chắc chắn inactive Client App này?",
      content: "",
      onOk: () => {
        dispatch(actions.ClientAppActions.deleteCurrentItem(client_id));
      },
      onCancel: () => {},
    });
  };

  const handleChangeCurrentItem = (data: any) => {
    if (data.auth_type === "API_KEY")
      dispatch(
        actions.ClientAppActions.updateCurrentItem({
          ...data,
          auth_url: "",
          auth_resp_key: "",
          auth_header: null,
          auth_body: null,
        })
      );
    if (data.auth_type === "BEARER_TOKEN")
      dispatch(
        actions.ClientAppActions.updateCurrentItem({
          ...data,
        })
      );
  };

  const handleChangePageNumber = (page_number: any) => {
    dispatch(actions.ClientAppActions.updatePageNumber(page_number));
  };

  const handleChangePageSize = (page_size: any) => {
    dispatch(actions.ClientAppActions.updatePageSize(page_size));
  };

  const handleCancel = () => {
    dispatch(actions.ClientAppActions.updateCurrentItem(null));
    setItemShow(null);
  };
  const onSearch = (searchText: string, searchType: number) => {
    const filter =
      searchType === 1
        ? { clientId: searchText, clientApp: "" }
        : { clientApp: searchText, clientId: "" };
    dispatch(actions.ClientAppActions.updateFilter({ ...filter }));
  };

  const isJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleSave = () => {
    const {
      name,
      priority,
      webhook_url,
      auth_type,
      // auth_token,
      auth_url,
      auth_resp_key,
      auth_header,
      auth_body,
      webhook_header,
    } = currentItem;
    if (
      name === "" ||
      priority === null ||
      priority === "" ||
      webhook_url === "" ||
      (auth_type === "API_KEY" && webhook_header === "") ||
      (auth_type === "BEARER_TOKEN" &&
        (auth_url === "" ||
          auth_resp_key === "" ||
          auth_header === "" ||
          auth_body === ""))
    )
      return error("Vui lòng nhập đầy đủ thông tin");
    if (webhook_header && !isJsonString(webhook_header))
      return error("Webhook header không nhập chuỗi JSON hợp lệ");
    if (auth_type === "BEARER_TOKEN" && !isJsonString(auth_header))
      return error("Header đăng nhập không nhập chuỗi JSON hợp lệ");
    if(priority < 0)
      return error("Mức độ ưu tiên phải lớn hoặc bằng 0");
    if (auth_type === "BEARER_TOKEN" && !isJsonString(auth_body))
      return error("Body đăng nhập không nhập chuỗi JSON hợp lệ");
    else {
      dispatch(
        actions.ClientAppActions.updateCurrentItem({
          ...currentItem,
          webhook_header: webhook_header
            ? JSON.parse(webhook_header)
            : webhook_header,
          auth_header:
            auth_type === "BEARER_TOKEN"
              ? JSON.parse(auth_header)
              : auth_header,
          auth_body:
            auth_type === "BEARER_TOKEN" ? JSON.parse(auth_body) : auth_body,
        })
      );
      dispatch(
        actions.ClientAppActions.saveCurrentItem(
          currentItem.id ? "update" : "create"
        )
      );
      setItemShow(null);
    }
  };

  if (!role || role === "") role = localStorage.getItem("role");

  var prioritySet = new Set();
  if (data && data.length > 0) {
    var currentValue = 0;
    if (currentItem && currentItem.id)
      currentValue = data.find(
        (e: any) => e.id.toString() === currentItem.id
      )?.priority;
    prioritySet = new Set(
      data.map((item: any) => {
        if (item.priority === 0 || item.priority === currentValue) return null;
        if (item.status === "DELETED") return null;
        return item.priority;
      })
    );
  }

  return (
    <div className="AppPage">
      <div className="PageTitle">Client App Management</div>
      <div className="head-section">
        <ClientFilter onSearch={onSearch} options={options} />

        <div className="CreateButtonContainer">
          <Button type="primary" onClick={handleCreate}>
            <RiAddCircleLine /> Tạo Client App
          </Button>
        </div>
      </div>

      <Spin
        spinning={requestState === 0}
        size="large"
        style={{ position: "static" }}
      >
        <div
          className="TableContainer"
          //style={{ height: "calc(100vh - 260px)" }}
        >
          <table className="table table-striped sticky-header client-table">
            <thead>
              <tr>
                <th>Client App</th>
                <th>Trạng thái</th>
                <th>Client ID</th>
                <th>Mức độ ưu tiên</th>
                <th>Client Secret</th>
                <th>Client bucket</th>
                <th>Webhook URL</th>
                <th>Loại xác thực</th>
                <th>URL đăng nhập</th>
                <th>Webhook bucket</th>
                <th>Thời gian tạo</th>
                <th>Tạo bởi</th>
                <th>Thời gian cập nhật</th>
                <th>Cập nhật bởi</th>
                <th>Thời gian inactive</th>
                <th>Inactive bởi</th>
                {role === "ADMIN_OCR" && <th></th>}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item: any, index: any) => {
                  return (
                    <tr key={`row_${index}`}>
                      <td>{item.client_alias}</td>
                      <td>
                        {item.status == "DELETED" ? "INACTIVE" : item.status}
                      </td>
                      <td>{item.client_id}</td>
                      <td>{item.priority}</td>
                      <td>{item.client_secret}</td>
                      <td>{item.bucket_name}</td>
                      <td>{item.webhook_url}</td>
                      <td>{item.auth_type}</td>
                      <td>{item.auth_url}</td>
                      <td>{item.bucket_path}</td>

                      <td>{renderDateTime(item.created_at)}</td>
                      <td>{item.created_user}</td>
                      <td>{renderDateTime(item.updated_at)}</td>
                      <td>{item.updated_user}</td>
                      <td>{renderDateTime(item.deleted_at)}</td>
                      <td>{item.deleted_user}</td>
                      <td>
                        <div className="ButtonContainer">
                          {item.status !== "DELETED" && (
                            <Button
                              type="default"
                              className="SearchButton"
                              onClick={() => handleUpdate(item)}
                            >
                              <RiEditLine />
                              Cập nhật
                            </Button>
                          )}
                          {role === "ADMIN_OCR" &&
                            item.status !== "DELETED" && (
                              <Button
                                type="primary"
                                className="ResetButton"
                                onClick={() => handleDelete(item.client_id)}
                                danger
                              >
                                <RiDeleteBin6Line /> Inactive
                              </Button>
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {(!data || data.length === 0) && (
                <tr>
                  <td colSpan={12}>Không có dữ liệu</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Spin>
      <ClientAppModalComponent
        modalStatus={currentItem && itemShow === currentItem.id}
        currentItem={currentItem}
        onCancel={handleCancel}
        onSave={handleSave}
        onChangeCurrentItem={handleChangeCurrentItem}
        prioritySet={prioritySet}
      />
      <div className="client-pagination">
        {totalRecord > 0 && (
          <div className="">Số lượng Client apps: {totalRecord}</div>
        )}
        <PaginationComponent
          totalRecord={totalRecord}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onChangePageNumber={handleChangePageNumber}
          onChangePageSize={handleChangePageSize}
        />
      </div>
    </div>
  );
};
