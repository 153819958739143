export const errorCodeMap = new Map(
  Object.entries({
    2: 'Ảnh đầu vào không hợp lệ',
    4203: 'Phần mở rộng file ảnh không được hỗ trợ',
    4206: 'Tham số yêu cầu không hợp lệ',
    4211: 'Tham số kiểu file không được hỗ trợ',
    4212: 'Kích thước file không hợp lệ',
    4213: 'Định dạng file không hợp lệ',
    4214: 'Phần mở rộng file không hợp lệ',
    4215: 'Số file tải lên vượt quá giới hạn',
    5000: 'Lỗi cục bộ',
  })
);

export const errorIdAppMap = new Map(
  Object.entries({
    2: 'Ảnh đầu vào không phải CMND, CCCD hoặc hộ chiếu',
    4: 'Dung lượng hoặc độ phân giải ảnh không phù hợp',
    10: 'Ảnh đầu vào không phải mặt trước hoặc mặt sau của CMND, CCCD',
    14: 'Không tìm thấy đủ 4 góc của CMND, CCCD',
    15: 'Ảnh CMND, CCCD không được chụp từ ảnh gốc',
    23: 'Ảnh đầu vào bị chói',
    26: 'CMND mặt trước và mặt sau không cùng 1 loại',
    27: 'Ảnh đầu vào bị mờ',
    28: 'Ảnh đầu vào quá tối',
    30: 'Số CMND, CCCD trong ảnh khác một',
    31: 'Ảnh CMND, CCCD quá nhỏ',
  })
);

export const errorBizAppMap = new Map(
  Object.entries({
    100: 'Số giấy tờ trong ảnh đầu vào khác một',
    101: 'Không đúng loại tài liệu',
    102: 'Tài liệu bị mờ',
  })
);

export const errorDLAppMap = new Map(
  Object.entries({
    100: 'Số GPLX/ĐKX trong ảnh đầu vào khác một',
    101: 'Không đúng loại giấy tờ',
    103: 'Ảnh đầu vào sai mặt của giấy tờ',
  })
);
