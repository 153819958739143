import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import moment from "moment";
import { FaSquare } from "react-icons/fa";

const dateFormat = "DD-MM-YYYY";

export const OCRStatusChartComponent = (props: any) => {
  const { requestData, currentFilter } = props;
  const COLORS = ["#0088FE", "#FFBB28", "#00C49F", "#FF8042"];

  let pieChartData = [
    { name: "Tiếp nhận", value: 0 },
    { name: "Đang xử lý", value: 0 },
    { name: "Hoàn thành", value: 0 },
    { name: "Thất bại", value: 0 },
  ];

  if (requestData && requestData.length > 0) {
    let received = 0;
    let processing = 0;
    let finished = 0;
    let fail = 0;

    requestData.forEach((item: any) => {
      // if (item.finished_doc === item.total_doc) finished += 1;
      // else unfinished += 1;
      received += item.Receive;
      processing += item.Processing;
      finished += item.Success;
      fail += item.Fail;
    });

    pieChartData[0].value = received;
    pieChartData[1].value = processing;
    pieChartData[2].value = finished;
    pieChartData[3].value = fail;
  }
  const total = pieChartData.reduce((acc, cur) => acc + cur.value, 0);

  if (!requestData || requestData.length === 0)
    return <div className="ReportBlock">Không có dữ liệu</div>;
  return (
    <div className="ReportBlock">
      <div className="BlockTitle">Trạng thái OCR</div>
      <div className="BlockTime">
        {moment(currentFilter.from_time).format(dateFormat)} &#8226;{" "}
        {moment(currentFilter.to_time).format(dateFormat)}
      </div>
      <div className="BlockBody OCR_Status">
        <div className="ChartContainer" style={{ width: "50%" }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={"80%"}
                innerRadius={"55%"}
                dataKey="value"
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>

              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="InfoContainer">
          <div className="InfoRow">
            <div className="InfoLabel">Tổng số request</div>
            <div className="InfoValue">{total}</div>
          </div>
          <div className="InfoRow">
            <div className="InfoLabel">
              <FaSquare color={COLORS[0]} />
              Tiếp nhận
            </div>
            <div className="InfoValue">
              {((pieChartData[0].value * 100) / total).toFixed(0)}% -{" "}
              {pieChartData[0].value ?? 0} requests
            </div>
          </div>
          <div className="InfoRow" style={{ border: "none" }}>
            <div className="InfoLabel">
              <FaSquare color={COLORS[1]} />
              Đang xử lý
            </div>
            <div className="InfoValue">
              {((pieChartData[1].value * 100) / total).toFixed(0)}% -{" "}
              {pieChartData[1].value} requests
            </div>
          </div>
          <div className="InfoRow" style={{ border: "none" }}>
            <div className="InfoLabel">
              <FaSquare color={COLORS[2]} />
              Hoàn thành
            </div>
            <div className="InfoValue">
              {((pieChartData[2].value * 100) / total).toFixed(0)}% -{" "}
              {pieChartData[2].value} requests
            </div>
          </div>
          <div className="InfoRow" style={{ border: "none" }}>
            <div className="InfoLabel">
              <FaSquare color={COLORS[3]} />
              Thất bại
            </div>
            <div className="InfoValue">
              {((pieChartData[3].value * 100) / total).toFixed(0)}% -{" "}
              {pieChartData[3].value} requests
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
