import actions from "./actions";

export const initState = {
  client_id: null,
  doc_type: null,
  files: [],
  ocr: null,
  config: null,
  request_id: "",
  file_id: "",
  documents: null,
  signatures: null,
  ocr_done: false,
  update_result: null,
  single_docs: [],
  ocr_type: "FLOW",
};

const OCRReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case actions.types.SET_INIT_STATE:
      return initState;
    case actions.types.LOAD_CONFIG:
      return {
        ...state,
      };
    case actions.types.LOAD_CONFIG_SUCCESS:
      return {
        ...state,
        ...{
          config: action.payload.config,
          single_docs: action.payload.single_docs,
        },
      };
    case actions.types.UPDATE_CLIENT_ID:
      return {
        ...state,
        ...{ client_id: action.payload.client_id },
      };
    case actions.types.UPDATE_DOC_TYPE:
      return {
        ...state,
        ...{ doc_type: action.payload.doc_type },
      };

    case actions.types.UPDATE_FILE:
      return {
        ...state,
        ...{ files: action.payload.files },
      };
    case actions.types.PUSH_FILE:
      return {
        ...state,
      };
    case actions.types.PUSH_FILE_SUCCESS:
      return {
        ...state,
        ...{
          request_id: action.payload.request_id,
          file_id: action.payload.file_id,
          documents: action.payload.documents,
        },
      };
    case actions.types.OCR_FILE:
      return {
        ...state,
      };
    case actions.types.OCR_FILE_SUCCESS:
      return {
        ...state,
        ...{
          ocr_done: action.payload.ocr_done,
          ocr: action.payload.data,
        },
      };
    case actions.types.DETECT_SIGNATURE:
      return {
        ...state,
      };
    case actions.types.DETECT_SIGNATURE_SUCCESS:
      return {
        ...state,
        ...{
          signatures: action.payload.data,
        },
      };
    case actions.types.UPDATE_RESULT:
      return {
        ...state,
        ...{
          update_result: action.payload.data,
        },
      };
    case actions.types.PUSH_RESULT:
      return {
        ...state,
      };
    case actions.types.PUSH_RESULT_SUCCESS:
      return {
        ...state,
        ...{
          update_result: state.update_result.map((e: any, _: any) => {
            const { document_id, ocr_update } = action.payload.data;
            if (e.document_id !== document_id) return e;
            return {
              document_id,
              ocr_update,
            };
          }),
        },
      };
    case actions.types.UPDATE_OCR_TYPE:
      return {
        ...state,
        ...{
          ocr_type: action.payload.ocr_type,
        },
      };

    default:
      return state;
  }
};

export default OCRReducer;
