import moment from "moment";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const dateFormat = "DD-MM-YYYY";
const ConfidentChartComponent = (props: any) => {
  const { evaluateData, currentFilter } = props;
  const data = evaluateData?.map((item: any) => {
    return {
      name: item.DocType,
      confident_score: item.AvgDocScore * 100,
      total: item.Total,
      min: item.MinDocScore * 100,
      max: item.MaxDocScore * 100,
    };
  });
  if (!data || data.length === 0)
    return <div className="ReportBlock">Không có dữ liệu</div>;
  return (
    <div className="ReportBlock">
      <div className="BlockTitle">Tỉ lệ độ tin cậy</div>
      <div className="BlockTime">
        {moment(currentFilter?.from_time).format(dateFormat)} &#8226;{" "}
        {moment(currentFilter?.to_time).format(dateFormat)}
      </div>
      <div className="BlockBody OCR_Status">
        <div className="ChartContainer" style={{ width: "100%" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip
                formatter={(value, name, props) => {
                  const { payload } = props;
                  if (name === "Confidence Score") {
                    return [
                      <div>
                        <p>{`Avg: ${Math.round(Number(value))}%`}</p>
                        <p>{`Min: ${Math.round(Number(payload.min))}%`}</p>
                        <p>{`Max: ${Math.round(Number(payload.max))}%`}</p>
                        <p>{`Total: ${payload.total} documents`}</p>
                      </div>,
                    ];
                  }
                  return [value, name];
                }}
              />
              <Legend />
              <Bar
                name="Confidence Score"
                dataKey="confident_score"
                stackId="a"
                fill="#8884d8"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ConfidentChartComponent;
