import { RequestActions } from "./request/actions";
import { AuthActions } from "./auth/actions";
import { OCRActions } from "./ocr/actions";
import { LogActions } from "./log/actions";
import { SignActions } from "./sign/actions";
import { DocActions } from "./doc/actions";
import { UserActions } from "./user/actions";
import { UserGroupActions } from "./user-group/actions";
import { ClientAppActions } from "./client-app/actions";
import { ReportActions } from "./report/actions";
import { DailyActions } from "./daily/actions";
import { FlowActions } from "./flow/actions";
import { SignatureCompareActions } from "./signature-compare/actions";

export const useActions = () => {
  const actions = {
    RequestActions,
    AuthActions,
    OCRActions,
    LogActions,
    SignActions,
    DocActions,
    UserActions,
    UserGroupActions,
    ClientAppActions,
    ReportActions,
    DailyActions,
    FlowActions,
    SignatureCompareActions,
  };

  return actions;
};
