import { Store } from 'react-notifications-component';

export const success = (message = '', title = 'Thông báo', postion: any = 'top-right') => {
  Store.addNotification({
    title: title,
    message: message,
    type: 'success',
    insert: 'top',
    container: postion,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 2000,
      onScreen: false,
    },
  });
};

export const error = (
  message = '',
  duration = 2000,
  title = 'Thông báo',
  postion: any = 'top-right'
) => {
  Store.addNotification({
    title: title,
    message: message,
    type: 'danger',
    insert: 'top',
    container: postion,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration,
      onScreen: false,
    },
  });
};
