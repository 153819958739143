import { all } from "redux-saga/effects";

import requestSaga from "./request/saga";
import authSaga from "./auth/saga";
import ocrSaga from "./ocr/saga";
import logSaga from "./log/saga";
import signSaga from "./sign/saga";
import docSaga from "./doc/saga";
import userSaga from "./user/saga";
import userGroupSaga from "./user-group/saga";
import clientAppSaga from "./client-app/saga";
import reportSaga from "./report/saga";
import dailySaga from "./daily/saga";
import flowSaga from "./flow/saga";
import signatureCompareSaga from "./signature-compare/saga";

export default function* rootSaga() {
  yield all([
    requestSaga(),
    authSaga(),
    ocrSaga(),
    logSaga(),
    signSaga(),
    docSaga(),
    userSaga(),
    userGroupSaga(),
    clientAppSaga(),
    reportSaga(),
    dailySaga(),
    flowSaga(),
    signatureCompareSaga(),
  ]);
}
