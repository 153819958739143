import createApiServices from "../createApiServices";

const api = createApiServices();

const GetOCRLog = (
  client_id: any,
  page_size: any,
  page_number: any,
  current_filter: any
) => {
  var listClient: any = [];
  if (client_id && client_id.length > 0)
    listClient = client_id.map((client: any, _: any) => {
      return client.client_id;
    });

  const { search_text, search_type, from_time, to_time } = current_filter;

  return api.makeAuthRequest({
    url: `/ocr-internal/v1/history/request?limit=${page_size}&page=${page_number}&search_text=${search_text}&search_type=${search_type}&from_time=${from_time}&to_time=${to_time}`,
    method: "POST",
    data: { client_id: listClient },
  });
};

const GetOCRLogDetail = (client_id: any, request_id: any) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/history/document?rid=${request_id}&limit=10000`,
    method: "POST",
    data: { client_id: [client_id] },
  });
};

const GetFileContent = (
  client_id: any,
  request_id: any,
  path: any,
  size: any = "thumbnail"
) => {
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/file/${request_id}?size=${size}`,
    method: "POST",
    data: { client_id, path },
    responseType: "blob",
  });
};

export const Log = {
  GetOCRLog,
  GetOCRLogDetail,
  GetFileContent,
};
