import { Redirect, Switch } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RouterLinks } from "../../const";
import {
  AuthorizationComponent,
  TopBarComponent,
  SideBarComponent,
} from "../../components";
import {
  OCRPage,
  LogPage,
  SignPage,
  DocumentPage,
  UserGroupPage,
  ClientAppPage,
  ReportPage,
  UserPage,
  DailyPage,
  FlowPage,
  SignatureComparePage,
} from "../../pages";
import { useActions } from "../../redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const AuthorizedPage = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const actions = useActions();
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.OCRActions.setInitState());
    dispatch(actions.LogActions.reset());
    dispatch(actions.SignActions.reset());
    dispatch(actions.DocActions.reset());
  }, [location.pathname, dispatch, actions]);

  useEffect(() => {
    // will dispatch init data for all pages here
    //  dispatch(actions.ReportActions.loadDocTypes());
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(actions.AuthActions.logOut());
    return history.push(RouterLinks.LOGIN_PAGE);
  };

  return (
    <>
      <TopBarComponent handleLogout={handleLogout} />
      <div style={{ display: "flex" }}>
        <SideBarComponent />
        <Switch>
          <AuthorizationComponent
            component={OCRPage}
            path={RouterLinks.OCR_PAGE}
          />{" "}
          <AuthorizationComponent
            component={DailyPage}
            path={RouterLinks.DAILY_PAGE}
          />
          <AuthorizationComponent
            component={LogPage}
            path={RouterLinks.LOG_PAGE}
          />
          <AuthorizationComponent
            component={SignPage}
            path={RouterLinks.SIGN_PAGE}
          />
          <AuthorizationComponent
            component={SignatureComparePage}
            path={RouterLinks.SIGNATURE_COMPARE_PAGE}
          />
          <AuthorizationComponent
            component={FlowPage}
            path={RouterLinks.FLOW_PAGE}
          />
          <AuthorizationComponent
            component={DocumentPage}
            path={RouterLinks.DOC_PAGE}
          />
          <AuthorizationComponent
            component={UserPage}
            path={RouterLinks.USER_PAGE}
          />
          <AuthorizationComponent
            component={UserGroupPage}
            path={RouterLinks.USER_GROUP_PAGE}
          />
          <AuthorizationComponent
            component={ClientAppPage}
            path={RouterLinks.CLIENT_APP_PAGE}
          />
          <AuthorizationComponent
            component={ReportPage}
            path={RouterLinks.REPORT_PAGE}
          />
          <Redirect to={RouterLinks.OCR_PAGE} />
        </Switch>
      </div>
    </>
  );
};
