import type { MenuProps } from "antd";
import { Button, Form, Input, Row, Select } from "antd";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosCloseCircle,
  IoIosRefresh,
} from "react-icons/io";

const options = [
  { label: "Request ID", value: 1 },
  { label: "Client App", value: 2 },
  { label: "Client ID", value: 3 },
];

export const DailyFilterComponent = (props: any) => {
  const [form] = Form.useForm();
  const { currentFilter, onFilterChange, onReload } = props;
  if (currentFilter) form.setFieldsValue(currentFilter);
  const handleValuesChange = () => {
    onFilterChange({ ...currentFilter, ...form.getFieldsValue() });
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    onFilterChange({
      ...form.getFieldsValue(),
      sortByPriority: e.key,
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      type: "group",
      label: "Sắp xếp theo",
      children: [
        {
          key: "ASC",
          label: "Ưu tiên tăng dần",
          icon: <IoIosArrowUp />,
        },
        {
          key: "DESC",
          label: "Ưu tiên giảm dần",
          icon: <IoIosArrowDown />,
        },
      ],
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
    selectable: true,
    defaultSelectedKeys: ["DESC"],
  };

  return (
    <div className="FormFilter">
      <Form
        layout="vertical"
        form={form}
        initialValues={currentFilter}
        // onValuesChange={handleValuesChange}
      >
        <Row>
          <Form.Item name="search_type">
            <Select
              style={{ width: "200px" }}
              placeholder="Cột cần tìm kiếm"
              options={options}
              defaultValue={1}
            />
          </Form.Item>

          <Form.Item name="search">
            <Input allowClear placeholder="Tìm kiếm" />
          </Form.Item>

          <Form.Item>
            <div className="ButtonContainer">
              <Button
                type="primary"
                className="SearchButton"
                onClick={() => {
                  handleValuesChange();
                }}
              >
                <span>Áp dụng </span>
              </Button>
              <Button
                type="default"
                className="ResetButton"
                onClick={() => {
                  form.setFieldValue("search", "");
                  handleValuesChange();
                }}
              >
                <IoIosCloseCircle /> Bỏ tuỳ chọn
              </Button>

              <Button className="ResetButton" onClick={onReload}>
                <IoIosRefresh /> Tải lại trang
              </Button>
            </div>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
