import { Button, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";

interface FilterComponentProps {
  showSearchText?: boolean;
  showSearchType?: boolean;
  userGroupList?: any;
  options: { label: string; value: string }[];
  onSearch?: (searchText: string, searchType: string) => void;
}

const UserFilter: React.FC<FilterComponentProps> = ({
  onSearch,
  options,
  userGroupList,
  showSearchText = true,
  showSearchType = true,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("username");
  const [form] = Form.useForm();

  const onReset = () => {
    setSearchText("");
    onSearch && onSearch("", "username");
  };

  return (
    <div className="FormFilter" style={{ margin: 0, marginTop: 20 }}>
      <Form layout="vertical" form={form}>
        <Row>
          <Form.Item>
            <Select
              disabled={!showSearchType}
              style={{ width: "200px" }}
              placeholder="Cột cần tìm kiếm"
              options={options}
              defaultValue={"username"}
              onChange={(e) => {
                setSearchType(e);
                setSearchText("");
              }}
            />
          </Form.Item>

          {showSearchText && (
            <Form.Item>
              {searchType === "groupId" ? (
                <Select
                  style={{ width: "200px" }}
                  placeholder="Cột cần tìm kiếm"
                  options={userGroupList}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e);
                  }}
                />
              ) : (
                <Input
                  style={{ minWidth: "250px" }}
                  placeholder="Tìm kiếm"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              )}
            </Form.Item>
          )}
          <Form.Item>
            <div className="ButtonContainer">
              <Button
                type="primary"
                className="SearchButton"
                onClick={() => {
                  onSearch && onSearch(searchText, searchType);
                }}
              >
                <span>Áp dụng </span>
              </Button>
              <Button type="default" className="ResetButton" onClick={onReset}>
                <IoIosCloseCircle /> Bỏ tuỳ chọn
              </Button>
            </div>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default UserFilter;
