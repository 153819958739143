const prefix = "ocr/";

const types = {
  SET_INIT_STATE: prefix + "set_init_state",
  LOAD_CONFIG: prefix + "load_config",
  LOAD_CONFIG_SUCCESS: prefix + "load_config_success",
  UPDATE_CLIENT_ID: prefix + "update_client_id",
  UPDATE_DOC_TYPE: prefix + "update_doc_type",
  UPDATE_FILE: prefix + "update_file",
  PUSH_FILE: prefix + "verify_file",
  PUSH_FILE_SUCCESS: prefix + "verify_file_success",
  OCR_FILE: prefix + "ocr_file",
  OCR_FILE_SUCCESS: prefix + "ocr_file_success",
  DETECT_SIGNATURE: prefix + "detect_signature",
  DETECT_SIGNATURE_SUCCESS: prefix + "detect_signature_success",
  UPDATE_RESULT: prefix + "update_result",
  PUSH_RESULT: prefix + "push_result",
  PUSH_RESULT_SUCCESS: prefix + "push_result_success",
  UPDATE_OCR_TYPE: prefix + "update_ocr_type",
};

const action = {
  setInitState: () => {
    return {
      type: types.SET_INIT_STATE,
    };
  },
  loadConfig: () => {
    return {
      type: types.LOAD_CONFIG,
    };
  },
  loadConfigSuccess: (config: any, single_docs: any) => {
    return {
      type: types.LOAD_CONFIG_SUCCESS,
      payload: { config, single_docs },
    };
  },
  updateOcrType: (ocr_type: any) => {
    return {
      type: types.UPDATE_OCR_TYPE,
      payload: { ocr_type },
    };
  },
  updateClientID: (client_id: any) => {
    return {
      type: types.UPDATE_CLIENT_ID,
      payload: { client_id },
    };
  },
  updateDocType: (doc_type: any) => {
    return {
      type: types.UPDATE_DOC_TYPE,
      payload: { doc_type },
    };
  },
  updateFile: (files: any) => {
    return {
      type: types.UPDATE_FILE,
      payload: { files },
    };
  },
  pushFile: () => {
    return {
      type: types.PUSH_FILE,
    };
  },
  pushFileSuccess: (request_id: any, file_id: any, documents: any) => {
    return {
      type: types.PUSH_FILE_SUCCESS,
      payload: {
        request_id,
        file_id,
        documents,
      },
    };
  },
  ocrFile: () => {
    return {
      type: types.OCR_FILE,
    };
  },
  ocrFileSuccess: (ocr_done: any, data: any) => {
    return {
      type: types.OCR_FILE_SUCCESS,
      payload: { ocr_done, data },
    };
  },
  detectSignature: (document_id: any) => {
    return {
      type: types.DETECT_SIGNATURE,
      payload: { document_id },
    };
  },
  detectSignatureSuccess: (data: any) => {
    return {
      type: types.DETECT_SIGNATURE_SUCCESS,
      payload: { data },
    };
  },
  updateResult: (data: any) => {
    return {
      type: types.UPDATE_RESULT,
      payload: { data },
    };
  },
  pushResult: (data: any) => {
    return {
      type: types.PUSH_RESULT,
      payload: { result: data },
    };
  },

  pushResultSuccess: (data: any) => {
    return {
      type: types.PUSH_RESULT_SUCCESS,
      payload: { data },
    };
  },
};

const actions = {
  types,
  action,
};

export default actions;

export const OCRActions = action;
