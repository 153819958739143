import { HiOutlineArrowsExpand } from 'react-icons/hi';

export const FileComponent = (props: any) => {
  const { file, expandFile, showExpand } = props;

  return (
    <>
      <div className='FileContainer'>
        <div className='ImageContainer'>
          {showExpand && (
            <div className='IconContainer' onClick={expandFile}>
              <HiOutlineArrowsExpand />
            </div>
          )}
          <img src={URL.createObjectURL(file)} alt='img' className='ThumbnailImage' />
        </div>
      </div>
    </>
  );
};
