import createApiServices from "../createApiServices";

const api = createApiServices();

const GetDoc = (page_size: any, page_number: any, filter: any) => {
  const { searchText, searchType, fromTime, toTime } = filter;
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/document?limit=${page_size}&page=${page_number}&search_text=${searchText}&search_type=${searchType}&from_time=${fromTime}&to_time=${toTime}`,
    method: "GET",
  });
};

const CreateDoc = (
  doc_type: any,
  description: any,
  is_ocr: any,
  has_signature: any
) => {
  const data = {
    documents: [
      {
        doc_type,
        description,
        is_ocr,
        has_signature,
      },
    ],
  };
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/document/create`,
    method: "POST",
    data,
  });
};

const UpdateDoc = (
  doc_id: any,
  doc_type: any,
  description: any,
  is_ocr: any,
  has_signature: any
) => {
  const data = {
    documents: {
      [doc_id]: {
        doc_type,
        description,
        is_ocr,
        has_signature,
      },
    },
  };
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/document/update`,
    method: "POST",
    data,
  });
};

const DeleteDoc = (doc_id: any) => {
  const data = {
    doc_id: [doc_id],
  };
  return api.makeAuthRequest({
    url: `/ocr-internal/v1/document/delete`,
    method: "POST",
    data,
  });
};

export const Doc = {
  GetDoc,
  CreateDoc,
  UpdateDoc,
  DeleteDoc,
};
